// =============================================================================
// 1# Settings / Fonts
// =============================================================================

// Context
// =============================================================================
$body-context: 16px;

// Typefaces
// =============================================================================

//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@font-face {
    font-family: "Noto Sans";
    src: url("../images/fonts/notosans/NotoSans-Regular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display:  swap;
}
@font-face {
    font-family: "GT America Condensed";
    src: url("../images/fonts/GT-America/GT-America-Condensed-Regular.ttf") format('truetype'),
        url("../images/fonts/GT-America/GT-America-Condensed-Regular.otf") format("opentype"),
        url("../images/fonts/GT-America/GT-America-Condensed-Regular.woff") format("woff"),
        url("../images/fonts/GT-America/GT-America-Condensed-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "GT America Condensed";
    src: url("../images/fonts/GT-America/GT-America-Condensed-Medium.ttf") format('truetype'),
        url("../images/fonts/GT-America/GT-America-Condensed-Medium.otf") format("opentype"),
        url("../images/fonts/GT-America/GT-America-Condensed-Medium.woff") format("woff"),
        url("../images/fonts/GT-America/GT-America-Condensed-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT America Extended";
    src: url("../images/fonts/GT-America/GT-America-Extended-Regular.ttf") format('truetype'),
        url("../images/fonts/GT-America/GT-America-Extended-Regular.otf") format("opentype"),
        url("../images/fonts/GT-America/GT-America-Extended-Regular.woff") format("woff"),
        url("../images/fonts/GT-America/GT-America-Extended-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "GT America Extended";
    src: url("../images/fonts/GT-America/GT-America-Extended-Medium.ttf") format('truetype'),
        url("../images/fonts/GT-America/GT-America-Extended-Medium.otf") format("opentype"),
        url("../images/fonts/GT-America/GT-America-Extended-Medium.woff") format("woff"),
        url("../images/fonts/GT-America/GT-America-Extended-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "GT America";
    src: url("../images/fonts/GT-America/GT-America-Standard-Light.ttf") format('truetype'),
    url("../images/fonts/GT-America/GT-America-Standard-Light.otf") format("opentype"),
    url("../images/fonts/GT-America/GT-America-Standard-Light.woff") format("woff"),
    url("../images/fonts/GT-America/GT-America-Standard-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "GT America";
    src: url("../images/fonts/GT-America/GT-America-Standard-Regular.ttf") format('truetype'),
        url("../images/fonts/GT-America/GT-America-Standard-Regular.otf") format("opentype"),
        url("../images/fonts/GT-America/GT-America-Standard-Regular.woff") format("woff"),
        url("../images/fonts/GT-America/GT-America-Standard-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "GT America";
    src: url("../images/fonts/GT-America/GT-America-Standard-Medium.ttf") format('truetype'),
        url("../images/fonts/GT-America/GT-America-Standard-Medium.otf") format("opentype"),
        url("../images/fonts/GT-America/GT-America-Standard-Medium.woff") format("woff"),
        url("../images/fonts/GT-America/GT-America-Standard-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "GT America";
    src: url("../images/fonts/GT-America/GT-America-Standard-Bold.ttf") format('truetype'),
        url("../images/fonts/GT-America/GT-America-Standard-Bold.otf") format("opentype"),
        url("../images/fonts/GT-America/GT-America-Standard-Bold.woff") format("woff"),
        url("../images/fonts/GT-America/GT-America-Standard-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

$font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Noto Sans, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif !default; // font operating system
$font-family-headings: sans-serif !default; // font for h1, h2.. h6
$font-family-monospace: consolas, courier, monospace !default; // font for code and samples
$font-icons: 'icomoon';

$font-formats-default: 'woff2', 'woff' !default;
$font-family-default: $font-family-system !default;
$font-family: "GT America", "Noto Sans", Arial, Helvetica, sans-serif;
$font-family-condensed: "GT America Condensed", "Noto Sans", Arial, Helvetica, sans-serif;
$font-family-extended: "GT America Extended", "Noto Sans", Arial, Helvetica, sans-serif;
$font-family-secondary: "Noto Sans", Arial, Helvetica, sans-serif;
$font-icons: 'icomoon' !default;
$font-family-name__base: $font-family-default;


// Line heights
// =============================================================================
$line-height-default: 1.66 !default;


// Weight
// =============================================================================
$weight-light: 200 !default;
$weight-book: 300 !default;
$weight-regular: 400 !default;
$weight-medium: 500 !default;
$weight-bold: 700 !default;
