.pager {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.4rem 0;
    gap: 2rem;

    .pager__item {
        text-align: center;
        margin-bottom: 0;
        transition: all .3s;
        border-bottom: 2px solid transparent;
        font-family: $font-family-condensed;

        &.pager__item--previous,
        &.pager__item--next {
            span {
                color: $content-primary;
            }
        }

        &.-hide {
            opacity: 0;
            pointer-events: none;
        }

        &:last-of-type:hover .icon {
            transform: translateX(3px);
        }

        a, span {
            width: 100%;
            height: 100%;
            color: rgba(0,0,0,0.4);
            transition: all .3s;
            text-align: center;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            padding: 2px 0;
            min-width: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover, &:focus {
                color: rgba(0,0,0,1);

                .icon {
                    fill: black;
                    transform: translateX(-3px);
                }
            }
        }

        span {
            color: black;
            min-width: 2rem;
        }

        .icon {
            fill: black;
            vertical-align: middle;
            transition: transform .3s;
        }
    }

    .pager__item--selected {
        border-bottom: 2px solid black;

        span {
            font-size: 16px;
        }
    }
}
