////// tissot btn

button {
    line-height: 1.6em;
    border-radius: 0;
}

@mixin make-btn {
    font-family: $font-family;
    display: inline-flex;
    padding: 1.6rem 3rem;
    min-height: 5.2rem;
    border-radius: 0;
    border: 0;
    text-align: center;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.4rem;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: .8rem;

    span {
        z-index: 3;
        position: relative;
        display: flex;
        gap: .8rem;
        align-items: center;
        justify-content: center;
    }

    svg {
        z-index: 3;
        position: relative;
        min-width: 1.4rem;
        width: 1.4rem;
        height: 1.4rem;
        transition: all 0.2s ease-in-out;
    }
}

@mixin make-btn-primary {
    font-family: $font-family;
    background: $primary-bg;
    color: $white;
    position: relative;
    overflow: hidden;
    border: 0;
    cursor: pointer;
    box-shadow: 0 0 0 1px white;

    @include max-screen($screen__m) {
        width: 100%;
    }

    svg {
        fill: $white;
    }

    &:before {
        content: "";
        position: absolute;
        top: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
        background: $primary-bg;
        transition: all $transition-02;
    }

    &:hover, &:focus {
        color: $white;

        svg {
            fill: $white;
        }

        &:before {
            top: 0;
            background: $primary;
        }
    }

    &:focus-visible {
        border-color: $white;
        outline: 2px solid $primary;
        outline-offset: 4px;
    }

    &:visited {
        color: $white;
    }

    &:visited:not(:hover) {
        color: $white;
    }


    &:disabled {
        background: #bdbdbd;

        &:before {
            display: none;
        }
    }
}

@mixin make-btn-secondary {
    background-color: white;
    color: $primary-bg;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border: 0;
    transition: all $transition-02;
    box-shadow: 0 0 0 1px $primary-bg;

    svg {
        fill: $primary-bg;
    }

    &:before {
        content: "";
        position: absolute;
        top: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background-color: white;
        transition: all $transition-02;
    }

    &:hover, &:focus, &:focus-within {
        color: $white;
        box-shadow: 0 0 0 1px white !important;

        svg {
            fill: $white;
        }

        &:before {
            top: 0;
            background: $primary-bg;
        }
    }

    &:focus-visible {
        outline: 2px solid $primary;
        outline-offset: 4px;

        &:visited {
            color: $white;
        }
    }


    &:disabled {
        background: #bdbdbd;

        &:before {
            display: none;
        }
    }
}

@mixin make-btn-stroke {
    border: 1px solid $primary-bg;
    background: white;
    color: $black;
    transition: all $transition-02;
    position: relative;
    box-shadow: 0 0 0 1px transparent;

    span {
        z-index: 2;
        position: relative;
    }

    svg {
        fill: $black;
    }

    &:before {
        content: "";
        position: absolute;
        top: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1;
        background: $white;
        transition: all $transition-02;
    }

    @include max-screen($screen__m) {
        width: 100%;
        margin-top: 10px;
    }

    &:hover, &:focus, &:active {
        color: $white;
        box-shadow: 0 0 0 1px white;

        svg {
            fill: $white;
        }

        &:before {
            top: 0;
            background: $primary-bg;
        }
    }

    &:focus-visible {
        border-color: $white;
        outline: 2px solid $primary;
        outline-offset: 4px;
    }

    &.disabled {
        background: $light-grey2;
        border-color: $light-grey;
        cursor: not-allowed;

        &:hover {
            color: $black;
        }
    }
}

.btn {
    @include make-btn;

    &.-primary {
        @include make-btn-primary;
    }

    &.-secondary {
        @include make-btn-secondary;
    }

    &.-stroke {
        @include make-btn-stroke;
    }

    &.-big {
        font-size: 1.0em;
        font-weight: bold;
        padding: 14px 52px;
    }
}
