// =============================================================================
// 4# Generic / Common
// =============================================================================
* {
    box-sizing: border-box;
}

body {
    min-height: 100vh;

    /// keep the footer at the bottom of the page
    /// @example html
    ///   <body>
    ///     <main></main>
    ///     <footer></footer>
    ///   </body>
    display: flex;
    flex-direction: column;
    line-height: $line-height-default;
    font-family: $font-family;
    color: $content-primary;
    font-size: 1.6rem;
}

main {
    // keep the footer at the bottom of the page
    flex-grow: 1;
}

// fix for IE 11
a,
button {
    svg {
        pointer-events: none;
    }
}

a {
    color: $dark-grey;
    transition: color $transition-fast;
    cursor: pointer;

    &:hover {
        color: $primary;
        text-decoration: none;
    }

    &:visited:not(:hover) {
        color: $dark-grey;
    }
}

// a,
// button,
// input,
// select,
// ._keyfocus *,
// input:not([disabled]),
// textarea:not([disabled]),
// select:not([disabled]) {
//     outline: 2px solid transparent;
//     outline-offset: 0;
//     box-shadow: none;
//     transition: all $transition-fast;

//     &:focus {
//         outline-offset: 4px;
//         outline-color: $primary;
//     }
// }

a:focus-visible,
button:focus-visible,
input:not(:focus):focus-visible,
select:not(:focus):focus-visible,
._keyfocus *:focus-visible,
input:not([disabled]):not(:focus):focus-visible,
textarea:not([disabled]):not(:focus):focus-visible,
select:not([disabled]):not(:focus):focus-visible,
.focus {
    @include min-screen($screen__m) {
        outline: 2px solid $primary;
        outline-offset: 4px;
        box-shadow: none;
    }
}

._keyfocus *:focus,
input:not([disabled]):focus,
textarea:not([disabled]):focus,
select:not([disabled]):focus {
    box-shadow: none;
}

button:active,
.cart.table-wrapper .actions-toolbar > .action:active,
.action-gift:active {
    box-shadow: none;
}

.icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}

.icon-flag {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    overflow: hidden;
}

div[data-enable-parallax] {
    padding: 0 !important;
    margin: 0 !important;
}
body:not(.checkout-index-index) {
    .page-wrapper {
        padding-top: $header-total-height-mobile;
    }
    &.-has-callout .page-wrapper {
        padding-top: calc(#{$header-block-callout-height-mobile} + #{$header-total-height-mobile});
    }

    @include min-screen($screen__xl) {
        .page-wrapper {
            padding-top: $header-total-height;
        }
        &.-has-callout .page-wrapper {
            padding-top: calc(#{$header-block-callout-height} + #{$header-total-height});
        }
    }
}

body[class*="tissot_landing-page-"]{
    .page-wrapper {
        padding-top: calc(#{$header-height-mobile} + #{$header-header-bottom-height});
    }
    &.-has-callout .page-wrapper {
        padding-top: calc(#{$header-block-callout-height-mobile} + #{$header-height-mobile} + #{$header-header-bottom-height});
    }
    @include min-screen($screen__xl) {
        .page-wrapper {
            padding-top: calc(#{$header-height} + #{$header-header-bottom-height});
        }
        &.-has-callout .page-wrapper{
            padding-top: calc(#{$header-block-callout-height} + #{$header-height} + #{$header-header-bottom-height});
        }
    }
}

body.cms-home {
    .page-wrapper { // header is transparent expect callout block on top of header
        padding-top: 0;
    }
    &.-has-callout .page-wrapper {
        padding-top: $header-block-callout-height-mobile;
    }
    @include min-screen($screen__xl) {
        .page-wrapper{
            padding-top: 0;
        }
        &.-has-callout .page-wrapper{
            padding-top: $header-block-callout-height;
        }
    }
}

.page-wrapper > .block {
    margin-bottom: 0;
}

.badge {
    background-color: $dark-grey;
    color: #ffffff;
    padding: 2px 4px;
    font-size: 0.8em;
    font-weight: bold;
    margin-left: 4px;
}

div.field-recaptcha {
    div.field {
        div.control {
            position: relative;
            top: -25px;
            z-index: -1;

            input.mage-error {
                border-color: white;
            }

            input:focus {
                outline: none;
                outline-offset: 0;
            }

            div.mage-error {
                position: relative;
                top: unset;
                left: unset;
            }
        }
    }
}


// livechat vs filters mobile
@include max-screen($screen__m) {
    .page-with-filter {
        // Salesforce plugin
        .embeddedServiceHelpButton {
            .helpButton {
                bottom: 50px;
            }
        }

        // Facebook plugin
        .fb_dialog {
            .fb_dialog_content {
                iframe {
                    bottom: 50px !important;
                }
            }
        }
    }
}
