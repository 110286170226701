
div#adyen_cc_installments_div {
    display: block;
    label.label {
        padding: 0px;
        display: block;
        overflow: auto;
        width: initial;
        float: initial;
        text-align: left;
    }
    .field.number.cardContainerField {
        margin-bottom: 0px !important;
    }


}
#adyen-cc-form {
    button.action.primary.checkout{
        font-family: $font-family;
        background: #F77F00;
        color: #fff;
        position: relative;
        overflow: hidden;
        border: 0;
        display: inline-block;
        padding: 10px 30px;
        border-radius: 2px;
    }

}
.adyen-sprite.adyen_cc {
    margin-left: 10px;
    margin-right: 10px;
}
