// Reset Photoswipe buttons hover effect
button.pswp__button {
    $photoswipe-bkg: "../images/default-photoswipe-icons.png";
    &:hover {
        background-color: transparent;
        border: none;
        color: $white;
    }
    &--close:hover{
        background-image: url($photoswipe-bkg);
        background-position:0 -44px;
    }
    &--share:hover{
        background-image: url($photoswipe-bkg);
        background-position:-44px -44px;
    }
    &--fs:hover{
        background-image: url($photoswipe-bkg);
        background-position:-44px 0;
    }
    &--zoom:hover{
        background-image: url($photoswipe-bkg);
        background-position:-88px 0;
    }
    &.pswp--zoomed-in .pswp__button--zoom{
        background-image: url($photoswipe-bkg);
        background-position:-132px 0;
    }
    &--arrow--left::before{
        background-image: url($photoswipe-bkg);
        background-position:-138px -44px;
    }
    &--arrow--right::before{
        background-image: url($photoswipe-bkg);
        background-position:-94px -44px;
    }
}
