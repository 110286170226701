.text-media {
    display: flex;
    flex-flow: column;
    align-items: center;

    @include min-screen($screen__l) {
        flex-direction: row;
        height: 36vw;
    }

    &__media {
        position: relative;
        width: 100%;
        height: 80vw;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        @include min-screen($screen__l) {
            width: 50%;
            height: 100%;
        }
    }

    &__text {
        width: 100%;
        max-width: 80rem;
        text-align: center;
        padding: 2.4rem 1.2rem 4rem;

        @include min-screen($screen__l) {
            width: calc(50% - 2.4rem);
            margin: 0 1.2rem;
            text-align: start;
        }

        @include min-screen($screen__xl) {
            & > div {
                width: 66.66%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__video {
        padding-top: 56.25%;
        position: relative;

        @include min-screen($screen__l) {
            padding-top: 36vw;
        }

        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        right: 0;
        bottom: 0;
        width: 100% !important;
        height: 100% !important;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s, visibility 0.5s;

        &.hide {
            opacity: 0;
            visibility: hidden;
        }

        &:hover:before {
            width: 70px;
            height: 70px;
        }

        &:hover:after {
            border-top-width: 15px;
            border-bottom-width: 15px;
            border-left-width: 20px;
        }

        &:before,
        &:after {
            display: block;
            content: " ";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: all 0.3s;
        }

        &:before {
            width: 60px;
            height: 60px;
            background-color: $primary;
        }

        &:after {
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 15px solid white;
        }
    }

    &__label {
        @extend .text-highlight;
        margin-bottom: 0;
    }

    &__title {
        @extend .title-h3;
        margin-bottom: 8px;
        margin-top: 0;
    }

    &__description {
        @extend .text-default;
    }

    &__btn {
        @include make-btn;
        @include make-btn-primary;
        margin-top: 1rem;
    }

    &__link {
        @extend .link-more;
    }

    &__container {
        &__gwp-container {

        }
    }

}

.wide_contentsystem-right {
    @include min-screen($screen__l) {
        .text-media {
            flex-direction: row-reverse;
        }
    }
}

.wide_contentsystem-left {

}

.text-media.bg-white,
.bg-white .text-media {
    background: white !important;
}

.text-media.bg-black,
.bg-black .text-media {
    background: black !important;

    &__title,
    &__description {
        color: white;
    }
}
