.pagecontent-columns {
    $col-space: 10px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 30px 15px;

    @include min-screen($screen__xl) {
        padding: 40px calc(3vw + 20px);
    }

    &.no-padding-top {
        padding-top: 0 !important;
    }

    .row {
        box-sizing: border-box;
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -$col-space;
        margin-left: -$col-space;

        > [class*="col-"] {
            flex: 0 0 auto;
            flex-basis: auto;
            padding-right: $col-space / 2;
            padding-left: $col-space / 2;
            margin: $col-space 0;
        }

        .col-6 {
            flex-basis: 50%;
            max-width: 50%;
        }

        @media screen and (min-width: $screen__m) {
            .col-md-4 {
                flex-basis: 33.33333333%;
                max-width: 33.33333333%;
            }
            .col-md-8 {
                flex-basis: 66.66666667%;
                max-width: 66.66666667%;
            }
        }
        @include max-screen($screen__m) {
            .col-sm-12 {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }

    h2, .h2 {
        @extend .title-h2;
        margin-bottom: 50px;
        text-align: center;
        text-align: inherit !important;
    }

    .swiper-slide {
        &:first-child {
            padding-left: 0 !important;
        }

        &:last-child {
            padding-right: 0 !important;
        }
    }

    .container-small-center {
        text-align: center;
        max-width: 1450px;
        margin-left: auto;
        margin-right: auto;
    }

    &.pictures__content {
        .row {
            align-items: stretch;
            justify-content: center;

            > [class*="col-"] {
                margin-top: 0;
                margin-bottom: 0;

                &.col-6 {
                    margin-bottom: 1rem;

                    figure {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                }
            }
        }

        &.two-one figure {
            margin-top: 0;
        }

        figure {
            margin-top: $col-space;
            margin-bottom: $col-space;
            position: relative;
            text-align: center;

            img {
                height: auto;
                width: auto;
                display: block;
            }

            figcaption {
                position: absolute;
                padding: 3rem;
                top: 50%;
                left: 0;
                right: 0;
                margin: auto;
                transform: translateY(-50%);

                h2, .h2 {
                    color: $white;
                    text-shadow: 1px 1px rgba($black, .3);
                }
            }
        }

        .action {
            margin: 2rem auto;
            text-align: center;
        }

        @include min-screen($screen__m) {
            .row {
                %centered_image {
                    height: 100%;
                    margin: auto;
                    object-fit: cover;
                    width: 100%;
                    z-index: 0;
                }

                > [class*="col-md-8"],
                > [class*="col-md-4"] {
                    display: flex;
                    flex-flow: row wrap;
                    align-items: stretch;
                    figure{
                        display: block;
                        width: 100%;
                        margin-top: 0;
                        margin-bottom: 0;
                        img {
                            max-width: inherit;
                            object-position: center;
                            object-fit: cover;
                            position: relative;
                            left: 50%;
                            transform: translateX(-50%);
                            margin: auto;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .col-md-8 {
                    overflow: hidden;
                    position: relative;
                    display: flex;
                    flex-flow: row wrap;
                    align-items: stretch;
                    justify-content: center;

                    img {
                        @extend %centered_image;
                    }

                    figcaption {
                        z-index: 2;
                    }
                }

                .col-6 figure {
                    position: relative;

                    img {
                        @extend %centered_image;
                    }
                }
            }
        }

        .cms-home &{
            figure{
                overflow: hidden;
                img{
                    transition: transform 500ms;
                }
                &:hover img{
                    transform: scale(1.02);
                }
            }
            @include min-screen($screen__m) {
                .row > [class*="col-"] figure:hover img{
                    transform: scale(1.02) translateX(-49.02%);
                }
            }
        }
    }

    &.destructured-content-push {
        @extend .container-small-center;

        h2, .h2 {
            margin-bottom: 4.5rem;
            text-transform: uppercase;
        }

        .destructured-content-push__content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-flow: row wrap;

            &__image_wrapper {
                position: relative;
                text-align: right;
                z-index: 1;

                figure {
                    margin-top: $col-space;
                    margin-bottom: $col-space;
                    max-height: 890px;
                    height: 95vw;
                    overflow: hidden;
                }

                img {
                    display: block;
                    position: relative;
                    max-width: inherit;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100%;
                    width: auto;
                }
            }

            &__content_wrapper {
                background-color: $white;
                box-sizing: border-box;
                margin: -14rem auto 0;
                max-width: 540px;
                padding: 4rem;
                position: relative;
                width: calc(100% - 70px);
                z-index: 3;

                p {
                    font-weight: $weight-light;
                }

                .action {
                    padding-top: 1rem;
                }

                &.-product {
                    margin-top: -7rem;
                    max-width: 480px;

                    .product-thumbnail__description {
                        margin-top: 2rem;
                        padding: 0;
                    }

                    .product-thumbnail__img-container img {
                        width: 110%;
                        max-width: none;
                    }

                    h3, .h3 {
                        font-size: 2.4rem;
                        font-weight: 400;
                        letter-spacing: 0;
                        margin-bottom: 0.5rem;
                        margin-top: 0.5rem;
                    }

                    p.badge-technical span {
                        font-weight: $weight-light;
                    }
                }
            }
        }

        @include min-screen($screen__xl) {
            .destructured-content-push__content {
                flex-direction: row-reverse;
                align-items: stretch;

                &__image_wrapper {
                    margin-left: -200px;
                    width: calc(100% - 200px);

                    figure {
                        max-height: inherit;
                        height: auto;
                    }

                    img {
                        transform: translateX(0);
                        position: absolute;
                        height: 100%;
                        left: 0;
                        object-fit: cover;
                        top: 0;
                        width: 100%;
                        z-index: -1;
                    }
                }

                &__content_wrapper {
                    font-size: 2.1rem;
                    margin: 7rem 0;
                    max-width: 100%;
                    padding: 7rem 5rem;
                    width: 400px;

                    h3, .h3 {
                        font-size: 3rem;
                        font-weight: $weight-regular;
                    }

                    .action {
                        padding-top: 5rem;
                    }

                    &.-product {
                        margin-top: 7rem;
                        padding: 4rem 2rem;

                        h3, .h3 {
                            font-size: 2.2rem;
                            margin-bottom: 2rem;
                        }
                    }
                }
            }
        }
        @include max-screen($screen__xs) {
            .destructured-content-push__content__content_wrapper {

                h3, .h3 {
                    margin-bottom: 3rem;
                }
            }
        }
    }

    .text-center,
    &.text-center {
        justify-content: center;
        text-align: center;
    }

    &.horizontal-thumbnails__content {

        .slider {
            margin-bottom: 0;
            padding-top: 2rem;
            position: relative;

            figure {
                overflow: hidden;

                figcaption {
                    h3, .h3 {
                        margin-bottom: 2.5rem;
                        margin-top: 1.6rem;
                    }
                }

                img {
                    width: 100%;
                }
            }

            .swiper {
                &-scrollbar {
                    display: none;
                }

                &-wrapper {
                    align-items: stretch;
                }

                &-slide {
                    align-self: stretch;
                    display: flex;
                    flex-wrap: wrap;
                    height: auto;

                    &--wrapper,
                    &--wrapper figure,
                    &--wrapper figcaption {
                        align-items: stretch;
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;

                        img {
                            margin-top: auto;
                        }
                    }

                    &--wrapper figure {
                        height: 100%;
                    }

                    &--wrapper figcaption {
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            //.swiper-slide {
            //    width: auto!important;
            //    padding: 0;
            //}
        }
        @include min-screen($screen__xl) {
            figure{
                figcaption * {
                    transition: color 200ms;
                }
                img {
                    transition: transform 200ms;
                }
                &:hover {
                    figcaption * {
                        color: $primary;
                    }
                    img {
                        transform: scale(1.03);
                    }
                }
            }
        }
        @include max-screen($screen__m) {
            .slider {
                padding-top: .5rem;
            }
            .swiper-container .swiper-slide {
                opacity: 1;
            }
        }
    }

    &.mosaic-3-images {
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        @extend .container-small-center;

        .mosaic-3-images {
            &__swiper-pagination .swiper-pagination-bullet-active {
                background-color: $red;
            }

            &__wrapper {
                padding-top: 1rem;
                position: relative;
            }

            &__content {
                > a {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                }
            }

            &__image {
                margin: 0;
                width: 100%;

                img {
                    display: block;
                    margin: 0 auto;
                    max-width: 100%;
                    width: auto;
                }
            }

            &__text {
                align-items: center;
                background-color: $white;
                display: flex;
                flex-flow: column wrap;
                font-weight: $weight-light;
                justify-content: center;
                padding: 2rem;
                text-align: center;
                width: 100%;

                p {
                    margin-bottom: 0;
                }

                .uppercase {
                    display: block;
                    font-weight: $weight-bold;
                    text-transform: uppercase;
                }

                &.-bigger {
                    font-size: 200%;
                }
            }
        }

        @include min-screen($screen__m) {
            padding-bottom: 0;
            .swiper {
                &-wrapper {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;
                }

                &-pagination {
                    display: none;
                }
            }
            .mosaic-3-images {
                &__wrapper {
                    padding-top: 4rem;
                }

                &__content {
                    display: flex;
                    flex-flow: column wrap;
                    width: 33.33333% !important;

                    &:nth-child(even):not(.-has-link),
                    &:nth-child(even) > a {
                        flex-direction: column-reverse;
                    }
                }

                &__image,
                &__text {
                    width: 100%;
                }

                &__text {
                    position: relative;
                    padding-bottom: calc(100% - 2rem);

                    &__wrapper {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    &.lifestyle-slider {
        max-width: 1450px;
        margin: 0 auto;

        .lifestyle-slider {
            &__title {
                margin-bottom: 2rem;
            }

            &__wrapper {
                padding-bottom: 5rem;
                position: relative;
            }

            &__content {
                align-items: center;
                display: flex;
                flex-flow: column-reverse wrap;
                justify-content: center;
                text-align: center;

                &__product {
                    box-sizing: border-box;
                    padding: 2rem;
                }

                &__image {
                    margin: 0;

                    img {
                        display: inline-block;
                        width: 100%;
                        vertical-align: middle;
                    }
                }

                &__text {
                    font-weight: $weight-light;
                    padding-top: 1.5rem;

                    p {
                        margin-bottom: 0;

                        &.uppercase {
                            text-transform: uppercase;
                            font-weight: $weight-bold;
                            display: block;
                        }
                    }
                }

            }

            &__link {
                margin-top: 3rem;
            }

        }

        .swiper-pagination-bullet-active {
            background-color: $red;
        }

        @include min-screen($screen__m) {
            .lifestyle-slider {
                &__title {
                    margin-bottom: 4rem;
                }

                &__content {
                    flex-direction: row;

                    &__product {
                        flex: 0 0 40%;
                        max-width: 40%;
                        padding: 2rem 4rem;
                    }

                    &__image__wrapper {
                        flex: 0 0 60%;
                        max-width: 60%;
                    }

                    &.-revert {
                        flex-direction: row-reverse;
                    }
                }
            }
            .swiper {
                &-pagination {
                    margin-left: 20%;
                }
            }
        }
    }
}
