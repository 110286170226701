.footer {
    border-top: 1px solid $border-color;

    .footer-list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .footer-label {
        display: block;
        margin-top: .8rem;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 2.2rem;
        text-transform: none;
        font-family: $font-family-extended;

        @include min-screen($screen__m) {
            font-weight: 300;
            font-size: 1.8rem;
            line-height: 2.4rem;
        }
    }

    li {
        margin-bottom: 0;
        width: 150px;
        text-align: center;

        &,
        a {
            text-transform: uppercase;
        }
    }

    .footer-top {
        justify-content: center;
        padding: 1.6rem 1.2rem;

        @include min-screen($screen__m) {
            padding: 1.6rem 5rem;
        }

        @include min-screen($screen__xl) {
            padding: 1.6rem 7rem;
        }

        ul {
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            gap: 2rem;

            @include min-screen($screen__m) {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }

            li {
                width: 100%;

                svg {
                    min-width: 6rem;
                    width: 6rem;
                    height: 6rem;
                }
            }
        }
    }

    .footer-bottom {
        padding: 40px 0 34px;
        background-color: black;

        @include min-screen($screen__xl) {
            padding: 40px 3vw;
        }

        &,
        a {
            color: white;
        }
    }

    .footer-logo {
        text-align: center;
    }

    .footer-titles,
    .footer-menus {
        display: flex;
        justify-content: center;

        @include max-screen($screen__s) {
            flex-flow: row wrap;
        }
    }

    .footer-titles {
        margin-top: 80px !important;

        li {
            width: 50%;
            padding: 0 10px;

            &:nth-child(n+3) {
                margin-top: 50px !important;
            }

            @include min-screen($screen__s) {
                width: 25%;

                &:nth-child(n+3) {
                    margin-top: 0 !important;
                }
            }

            @include min-screen($screen__m) {
                padding: 0 20px;
            }
        }
    }

    .footer-menus {
        margin-top: 66px;

        .wide_contentsystem_summary {
            width: 50%;

            @include max-screen($screen__s) {
                &:nth-child(n+3) {
                    margin-top: 26px;
                }
            }

            @include min-screen($screen__s) {
                width: 25%;
            }

            @include min-screen($screen__l) {
                padding: 0 2%;
            }
        }
    }

    .footer-menu {
        li {
            margin: 0;
            padding-left: 30px;
            width: 100%;
            text-align: left;

            @include min-screen($screen__s) {
                width: auto;
                padding-left: 0;
                text-align: center;
            }

            & + li {
                margin-top: 20px;
            }
        }

        a {
            position: relative;
            display: inline-block;
            font-size: 1.2rem;

            &:hover:after {
                width: 100%;
            }

            &:after {
                position: absolute;
                bottom: -5px;
                display: block;
                content: "";
                width: 0;
                height: 1px;
                background-color: white;
                transition: width .3s;
            }
        }
    }

    .footer-copyright {
        margin-top: 91px !important;
        margin-bottom: 0 !important;
        font-size: 1rem;
        text-align: center;
        color: $light-grey2;
    }

    .icon {
        width: 2.5rem;
        height: 2.5rem;
        color: $primary;

        &--red {
            color: $primary;
        }

        & + .icon {
            margin-left: 5px;
        }

        &.icon-flag {
            clip-path: circle(10px);
            background: red;
        }
    }

    &.content {
        border: 0;
        padding: 0;
        margin: 0;

    }

    .wide_contentsystem_summary {
        margin-bottom: 0;
    }
}
