.product-description {
    max-width: $width-medium;
    margin: 0 auto 0;

    &__title {
        @extend .title-h3;
        text-align: center;
    }

    p {
        @extend .text-default;
        color: $light-grey;
    }
}

////////////////////////////////////////////////////////////////////////////////////
.product-add-form {
    width: 100%;
    position: relative;

    > form {
        width: 100%;
    }

    .product-options-wrapper {
        padding: 0 0 2rem;

        @include min-screen($screen__m) {
            padding: 0 0 2.4rem;
        }
    }

    .product-options-bottom,
    .product-options-bottom-links {
        display: block;
        align-items: center;

        button.btn {
            height: unset;
            margin-top: 10px;

            @include min-screen($screen__l) {
                &.action.tocart, &.btn-open-modal-retailer-links {
                    margin-bottom: 10px !important;
                }
            }

            span {
                white-space: pre-wrap;
            }
        }

        .open-contact-us-in-store-div,
        .product-options-bottom-button,
        .open-appointment-in-store-div,
        .open-digital-appointment-in-store-div,
        .open-reserve-in-store-div {
            text-align: left;
            display: block;

            @include max-screen($screen__l) {
                margin-bottom: 10px;
            }

            .btn {
                @include max-screen($screen__l) {
                    width: 100%;
                }
            }
        }

        .product-options-available-from {
            margin-bottom: 10px;
            display: inline-block;
            font-weight: normal;
            font-size: 1.2rem;
            line-height: 1.33em;
            letter-spacing: 2px;
            color: $dark-grey;
            text-transform: uppercase;
        }

        .open-appointment-in-store-div,
        .open-digital-appointment-in-store-div,
        .open-reserve-in-store-div,
        .open-contact-us-in-store-div {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @include min-screen($screen__l) {
                margin-top: 0;
                margin-bottom: 10px;
            }

            a#file-help-btn {
                top: unset;
                right: unset;
                position: relative;
            }

            button.btn {
                flex-wrap: wrap;
                align-items: center;
                height: 100%;
            }
        }


        .product-addtocart-backsale-text {
            width: 100%;
        }

        .product-addtocart-presale {
            color: $orange;
            width: 100%;

        }
    }

    .open-contact-us-in-store-div,
    .open-appointment-in-store-div,
    .open-digital-appointment-in-store-div,
    .open-reserve-in-store-div {
        display: inline-block;
        width: 100%;

        .btn {
            width: calc(100%);
        }

        #file-help-btn {
            width: fit-content;
            position: relative;
            right: calc(-100% + 32px);
            top: -30px;
        }
    }

    .action.tocartupdate {
        line-height: 2.2rem;
        padding: 14px 17px;
    }

    .btn {
        width: 100%;
        margin: 0 0 .8rem !important;

        span {
            white-space: nowrap;
        }
    }

    .help-btn {
        display: flex;
        align-items: center;
    }

    .update {
        .btn {
            width: 100%;
        }
    }

    &.--online-button {
        .open-contact-us-in-store,
        .open-appointment-in-store,
        .open-reserve-in-store {
            margin-top: -30px;
        }
    }

    .product-retailers-pop {
        width: 100%;
        max-width: 600px;
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #7777;
        z-index: 9999;
        box-sizing: border-box;
        padding: 5px;

        &__close {
            text-align: left;
            height: fit-content;
            width: 100%;

            button {
                padding: 0 15px 0 15px;
            }
        }

        .product-retailers-header {
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 10px;

            h1 {
                width: 100%;
                text-align: center;
                font-size: 24px;
                font-weight: 500;
                margin-bottom: 20px;
            }

            p {
                width: 100%;
                text-align: center;
                font-size: 14px;
                font-weight: 300;
            }

            margin-bottom: 20px;
        }

        .product-retailers-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .retailer-container {
                padding: 3px;
                text-align: center;

                @include min-screen($screen__m) {
                    width: calc(50% - 30px);
                }

                @include max-screen($screen__m) {
                    width: 65%;
                }

                .retailer-media {
                    width: 75%;
                    display: inline-block;
                }

                .retailer-link {
                    width: min-content;
                }
            }
        }
    }
}


.product-options-bottom-links {
    padding: 0 0 0 0;
}

.out-stock-container {
    .out-stock-buttons {
        padding: 2px 0 0 0;
    }
}

.modal-popup.modal-product-360 {
    .modal-inner-wrap {
        max-height: 100vh;
        width: 100vw;
        height: 100vh;
        margin: 0;
        display: flex;
        flex-direction: column;

        .modal-content,
        #modal-360,
        .modal-dialog,
        .modal-inner-content {
            height: 100%;
            max-height: 100%;
        }

        .modal-inner-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .container-360 {
                flex-grow: 1;
                width: 100%;
                margin: 20px;
                background-image: var(--background-image-modal-360); // set by jQuery widget
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;

                img {
                    // visibility:hidden instead of display:none so the browser
                    // loads the images, preventing flickering on the 360
                    visibility: hidden;
                    position: absolute;
                    top: 0;
                }
            }
        }
    }
}

.page-product {
    padding-bottom: 30px;
    max-width: 100vw;

    .content-push {
        &:nth-child(even) {
            .text-media {
                background: white;
            }
        }

        &:nth-child(odd) {
            .text-media {
                background: #F6F6F6;
            }
        }
    }

    @include min-screen($screen__l) {
        padding-bottom: 50px;

        .content-push {
            &:nth-child(even) {
                .text-media {
                    flex-direction: row;
                }
            }

            &:nth-child(odd) {
                .text-media {
                    flex-direction: row-reverse;
                }
            }
        }
    }
    .text-media__container__gwp-container .text-media {
        @include min-screen($screen__l) {
            flex-direction: row !important;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .breadcrumbs, .product-form {
        background: var(--Surface-Section-Secondary, #F8F7F7);
    }

    .product-form + .product-form {
        border-top: 1px solid var(--Border-Secondary, #EAE8E7);
    }
}

.product-mosaic {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    padding: 0 1.2rem;
    margin: 0 auto;

    @include min-screen($screen__l) {
        flex-direction: row;
        padding: 3.2rem 5rem 5rem;
    }

    @include min-screen($screen__xxl) {
        padding-left: 18rem;
        padding-right: 18rem;
    }

    &__item {
        .simple_text,
        .simple_html {
            margin: initial;
            padding: initial;
        }

        &:nth-child(2) {
            background: transparent;
        }
    }

    &__img-container {
        padding-top: 100%;
        position: relative;
        overflow: hidden;
        background: $ultralight-grey;
        cursor: zoom-in;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            transition: all 0.5s ease-in-out;
            min-width: 100%;
            margin-bottom: 0;
        }
    }

    #product-mosaic-container {
        position: relative;

        #product-360-container {
            position: absolute;
            bottom: 0;
            right: 5px;
            cursor: pointer;

            img {
                -ms-transition: 0.6s ease-in-out;
                -webkit-transition: 0.6s ease-in-out;
                transition: 0.6s ease-in-out;

                &:hover {
                    -ms-transform: rotate(-360deg); /* IE 9 */
                    -webkit-transform: rotate(
                            -360deg
                    ); /* Chrome, Safari, Opera */
                    transform: rotate(-360deg);
                }
            }
        }

        .rotor-notice {
            font-style: italic;
            margin: 10px;
            font-size: 14px;
        }
    }

    &__medias {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        position: relative;
        margin-bottom: 2.4rem;
        max-width: 100%;

        .product-images__items img {
            margin-left: auto;
            margin-right: auto;
        }

        .product-mosaic__img-thumbs {
            background-color: $white;
            border: solid 1px $border-color;
            max-width: 5rem;
            width: auto;
        }

        .swiper {
            &-button-next,
            &-button-prev {
                color: $dark-grey;
                display: block;
                font-size: 2rem;
                text-align: center;
                transition: all .5s ease-in-out;

                &::after {
                    font-size: inherit;
                    display: block;
                }

                &:hover,
                &:focus {
                    color: $orange;
                }

                &.swiper-button-disabled {
                    opacity: 0;
                }
            }

            &-wrapper {
                margin-bottom: 0;
            }

            &-slide {

            }

        }

        // Override Photoswipe
        .pswp {
            &--touch.pswp--animated-in .pswp__img,
            &--zoomed-in .pswp__img {
                object-fit: unset;
                transform: scale(1.4) !important;

                @include max-screen($screen__m) {
                    transform: scale(1) !important;
                }
            }

            &--visible {
                .pswp__ui--idle {
                    .pswp__top-bar,
                    .pswp__button--arrow--left,
                    .pswp__button--arrow--right {
                        opacity: 1;
                    }
                }
            }

            &__img {
                max-width: inherit;
                object-fit: cover;
                transform: scale(1);
                transition: transform .5s ease;

                @include max-screen($screen__m) {
                    transform: scale(1);
                }
            }

            &__item {
                background-color: $white;
            }

            &__top-bar {
                width: auto;
                right: 0;
                left: auto;

                .pswp__preloader {
                    display: none;
                }
            }
        }

        .product-images__thumbnails {
            height: auto;
            position: relative;
            width: 100%;
            display: none;

            &_swiper {
                overflow: hidden;
            }

            .swiper {

                &-button-prev,
                &-button-next {
                    visibility: hidden;
                    opacity: 0;
                }


                &-slide {
                    transition: opacity .3s ease;
                    background: white;

                    img {
                        cursor: pointer;
                    }
                }
            }

            @include max-screen($screen__xl) {
                margin-bottom: 2rem;

                .swiper-slide {
                    width: auto !important;
                }
            }
            @include max-screen($screen__l) {
                &_wrapper .centered-sm {
                    .swiper-wrapper {
                        transform: translate3d(0px, 0px, 0px) !important;
                        justify-content: center;
                    }
                }
            }
            @include max-screen($screen__m) {
                &_wrapper .centered-xs {
                    .swiper-wrapper {
                        transform: translate3d(0px, 0px, 0px) !important;
                        justify-content: center;
                    }
                }
            }
        }

        .product-images__items {
            overflow: hidden;
            width: 100%;

            .swiper {
                &-wrapper {
                    align-items: center;
                    list-style-type: none;
                    padding: 0 0 4rem;

                    @include min-screen($screen__l) {
                        padding-bottom: 0;

                        & + .swiper-pagination {
                            display: none;
                        }
                    }
                }

                &-slide {
                    margin-bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: opacity .5s ease-in-out, visibility .5s ease-in-out;

                    a, img {
                        cursor: zoom-in;
                        display: block;
                        max-height: 40rem;
                        outline-offset: -2px;

                        @include min-screen($screen__l) {
                            max-height: 63.8rem;
                        }
                    }

                    .product-mosaic-modal__img-container img {
                        margin-bottom: auto;
                    }

                    &:not(.swiper-slide-active) {
                        opacity: 0;
                        visibility: hidden;
                    }
                }

                &-button {
                    &-next,
                    &-prev {
                        height: 5rem;
                        text-align: center;
                        font-size: 3rem;
                        line-height: 5rem;
                        background-color: transparent;
                        border-radius: 50%;
                        width: 5rem;

                        &::after {
                            font-weight: $weight-bold;
                            font-size: inherit;
                            line-height: inherit;
                        }
                    }

                    &-prev {
                        left: 0;

                        &::after {
                            text-indent: -.5rem;
                        }
                    }

                    &-next {
                        right: 0;

                        &::after {
                            text-indent: .5rem;
                        }
                    }
                }

            }

            @include max-screen($screen__xl) {
                .swiper-button {
                    &-next {
                        right: 1.5rem;
                    }

                    &-prev {
                        left: 1.5rem;
                    }
                }
            }
        }

        img {
            display: block;
            max-width: 100%;
            width: auto;
        }

        .mido-modal {
            padding: 0;
            border: none;
            background: none;
            display: flex;
            gap: .8rem;
            align-items: center;
            margin: 0 auto;
            border-radius: 0;

            @include min-screen($screen__l) {
                margin: 0;
            }

            span {
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: 13px;
                text-decoration-line: underline;
            }
        }
    }

    @include min-screen($screen__l) {

        &__medias {
            width: 50%;
            margin-bottom: 0;
            position: relative;
            margin-right: auto;
            align-items: flex-start;

            .product-images__thumbnails {
                bottom: auto;
                left: 0;
                margin: auto;
                position: absolute;
                top: auto;
                width: 7rem;
                padding: 0;
                display: none;

                &_wrapper {
                    padding: 5rem 0;
                    position: relative;
                    max-height: 70rem;
                    overflow: hidden;
                    width: 100%;

                    &.centered-v {
                        .swiper-wrapper {
                            height: auto;
                            top: 50%;
                            transform: translate3d(0px, -50%, 0px) !important;
                        }

                        .swiper-button-prev,
                        .swiper-button-next {
                            display: none;
                        }
                    }
                }

                .swiper {
                    &-wrapper {
                        .swiper-slide {
                            width: 100%;
                            height: 100%;
                            text-align: center;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            &:after {
                                content: '';
                                display: block;
                                position: absolute;
                                bottom: 0;
                                height: .2rem;
                                width: 100%;
                                background: transparent;
                                transition: all 0.2s ease-in-out;
                            }

                            &.swiper-slide-thumb-active {
                                &:after {
                                    background: $content-primary;
                                }
                            }

                            figure {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                overflow: hidden;
                                max-width: none;
                                transition: all 0.2s ease-in-out;

                                &:hover {
                                    border-color: $content-primary;
                                }

                                img {
                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                    }

                    &-button-next,
                    &-button-prev {
                        background-color: transparent;
                        border-radius: 0;
                        display: block;
                        font-size: 2rem;
                        font-weight: $weight-bold;
                        height: 4rem;
                        left: 0;
                        line-height: 4rem;
                        margin: 0 auto;
                        opacity: 1;
                        padding: 0 .5rem;
                        right: auto;
                        text-align: center;
                        top: 0;
                        visibility: visible;
                        width: 7rem;
                        z-index: 3;
                        outline-offset: -2px;

                        &::before {
                            transform: rotate(180deg);
                        }

                        &::after {
                            line-height: inherit;
                            transform: rotate(90deg);
                        }

                        &.swiper-button-disabled {
                            opacity: 0;
                        }
                    }

                    &-button-next {
                        top: auto;
                        bottom: 0;

                        &::after {
                            transform: translate(-2%, 2px) rotate(90deg);
                        }
                    }

                    &-button-prev {
                        transform: rotate(180deg);
                    }

                    &-button-prev::after {
                        transform: translate(-2%, 0) rotate(90deg);
                    }
                }

                &_swiper {
                    display: block;
                    height: 53.8rem;
                    overflow: hidden;
                    position: relative;
                }

                &__slider {
                    position: relative;
                    padding-top: 5rem;
                    padding-bottom: 5rem;
                }
            }

            .product-images__items {
                margin-left: 18%;
                height: 63.8rem;
                display: flex;
                align-items: flex-start;
            }
        }
    }

    @include min-screen($screen__l) {
        &__medias {
            .product-images__thumbnails {
                display: block;
            }
        }
    }
    @include min-screen($screen__xl) {
        &__medias {
            width: 58.3333333333%;

            .product-images__items {
                margin-left: 15%;
            }
        }
    }
}

.product-mosaic__info-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;

    @include min-screen($screen__l) {
        width: 41.6666666667%;
    }

    @include min-screen($screen__xl) {
        width: 33.3333333333%;
    }

    .product-mosaic__info {
        flex: 1;
        max-width: 100%;

        .product-label {
            @extend .text-highlight;
            font-family: $font-family;
            margin-bottom: .7rem;

            @include min-screen($screen__l) {
                margin-bottom: 1.2rem;
            }
        }

        .product-name {
            margin-bottom: 1rem;
            text-transform: none;

            font-weight: 400;
            font-size: 3rem;
            line-height: 3.4rem;
            letter-spacing: -1px;
            color: $content-primary;

            @include min-screen($screen__l) {
                font-size: 3.6rem;
                line-height: 4.2rem;
            }

        }

        .product-sku {
            font-family: $font-family;
            color: $content-secondary;
            font-size: 1.2rem;
            font-weight: 500;
            line-height: 1.3rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 1.2rem;

            @include min-screen($screen__m) {
                font-size: 1.2rem;
                line-height: 1.4rem;
            }
        }


        .product-form-actions {
            margin-top: 2.4rem;
        }

        .product-price {
            color: $content-primary;
            display: flex;
            flex-wrap: wrap;
            gap: 0.8rem 1.2rem;
            align-items: baseline;
            margin-bottom: 1.2rem;

            .price-final_price {
                span {
                    font-family: $font-family-condensed;
                    font-size: 2.2rem !important;
                    font-weight: 500;
                    line-height: 2.6rem;
                    letter-spacing: 1px;
                    text-transform: uppercase;

                    @include min-screen($screen__m) {
                        font-size: 2.4rem !important;
                    }
                }
            }

            .product-price-extra {
                font-family: $font-family-condensed;
                font-size: 2.2rem !important;
                font-weight: 500;
                line-height: 2.6rem;
                letter-spacing: 1px;
                text-transform: uppercase;

                @include min-screen($screen__m) {
                    font-size: 2.4rem !important;
                }
            }

            .price-information, .price-information-x-month {
                width: fit-content;
                text-transform: none;
                display: flex;
                align-items: center;
                line-height: 2rem;
                font-size: 1.4rem;


                &.old-price {
                    text-decoration: line-through;
                    display: inline;
                    color: $light-grey;
                }

                img {
                    height: 2.6rem;
                    margin-left: .8rem;
                    vertical-align: middle;
                }

                &.-de {
                    color: $content-tertiary;
                    font-size: 1.3rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.1rem;
                    font-family: $font-family-condensed;

                    & + .price-information, & + .price-information-x-month {
                        width: 100%;
                    }
                }
            }
        }

        .retailer-link {
            width: 100%;

            @include min-screen($screen__l) {
                width: calc(50% - 5px);
                margin-bottom: 10px !important;
            }
        }

        .product-variants-list {
            list-style: none;
            padding: 0;
            display: block;
            font-size: 0;
            white-space: nowrap;
            overflow: auto;
            margin: 0 -17px 20px;

            @include min-screen($screen__m) {
                margin: 0 0 20px -10px;
                display: flex;
                flex-flow: row;
                flex-wrap: wrap;

                white-space: normal;
                font-size: 1em;
            }

            li {
                width: 60px;
                margin-left: 10px;

                display: inline-block;

                &:first-child {
                    margin-left: 17px;
                }

                &:last-child {
                    margin-right: 17px;
                }

                @include min-screen($screen__m) {
                    &:first-child {
                        margin-left: 10px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            img {
                max-width: 100%;
                height: auto;
                display: block;
            }

            a {
                display: block;
                border: 1px solid $border-color;
                transition: border-color $transition-slow;
                overflow: hidden;

                &:hover {
                    border-color: $border-active-color;
                }
            }
        }

        .product-size {
            display: flex;
            gap: .8rem;
            align-items: center;
            position: relative;

            label {
                color: $content-tertiary;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.6rem;
                letter-spacing: 0.1rem;
                text-transform: uppercase;
                margin-bottom: .8rem;
                display: block;

                @include min-screen($screen__m) {
                    display: none;
                }
            }

            svg {
                display: none;
                min-width: 3rem;
                margin-left: 1.2rem;

                @include min-screen($screen__m) {
                    display: block;
                }
            }

            .custom-select-2 {
                flex-grow: 1;
            }

            select {
                @extend .text-default;
                display: inline-block;
                background: white;
                border: 1px solid $border-color;
                padding: 1.4rem 3rem 1.4rem 1.6rem;
                text-transform: none;
                background: white url("../images/icons/chevron-down.png") 98% center no-repeat;
                background-size: 22px;
            }

            .product-size-description {
                position: absolute;
                top: -3px;
                right: 0;

                @include min-screen($screen__m) {
                    position: relative;
                    top: 0;
                }

                a {
                    color: $black;
                    text-decoration: underline;
                    display: block;
                    line-height: 2rem;
                    font-size: 1.4rem;
                    text-underline-offset: 4px;
                    white-space: nowrap;
                }
            }
        }

        .product-cta {
            margin-top: 30px;
            display: flex;
            flex-flow: column;

            @include min-screen($screen__m) {
                flex-flow: row;
                flex-wrap: nowrap;
                justify-content: space-between;
            }

            .btn {
                width: 100%;

                @include min-screen($screen__m) {
                    width: calc(50% - 5px);
                }

                & + .btn {
                    margin-top: 10px;

                    @include min-screen($screen__m) {
                        margin-top: 0;
                    }
                }
            }
        }

        .product-info--additional {

            .link-storelocator__button {
                width: 100%;
                padding: 1.8rem 1rem;
                display: flex;
                gap: .8rem;
                align-items: center;
                justify-content: center;
                font-size: 1.3rem;
                font-weight: 500;
                line-height: 1.4rem;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: $content-primary;
            }

            .simple_html {
                max-width: none;
                margin-top: 2rem;
                padding-top: 2rem;
                border-top: 1px solid var(--Border-Secondary, #C7C7C7);

                .content-system--html {
                    gap: 2.4rem;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                }
            }

            ul {
                width: 100%;
                padding: 0;
                margin: 0 0 3.2rem;
                list-style: none;
                gap: 1.6rem;
                display: flex;
                align-items: flex-start;

                @include min-screen($screen__m) {
                    gap: 2.4rem;
                }

                li {
                    flex-grow: 1;
                    width: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: .8rem;
                    text-align: center;
                    margin-bottom: 0;
                    @extend .text-default;
                    color: $content-primary;

                    svg {
                        min-width: 5rem;
                        width: 5rem;
                        height: 5rem;
                    }
                }
            }
            .open-find-in-store-div {
                 .-stroke {
                   // margin-top: -10px;

                     @media screen and (max-width: $screen__m) {
                        margin-top: 0;
                    }
                }

                .-ecom {
                    margin-top: -10px;
                    border: 0;
                    font-size: 1.1rem;
                    font-weight: 500;
                    line-height: 1.4rem;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: #121212;

                    svg {
                        fill: $black;
                    }

                    a.btn {
                        width: 100%;
                        color: #121212;
                    }
                }
            }
            .open-find-in-store-div.ecom {
                margin-top: 10px;
            }
            a.btn {
                width: 100%;

                .-stroke svg {
                    fill: $black;
                }

                &:hover, &:focus, &:visited {
                    .-stroke svg {
                        fill: $white;
                    }
                }
            }

        }

        .product-bullets {
            margin-bottom: 2.8rem;

            ul.product-assets,
            ul {
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;

                > li {
                    margin-top: .4rem;
                    margin-bottom: 0;
                    padding-left: 1rem;
                    position: relative;
                    @extend .text-default;
                    color: $content-primary;
                    font-size: 1.6rem;

                    &::before {
                        content: "";
                        width: 3px;
                        height: 3px;
                        border-radius: 50%;
                        background: $primary;
                        position: absolute;
                        left: 0;
                        top: 1rem;
                    }

                    &.highlight {
                        color: $primary;
                    }

                    strong {
                        color: $primary;
                        font-weight: normal;
                    }
                }
            }

            ul {
                li {
                    text-transform: none;

                    &::before {
                        background: $primary;
                    }
                }
            }
        }
    }
}

.tissot-m2 {

    &-products {
        .swiper-pagination.swiper-pagination-bullets {
            display: none;
        }
    }
}

.products-slider {
    text-align: center;
    position: relative;
    padding: 4rem 1.2rem 4rem;
    margin: 0 auto;
    overflow: hidden;

    @include min-screen($screen__m) {
        padding: 8rem 5rem;
    }

    @include min-screen($screen__xxl) {
        padding: 8rem 18rem;
    }

    &__title {
        @extend .title-h2;
        margin-top: 0;
        margin-bottom: 3.2rem;
    }

    &__btn-more {
        @extend .btn;
        @extend .-primary;
        top: 30px;
    }

    &_swiper-scrollbar {
        width: 80% !important;
        max-width: 750px;
    }

    &__swiper-wrapper-container,
    &__swiper-container {
        @extend .swiper-container;
        padding: 0 0 23px;
        position: relative;

        .swiper-wrapper {
            margin-bottom: 0;
        }

        .swiper-scrollbar {
            bottom: 0;
        }

        &.col-2-xs {
            .product-thumbnail-strap {
                width: auto;
            }

            .swiper-slide .product-thumbnail-strap__img-container {
                background-color: $lighter-grey;
            }

            @media screen and (max-width: $screen__l) {
                .swiper-slide {
                    width: 320px;
                    opacity: .5;
                    transition: opacity $transition-05;

                    &-active {
                        opacity: 1;
                    }
                }
            }
        }

        @media screen and (min-width: $screen__m) {
            background-color: transparent;
            &.col-2-xs {
                .swiper-scrollbar {
                    background-color: $light-grey;
                }
            }
        }
    }

    .products-slider__swiper-container .swiper-scrollbar {
        bottom: 0;
    }

    .product-thumbnail-strap {
        width: 100%;
        height: 100%;
    }

    .product-thumbnail {
        height: 100%;
    }

    &.-perso {
        padding: 4.8rem 1.2rem 4rem;

        @include min-screen($screen__m) {
            padding: 4.8rem 5rem 6rem;
        }

        @include min-screen($screen__xl) {
            padding: 4.8rem 5rem 6rem;
        }

        @include min-screen($screen__xxl) {
            padding: 5.6rem 18rem 8rem;
        }

        .products-slider__swiper-container {
            background: transparent;
            padding-bottom: 0;
        }

        .swiper-slide {
            display: flex;
            gap: 1.2rem;
            flex-direction: column;

            @include min-screen($screen__l) {
                flex-direction: row;
            }
        }

        .product-thumbnail {
            background: white;
        }

        .product-thumbnail-strap {
            padding: 0 2.4rem 0 0;
            background: white;
            display: flex;
            gap: 1.2rem;
            width: 100%;
            height: auto;
            flex-direction: row;
            align-items: center;

            @include min-screen($screen__l) {
                flex-grow: 1;
                height: 100%;
            }

            .product-thumbnail-strap__img-container {
                width: 30%;
                min-width: 30%;
                margin: 0;
                overflow: hidden;

                img {
                    width: 100%;
                    transform: scale(1);
                }

                @include min-screen($screen__xl) {
                    width: 25%;
                    min-width: 25%;
                }
            }

            .product-thumbnail-strap__description {
                padding: 2.4rem 0;
                margin: 0;

                * {
                    text-align: start;
                }

                .product-thumbnail-strap__out-of-stock {
                    min-height: 0;
                }

                .product-thumbnail-strap__title {
                    font-size: 1.8rem;
                    margin-top: 0;
                    margin-bottom: .4rem;

                    @include min-screen($screen__m) {
                        font-size: 1.9rem;
                        line-height: 2.3rem;
                    }
                }

                .badge-technical {
                    font-size: 1.6rem;
                    margin-bottom: .8rem;

                    @include min-screen($screen__m) {
                        font-size: 1.5rem;
                        line-height: 130%;
                    }
                }

                .product-thumbnail-strap__price {
                    font-size: 1.1rem;
                    line-height: 1.6rem;

                    @include min-screen($screen__m) {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }
                }
            }

            &:hover, &:focus {

                .product-thumbnail-strap__img-container img {
                    transform: scale(1.2);
                }
            }
        }

        .swiper-scrollbar {
            margin-top: 3rem;
            border-top: 10px solid #F8F7F7;
            border-bottom: 10px solid #F8F7F7;
        }
    }

    .swiper-scrollbar {
        margin-top: 3rem;
        position: relative;
    }

    .products-slider__swiper-container {
        background: transparent;
        padding-bottom: 0;
    }

    .products-push {
        &.swiper-button-prev {
            left: 0;
            top: calc(100% + 1rem);
            opacity: 1;

            &.swiper-button-disabled {
                opacity: 0.2;
            }
        }

        &.swiper-button-next {
            right: 0;
            top: calc(100% + 1rem);
            opacity: 1;

            &.swiper-button-disabled {
                opacity: 0.2;
            }
        }
    }
}

.product-group {
    padding: 1.4rem 0 0;
    margin-bottom: 3.2rem;
    margin-left: -0.6rem;
    background: transparent;
    overflow: hidden;
    border-top: 1px solid var(--Border-Secondary, #C7C7C7);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    ul {
        margin: 0;
        padding: 0.6rem;
        list-style: none;
        display: grid;
        gap: 1.2rem;
        overflow: hidden;
        max-height: 9rem;
        transition: all 0.4s ease-in-out;
        grid-template-columns: repeat(4, 6rem);

        &.-active {
            max-height: 53rem;
        }

        @include min-screen($screen__m) {
            grid-template-columns: repeat(9, 6rem);
        }

        @include min-screen(1024px) {
            grid-template-columns: repeat(4, 6rem);
        }

        @include min-screen(1280px) {
            grid-template-columns: repeat(5, 6rem);
        }

        @include min-screen(1400px) {
            grid-template-columns: repeat(5, 6rem);
        }

        @include min-screen(1680px) {
            grid-template-columns: repeat(5, 6rem);
        }

        @include min-screen(1800px) {
            grid-template-columns: repeat(6, 6rem);
        }

        @include min-screen(2000px) {
            grid-template-columns: repeat(7, 6rem);
        }

        li {
            margin-bottom: 0;
        }
    }

    &-more {
        display: none;
        position: relative;
        font-family: $font-family-condensed;
        font-size: 1.3rem;
        font-style: normal;
        margin-left: 2rem;
        margin-right: auto;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        border-radius: 100%;
        padding: 0;
        min-width: 4.4rem;
        line-height: 4.2rem;
        margin-top: 1.6rem;
        transition: all 0.2s ease-in-out;
        color: $content-primary;
        transform: scale(1);
        border: 1px solid var(--Border-Tertiary, #EAE8E7);
        background: var(--Surface-Section-Primary, #FFF) !important;

        &:hover, &:focus {
            border: 1px solid black;
        }

        &:before {
            content: '+';
            display: inline-block;
            margin-right: .4rem;
            transform: scale(1);
            transition: all 0.2s ease-in-out;
        }

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0);
            content: '';
            display: block;
            width: 1.4rem;
            height: .2rem;
            background: black;
            transition: all 0.2s ease-in-out;
        }

        &.-active {
            transform: scale(0.7);

            &:before {
                transform: scale(0);
            }
            &:after {
                transform: translate(-50%, -50%) scale(1);
            }
            span {
                transform: scale(0);
            }
        }

        span {
            display: inline-block;
            transform: scale(1);
            transition: all 0.2s ease-in-out;
        }
    }

    &-decli {
        height: 7.5rem;
        width: 6rem !important;
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            height: .2rem;
            width: 100%;
            background: transparent;
            transition: all 0.2s ease-in-out;
        }

        a, &.-active  {
            height: 100%;
            display: block;
            border: 1px solid $border-color;
            transition: border-color $transition-slow;
            overflow: hidden;
            background: white;

            &:hover {
                border-color: $content-primary;
            }
        }

        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            display: block;
            margin: auto;
            transform: scale(1.15);
        }

        &.-active {
            &:after {
                background: $content-primary;
            }
        }
    }
}

.product-video {
    position: relative;
    width: 100%;
    max-width: 90vw;
    margin-top: 15px;
    padding-top: 56.25%;

    @include min-screen($screen__m) {
        margin-top: 55px;
    }

    iframe {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.product-specs {
    text-align: center;
    max-width: 160rem;
    margin: 0 auto;
    padding: 4.8rem 0 0;

    @include min-screen($screen__m) {
        margin: 0 auto;
        padding: 8rem 0 4.8rem;
    }

    &__title {
        @extend .title-h2;
        text-align: center;
        margin-top: 0;
        margin-bottom: 3.2rem;
    }
}

.specs-usermanual {
    text-align: center;
    width: 100%;
    padding: 3.2rem 6rem 0;
    margin: 0 auto 6.4rem;

    @include min-screen($screen__m) {
        padding: 3.2rem 0 0;
    }

    .usermanual-content {
        margin-bottom: 2rem;

        @include min-screen($screen__xl) {
            width: 66.667%;
            margin: 0 auto 2rem;
        }

        p {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.2rem;
            margin-bottom: 0;
        }
    }

    .usermanual-title {
        margin-top: 0;
        @extend .title-h2;
        margin-bottom: .8rem;
    }

    .usermanual-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        align-items: center;
        justify-content: center;

        @include min-screen($screen__m) {
            flex-direction: row;
        }
    }

    .custom-select-2 {
        display: flex;
        text-transform: none;
        font-size: 1em;
        font-weight: normal;
        padding: 1.2rem 1.6rem;
        justify-content: space-between;
        border: 1px solid var(--Border-Tertiary, #EAE8E7);
        background: var(--Surface-Section-Primary, #FFF);
        width: 100%;
        max-width: 28rem;

        @include min-screen($screen__m) {
            max-width: 23rem;
        }

        .arrow {
            right: 1.6rem;
            top: 1rem;
        }

        label {
            display: none;
        }

        select {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.6rem;
            border: none;
            text-transform: none;
        }
    }

    .btn-container {
        width: 100%;
        max-width: 28rem;

        @include min-screen($screen__m) {
            max-width: 23rem;
        }
        .btn {
            width: 100%;
        }
    }

    .legal-notice {
        margin-top: 2rem;
        color: $content-tertiary;
        text-align: center;
        font-size: 1.3rem;
        font-style: normal;
        line-height: 2rem;
    }
}

.tabs {

    @include min-screen($screen__m) {
        margin: 0 1.2rem 0;
    }

    @include min-screen($screen__l) {
        width: 83.333%;
        margin: 0 auto 0;
    }
}

.tabs-btns {
    display: none;

    @include min-screen($screen__m) {
        border-bottom: 1px solid $border-color;
        display: flex;
        justify-content: space-around;
    }

    button, .product-tab-link {
        font-family: $font-family;
        background: transparent;
        border: 0;
        padding: 1rem;
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: 1px;
        color: $content-primary;
        transition: color $transition-fast;
        display: block;
        position: relative;
        margin-bottom: -1px;
        border-radius: 0;

        &::after {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            width: 0;
            left: 50%;
            height: 2px;
            background-color: $primary;
            transition: all $transition-fast;
            transform: translateX(-50%);
        }

        &[aria-selected="true"], &:hover, &:focus {
            color: $primary;

            &::after {
                width: 60%;
            }
        }
    }
}

.tabs-container {
    text-align: left;
    transition: all $transition-slow;
    border-bottom: 1px solid $border-color;

    @include min-screen($screen__m) {
        width: 80%;
        padding: 2.4rem 0 0;
        margin: 0 auto;
        border-bottom: 0;
    }

    ul {
        list-style: none;
        padding: 0 1.2rem 2.4rem;
        margin: 0;
        display: flex;
        gap: 1.6rem 1.2rem;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;

        @include min-screen($screen__m) {
            gap: 2.4rem;
            padding: 0;
        }

        li {
            margin: 0;
            flex-grow: 1;
            width: 100%;
            max-width: calc(50% - 0.6rem);

            &:first-child:last-child {
                max-width: none;

                h4 {
                    display: none;
                }
            }

            @include min-screen($screen__m) {
                max-width: calc(33.33% - 1.6rem);
            }
        }
    }

    h4 {
        font-family: $font-family;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $content-tertiary;
        margin: 0 0 10px;
    }

    p {
        color: $content-primary;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.2rem;
        margin: 0;
    }

    div[role="tabpanel"],
    .tab__content {
        max-height: 0;
        overflow: hidden;
        transition: all $transition-slow;

        @include min-screen($screen__m) {
            max-height: none;
            transition: all 1s ease-in;
        }

        &[hidden] {
            display: block;

            @include min-screen($screen__m) {
                display: none;
            }
        }


        & > .product-specs-description {
            margin: 0;
            padding: 0 1.2rem 2.4rem;

            @include min-screen($screen__m) {
                padding: 0;
            }
        }
    }

    button[role="tab"],
    .tab__btn--mob {
        display: block;
        width: 100%;
        padding: 2.4rem 1.2rem;
        font-weight: 400;
        text-align: left;
        font-size: 2rem;
        line-height: 2.4rem;
        color: $content-primary;
        background: transparent;
        border: 0;
        border-radius: 0;
        border-top: 1px solid $border-color;
        position: relative;
        @extend .icon-plus;

        &::after {
            font-family: "icomoon";
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            color: $content-primary;
            font-size: 1.5em;
            transition: all $transition-fast;
        }

        @include min-screen($screen__m) {
            display: none;
        }
    }

    button[aria-selected="true"],
    .tab__btn--mob[aria-selected="true"] {
        &:after {
            content: "\e909";
        }

        & + div[role="tabpanel"],
        .tab__content {
            max-height: 1000px;
        }
    }

    @include max-screen($screen__m) {
        .tab__btn--mob[aria-selected="true"] + div[role="tabpanel"] {
            max-height: 0;
        }

        .tab__btn--mob.is-open--mob + .tab__content,
        .tab__btn--mob.is-open--mob + div[role="tabpanel"] {
            max-height: 1000px;
        }
    }
}

.product-mosaic-modal__img-container img {
    display: block;
    margin: auto auto 10px auto;
}

.modal-product-image.modal-popup._inner-scroll .modal-inner-wrap {
    max-height: 100%;
    margin: 0;
    width: 100%;
    height: 100%;

    .modal-header {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;

        @include min-screen($screen__m) {
            display: none;
        }
    }

    .modal-content {
        overflow: auto;
        margin: auto;
        width: 100%;
    }

    #product-modal-mosaic {
        display: flex;
        flex-direction: column;
        align-items: center;

        .product-mosaic-modal__img-container {
            cursor: url("../images/logo/x-circle.png"),
            url("../images/logo/x-circle.png") 5 5, progress;

            img {
                max-width: fit-content;
            }
        }
    }
}

.product-retailers-pop button.close {
    background: none;
    border: none;
    font-size: 32px;
    line-height: 32px;
    font-weight: lighter;
    color: #8f8f8f;
}

.catalog-product-view {
    .page {
        padding-top: 60px;

        &.messages {
            padding-top: 0;
        }
    }

    @include max-screen($screen__m) {
        #fb-root {
            .fb_invisible_flow iframe {
                margin-bottom: 15px;
            }

            .fb_dialog_content > iframe {
                bottom: 45px;
            }
        }
    }
}

#reserve-in-store .reserve-wrap {
    @include max-screen($screen__m) {
        margin: 0;
        width: 100%;
        fieldset {
            width: 100%;


            .field.qty {
                width: 20%;
                padding: 0;
            }

            .actions {
                min-width: 400px;
                padding-top: 0;
            }
        }
    }
}

.product-options-bottom {
    .box-tocart {
        width: 100%;

        fieldset {
            width: 100%;

            .field.qty {
                width: 20%;
                padding: 0;
            }

            .actions {
                min-width: 400px;
                padding-top: 0;
            }
        }
    }
}

.product-out-of-stock-price {
    display: inline-block;
    color: #616161;
    margin-bottom: 0;
    @extend .text-default;
}

.price-box {
    display: inline-block;
}

.product-retailers-pop {
    @include min-screen($screen__l) {
        width: calc(50% - 5px);
    }

    position: relative;
    background-color: #FFFFFF;
    border: 1px solid #7777;
    z-index: 2;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px;

    &__close {
        text-align: right;
        height: fit-content;

        button {
            padding: 0 15px 0 15px;
        }
    }

    .product-retailers-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .retailer-container {
            @include min-screen($screen__m) {
                width: calc(50% - 30px);
            }

            @include max-screen($screen__m) {
                width: 65%;
            }

            text-align: center;

            .retailer-media {
                width: 75%;
                display: inline-block;
            }

            .retailer-link {
                width: min-content;
            }
        }
    }
}

.products-slider__btn-more-div {
    padding-top: 15px;
}

#product_addtocart_form .last {
    margin-bottom: 0;
}

.strap-button {
    text-align: center;
}

/* Popin 3BTN? */

.popin-3btn {
    margin: 20px;
    text-align: center;
}

/* Information block Korea */
.information-block {

    margin: auto;
    max-width: 860px;

    hr {
        border-top: 1px solid #000;
        margin: 0;
    }

    .fieldset {
        width: 100%;
        border-left: none;
        border-right: none;
        margin: 0;

        &.active {
            .block-title {
                .chevron {
                    transform: rotate(-45deg);
                }
            }
        }

        .block-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                @extend .title-h4;
                text-align: center;
                font-weight: 700;
                margin-left: 10px;
                margin-right: 30px;
            }

            .chevron {
                border-style: solid;
                border-width: 0.2em 0.2em 0 0;
                content: '';
                height: 1em;
                right: 15px;
                position: relative;
                top: -0.15em;
                transform: rotate(135deg);
                vertical-align: top;
                width: 1em;
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            margin: 0 10px 30px 10px;
        }
    }
}

.catalog-product-view {
    .tissot__header_bottom {
        position: fixed;
        top: $header-total-height;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 9;
        background: $white;
        padding: 25px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
        height: 50px;
        border-bottom: 1px solid $border-color;

        @include max-screen($screen__xl) {
            top: $header-total-height-mobile;
            border-top: 1px solid $border-color;
        }

        .information {
            flex: 1;
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            max-height: 50px;

            .product-name {
                @extend .title-h4;
                padding: 0;
                margin: 0;

                @include max-screen($screen__s) {
                    display: none;
                }
            }

            .price-box {
                display: flex;
                align-items: center;
                max-height: 50px;

                .price-container {
                    .price {
                        @extend .title-h4;
                    }
                }
            }
        }

        .actions {
            margin-left: 15px;

            button.action.tocart {
                .icon {
                    z-index: 2;
                    position: relative;
                    display: none;
                }

                @include max-screen($screen__m) {
                    .icon {
                        display: block;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}

.product-sticky-price {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch;
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
    opacity: 0;
    pointer-events: none;
    transition: all .2s ease-in-out;
    transform: translateY(100%);

    &.-active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
    }

    @include min-screen($screen__m) {
        gap: 3.2rem;
        align-items: center;
        padding: 1.2rem 2rem 1.2rem 1.2rem;
    }

    @include min-screen($screen__l) {
        padding: 1.2rem 2rem 1.2rem 2.4rem;
        top: 6.6rem;
        bottom: auto;
        transform: translateY(-100%);
    }

    @include min-screen($screen__xl) {
        top: 8.2rem;
        padding: 1.2rem 2rem 1.2rem 7rem;
    }

    &__wrapper {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        padding: 1.2rem;

        @include min-screen($screen__m) {
            padding: 0;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }

    &__title {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 1.8rem;
        text-transform: capitalize;

        @include min-screen($screen__m) {
            font-size: 2.4rem;
        }
    }

    &__price {
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 1px;
        text-transform: capitalize;
        font-family: $font-family-condensed;

        @include min-screen($screen__m) {
            font-size: 1.8rem;
        }

        .price {
            font-size: 1.5rem;

            @include min-screen($screen__m) {
                font-size: 1.8rem;
            }
        }
    }

    &__link {
        .btn {
            height: 100%;
        }

        span {
            display: none;

            @include min-screen($screen__m) {
                display: block;
            }
        }
    }
}

.warranty-3-years {
    min-height: 228px;
    padding: 30px 10px;
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background: radial-gradient(
                1601.75% 111.22% at 64.6% -6.65%,
                rgba(164, 164, 164, 0) 28.58%,
                rgba(174, 174, 174, 0.69) 73.82%,
                rgba(243, 243, 243, 0.52) 98.64%
        );

        @include min-screen($screen__m) {
            background: radial-gradient(
                    100% 3346.72% at 0% 40.13%,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.42) 58.85%,
                    rgba(156, 154, 154, 0.554803) 79.13%,
                    rgba(253, 253, 253, 0.72) 98.64%
            );
        }
    }

    &__title {
        text-transform: uppercase;
        color: $primary;
        font-size: 35px;
        z-index: 1;
    }

    &__cosc {
        color: $white;
        letter-spacing: 1px;
        z-index: 1;
    }

    &__img {
        z-index: 1;

        @include min-screen($screen__m) {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }
}

#product-img-loader {
    max-width: 110px;
}

.section-services {
    > p {
        color: $primary !important;
        font-weight: bold !important;
    }

    ul {
        &.list {
            list-style: none;
            margin-left: 0px;
            padding-left: 0px;

            li {
                &.mido-modal {
                    &:hover {
                        cursor: pointer;
                    }

                    &::before {
                        content: initial;
                    }

                    padding-left: 0;

                    svg {
                        min-width: 5rem;
                        width: 5rem;
                        height: 5rem;
                    }

                    span {
                        vertical-align: super;
                    }

                }

            }
        }

    }

}

.mido-modal {
    &:hover {
        cursor: pointer;
    }
}

// Zindex conflict between Facebook tchat and Photoswipe modal
.pswp-opened #fb-root {
    opacity: 0;
    visibility: hidden;
}

.product-size__modal {
    padding: 40px;
    text-align: center;

    h1 {
        @extend .title-h1;
    }

    img {
        width: 25%;
    }
}

.product-gift-case {
    border-top: 1px solid var(--Border-Secondary, #C7C7C7);
    padding: 2rem 1.6rem;
    display: flex;
    gap: 2rem;
    align-items: center;

    &-visual {
        width: 6.8rem;
        min-width: 6.8rem;
    }

    &-text {
        strong {
            font-family: $font-family;
            display: block;
            margin-bottom: .4rem;
            font-size: 1.1rem;
            font-weight: 500;
            line-height: 1.8rem;
            letter-spacing: .1rem;
            text-transform: uppercase;
        }

        p {
            margin-bottom: 0;
            @extend .text-default;
            color: $content-secondary;
        }
    }
}
