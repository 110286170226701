.product-thumbnail,
.product-thumbnail-strap,
.wide_contentsystem-content-page .wide_contentsystem- .product-thumbnail {
    display: flex;
    flex-direction: column;
    position: relative;
    width: auto;
    background: #F8F7F7;
    padding: 3.4rem 1.2rem 2.4rem;
    border-bottom: 3px solid transparent;
    transition: all 0.4s ease-in-out;

    @include min-screen($screen__l) {
        padding: 4.4rem 4.8rem 4.8rem;
    }

    &__img-container {
        position: relative;
        text-align: center;
        margin-bottom: .8rem;

        @include min-screen($screen__l) {
            margin-bottom: 0;
        }

        img {
            display: block;
            margin: auto;
            transform: scale(1);
            width: auto;
            max-height: 30rem;
            transition: transform .4s ease-in-out;

            @include min-screen($screen__l) {
                transform: scale(0.90);
            }
        }
    }

    &__front .product-thumbnail__img-container {
        background: transparent;

        img {
            background: $white;
        }
    }

    &__description {
        position: relative;
    }

    &__out-of-stock {
        min-height: 1.6rem;
        color: $content-tertiary;
        text-align: center;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.4rem;
        letter-spacing: 0.02rem;
        margin-bottom: .4rem;

        @include min-screen($screen__l) {
            font-size: 1.4rem;
            line-height: 1.8rem;
            margin-bottom: .8rem;
        }
    }

    &__title {
        text-align: center;
        color: $content-primary;
        font-size: 1.6rem;
        line-height: 2rem;
        font-style: normal;
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: .6rem;
        margin-top: 0;

        @include min-screen($screen__m) {
            font-size: 2.2rem;
            line-height: 2.8rem;
        }
    }

    &__price {
        text-align: center;
        color: $content-primary;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.6rem;
        letter-spacing: .1rem;
        text-transform: capitalize;
        transition: all 0.4s ease-in-out;
        font-family: $font-family-condensed;
        white-space: nowrap;

        @include min-screen($screen__m) {
            font-size: 1.6rem;
            line-height: 2rem;
        }

        .product-original-price {
            display: inline-block;
            color: $content-secondary;
        }
    }

    &__cta {
        @extend .btn;
        @extend .-primary;

        margin-top: 5px;
        width: 100%;
    }

    &__link, &__link_noecom {
        color: $primary;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.6rem;
        letter-spacing: .1rem;
        text-transform: uppercase;
        text-decoration: underline;
        text-underline-offset: 4px;
        transition: all 0.4s ease-in-out;
        transform: translateY(0);
    }

    &__link_noecom {
        transition: none;
        transform: none;
    }

    &__anim {
        overflow: hidden;
        height: 1.6rem;
        margin-top: 1.2rem;

        @include min-screen($screen__m) {
            height: 2rem;
        }
    }

    &:hover,
    &:focus {
        border-bottom: 3px solid $primary;

        .product-thumbnail-strap__price,
        .product-thumbnail__price {
            transform: translateY(-1.6rem);

            @include min-screen($screen__m) {
                transform: translateY(-2rem);
            }
        }
        .product-thumbnail-strap__link,
        .product-thumbnail__link {
            transform: translateY(-1.6rem);

            @include min-screen($screen__m) {
                transform: translateY(-2rem);
            }
        }

        .product-thumbnail__img--front {
            opacity: 1;

            @include min-screen($screen__l) {
                transform: scale(1);
            }
        }
    }
}

.product-thumbnail__technical-badges,
.product-thumbnail__comming-soon{
    text-align: center;
}

.product-thumbnail__mentions {
    position: absolute;
    top: 1.6rem;
    left: 1.2rem;
    right: 1.2rem;
    color: $primary;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.1rem; /* 122.222% */
    letter-spacing: .1rem;
    text-transform: uppercase;
    display: block;
    font-family: $font-family;
    text-align: center;

    @include min-screen($screen__l) {
        text-align: left;
        top: 3.2rem;
        left: 4.8rem;
        right: 4.8rem;
        font-size: 1.2rem;
    }
}

.badge-technical {
    display: block;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    letter-spacing: .02rem;
    color: $content-secondary;

    @include min-screen($screen__l) {
        font-size: 1.7rem;
    }
}

.badge-technical:after {
    content: "-";
}

.badge-technical:last-child:after {
    content: "";
}
