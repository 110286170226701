@font-face {
    font-family: 'icomoon';
    src: url('../images/fonts/icomoon.eot');
    src: url('../images/fonts/icomoon.eot') format('embedded-opentype'),
    url('../images/fonts/icomoon.ttf') format('truetype'),
    url('../images/fonts/icomoon.woff') format('woff'),
    url('../images/fonts/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
    content: "\e900";
}

.icon-chevron-down:before {
    content: "\e901" !important;
}

.icon-chevron-left:before {
    content: "\e902";
}

.icon-chevron-right:before {
    content: "\e903";
}

.icon-download:before {
    content: "\e905";
}

.icon-map-pin:before {
    content: "\e904";
}

.icon-plus:after { //after !!!
    content: "\e907";
}

.icon-share:before {
    content: "\e908";
}

.icon-x:before {
    content: "\e906";
}

.icon-min:before {
  content: "\e909";
}

.icon-pin {
  position: relative;
}

.icon-pin:after {
  content: "\e90a";
  color: $primary;
  position: absolute;
  top: 0;
  left: 0;
}

.icon-pin:before {
  content: "\e909";
  color: #262729;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.25;
}

.icon-crosshair:before {
  content: "\e910";
}
