::-moz-focus-inner {
    border: 0;
}

textarea {
    border-radius: 0;
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="date"],
input[type="password"],
input[type="tel"],
select {
    border: 1px solid $border-color;
    height: 56px;
    width: 100%;
    padding: 0 10px 0 15px;
    font-family: $font-family;
    font-size: 1.6rem;
    font-weight: 300;
    color: $dark-grey;
    background: $white;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    filter: none;
    border-radius: 0;

    &::placeholder {
        color: #757575;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 1000px $white inset;
        -webkit-text-fill-color: $dark-grey;
    }
}

input[type="number"] {
    -moz-appearance: textfield;
}

%focused-label {
    top: -2px;
    font-size: 1rem;
    text-transform: uppercase;
    color: $black;
    opacity: 1;
    visibility: visible;
}

.form-group-destop {
    position: relative;

    @include min-screen($screen__m) {
        width: calc(50% - 30px);
        margin: 60px 15px 0;
    }
    @include max-screen($screen__m) {
        margin: 0;
    }
}
.form-group {
    width: 100%;
    position: relative;
    margin: 30px 0 0;

    @include min-screen($screen__m) {
        width: calc(50% - 30px);
        margin: 60px 15px 0;

        &.fieldset-jp-postcode {
            width: 90%;
        }
    }

    .control-label {
        position: absolute;
        top: 28px;
        left: 15px;
        background: $white;
        color: $light-grey;
        font-size: 1.6rem;
        transform: translateY(-50%);
        padding: 0 5px;
        font-weight: 300;
        text-transform: uppercase !important;
        z-index: 2;
        transition: all $transition-fast;

        em {
            @extend .required-char
        }
    }

    &._disabled,
    &.is-focus,
    &.has-value {
        .control-label {
            @extend %focused-label;
        }
    }

    // Label must be placed after input (type password, text, email)
    input {
        &[type="text"], &[type="email"], &[type="password"], &[type="number"] {

            /* If input is not empty */
            &:not(:placeholder-shown) {
                /* You need to add a placeholder to your fields. For example: <input "placeholder=" "/> */
                & ~ label {
                    @extend %focused-label;
                }
            }

            /* If input is empty */
            &:placeholder-shown {
                & ~ label {
                    pointer-events: none;
                }

                &:focus ~ label {
                    @extend %focused-label;
                }
            }
        }
    }

    // Label must be placed after select
    select:valid ~ label { // Select is not empty
        @extend %focused-label;
    }

    &.field-mask {
        input {
            letter-spacing: 2px;
        }
    }

    small {
        font-size: 1rem;
        text-transform: uppercase;
        display: block;
        margin-bottom: 30px;
    }

    .btn {
        width: 100%;

        @include min-screen($screen__m) {
            width: auto;
        }
    }

    button.show-password {
        display: none;
    }

    &.password,
    &.confirmation {
        > div {
            position: relative;
        }

        .input-password,
        .input-text.password { // Hide / Show password
            padding-right: 5rem;
            z-index: 2;

            & ~ button.show-password {
                align-items: center;
                background: transparent;
                border: none;
                box-shadow: none;
                cursor: pointer;
                display: flex;
                flex-flow: row wrap;
                height: 56px;
                justify-content: center;
                padding: 1rem;
                position: absolute;
                right: 0;
                top: 0;
                transition: opacity $transition-02, visibility $transition-02;
                width: 5rem;
                z-index: 2;

                span {
                    display: flex;
                    height: 100%;
                    width: 75%;
                }

                svg {
                    transition: fill $transition-02;
                    width: 100%;
                }

                .-hide {
                    display: none;
                }

                &:not(:focus):focus-visible {
                    outline: 2px solid $primary;
                    outline-offset: 4px;

                    & ~ label {
                        @extend %focused-label;
                    }
                }

                &:hover svg {
                    fill: $red;
                }
            }

            &[type='text'] ~ button.show-password {
                .-show {
                    display: none;
                }

                .-hide {
                    display: block;
                }
            }

            /* If input is not empty */
            &:not(:placeholder-shown) {
                & ~ button.show-password {
                    opacity: 1;
                    visibility: visible;
                }
            }

            /* If input is empty */
            &:placeholder-shown {
                &:not(:focus) ~ button.show-password {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
}

.custom-select-2,
.select-styled {
    position: relative;

    .arrow {
        font-size: 19px;
        content: "\e901";
        font-family: 'icomoon';
        speak: none;
        position: absolute;
        right: 0;
        top: 5px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .field-tooltip {
        font-size: 19px;
        speak: none;
        position: absolute;
        right: -25px;
        top: 5px;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
    }


    select {
        font-family: $font-family;
        border: 0;
        border-bottom: 1px solid $border-color;
        text-transform: uppercase;
        font-size: 1.6rem;
        font-weight: 300;
        height: auto;
        padding: 0 20px 0 0;
        color: $dark-grey;
        border-radius: 0;
        background: transparent;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        position: relative;
        z-index: 2;
    }

    .validation-advice {
        top: 35px;
        left: 0;
    }

    .sorter-options {
        -webkit-appearance: none !important;
        appearance: none !important;
    }
}

.select-group {
    position: relative;

    @extend .icon-chevron-down;

    &:before {
        font-family: "icomoon";
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 1;
    }

    select {
        text-transform: none;
        height: 56px;
        border-radius: 0;
        background: transparent;
        width: 100%;
        position: relative;
        z-index: 2;
        padding-right: 4rem;
    }

    .control-label {
        z-index: 3;
    }
}

.checkbox-group {

    label {
        font-size: 1.4rem;
        position: relative;
        padding-left: 25px;
        cursor: pointer;

        .icon-checkbox {
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            width: 1.6rem;
            height: 1.6rem;
            border-radius: 2px;
            background-color: $white;
            border: 1px solid $border-color;
            transition: all $transition-fast;

            @extend .icon-check;

            &:before {
                font-family: "icomoon";
                position: absolute;
                top: 0;
                left: 0;
                color: $white;
            }
        }

        &.disabled {
            color: $light-grey;
        }

        input[type="checkbox"] {
            width: 10px;
            height: 10px;
            overflow: hidden;
            display: block;
            position: absolute;
            top: 5px;
            left: 3px;
            margin: 0;
            opacity: 0;

            &:checked + .icon-checkbox {
                border-color: $primary;
                background-color: $primary;
            }

            &:checked:disabled + .icon-checkbox {
                border-color: $light-grey;
                background-color: $light-grey;
            }


            &:focus-visible {
                outline-offset: 4px;

                & + .icon-checkbox {
                    outline: 2px solid $primary;
                    outline-offset: 4px;
                    border-radius: 0;
                }
            }
        }

        em {
            @extend .required-char
        }
    }
}

.radio-group {
    position: relative;

    .icon-radio:before {
        content: "";
        display: block;
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        top: 2px;
        left: 0;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 50%;

    }

    .icon-radio:after {
        content: "";
        display: none;
        position: absolute;
        width: 0.8rem;
        height: 0.8rem;
        top: 4px;
        left: 2px;
        background-color: $primary;
        border: 3px solid $white;
        border-radius: 50%;
    }


    input {
        opacity: 0;

        &:checked + .icon-radio:after {
            display: block;
        }

        &:checked + .icon-radio:before {
            border-color: $primary;
        }
    }
}

.radio-group-custom-form {
    position: relative;

    .radio-box {
        padding: 20px 0px 0px 25px;
    }

    .label-custom-form {
        display: flex;
        margin-left: -18px;

        span {
            margin: -2px 0 4px 5px;
            font-size: 1em;
            font-weight: 300;
            padding-bottom: 4px;
        }
    }

    .icon-radio:before {
        content: "";
        display: block;
        position: relative;
        width: 1.6rem;
        height: 1.6rem;
        top: 2px;
        left: 0;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 50%;

    }

    .icon-radio:after {
        content: "";
        display: none;
        position: relative;
        width: 0.8rem;
        height: 0.8rem;
        top: -14px;
        left: 2px;
        background-color: $primary;
        border: 3px solid $white;
        border-radius: 50%;
    }


    input {
        opacity: 0;

        &:checked + .icon-radio:after {
            display: block;
        }

        &:checked + .icon-radio:before {
            border-color: $primary;
        }
    }
}


.textarea-group,
.captcha-group,
.checkbox-group {
    .validation-advice {
        position: static;
        padding-left: 20px;
    }
}

// placeholder
::-webkit-input-placeholder,
:-moz-placeholder,
:-ms-input-placeholder,
::-moz-placeholder {
    text-transform: uppercase;
    color: $dark-grey;
    font-size: 1.6rem;
    font-weight: 300;
}

.fieldset__input-maks {
    input {
        letter-spacing: 2px;
    }
}

// error messages
.form-group textarea + .mage-error,
.form-group input + .mage-error,
.form-group select + .mage-error,
.field input + .mage-error,
.field select + .mage-error,
.validation-advice {
    text-transform: uppercase;
    color: $primary !important;
    font-size: 1rem;
    position: relative;
    margin-top: 5px;
}

.validation-error .control-label {
    color: $primary !important;
}

input.mage-error,
select.mage-error,
textarea.mage-error {
    border-color: $primary !important;
}

.form.send.confirmation {
    text-align: center;
    margin: 0 auto;
    padding-top: 20px;

    .actions-toolbar {
        .primary button {
            @include make-btn-primary;
        }

        .secondary button {
            @include make-btn-secondary;
        }
    }
}

.fieldset > .checkout-agreement.choice {
    &:before {
        width: 0;
        padding: 0;
    }
}

.opc-wrapper .fieldset > .checkout-agreement.required > label {
    &:after {
        top: -6px;
        left: 3px;
    }
}

.notice-info {
    font-size: 1.4rem;
    font-style: italic;
}

#dhl-shipping-form .form-group {
    margin: 0;
}

//PHONE NUMBER COUNTRY SELECT
.phone_number__container {
    display: flex;
    gap: 15px;

    &::before, &::after {
        display: none !important;
        content: none !important;
    }

    .icon.flag {
        border-radius: 50%;
    }

    .phone_prefix {
        width: 30% !important;

        .control-label {
            left: 2px;
        }

        .ts-wrapper.custom-select {
            .ts-control {
                height: 56px;
                border-radius: 0;
                align-items: center;

                &:after {
                    font-family: $font-icons;
                    content: "\e901" !important;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(-10px, -50%);
                    pointer-events: none;
                    color: $black;
                }

                .item {
                    .icon.flag {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translate(15px, -50%);

                        use {
                            display: block;
                        }
                    }

                    * {
                        display: none;
                    }
                }
            }
        }

        .ts-dropdown {
            width: calc(30% - 5px);
        }
    }

    .phone_number {
        width: 70% !important;

        input {
            letter-spacing: 1.5pt;
        }

        .control-label {
            right: 2px;
            left: auto;
        }
    }

    .ts-dropdown {
        .ts-dropdown-content {
            .option {
                display: flex;
                align-items: center;
                gap: 15px;
                font-size: 1.6rem;
                text-transform: uppercase;
                padding: 10px 30px;
            }
        }
    }
}
button {
    font-family: $font-family;
}
