:root {
    --page-header-height: 6.6rem;

    @include min-screen($screen__xl) {
        --page-header-height: 8.2rem;
    }
}

* {
    -webkit-overflow-scrolling: touch;
}

.page-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: black;
    transition: all $transition-fast;
    border-bottom: 0;
    margin-bottom: 0;
    min-height: var(--page-header-height);
}

.link-avoidance {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 0;
    overflow: hidden;
    background: rgba(black, 0.8);
    transition: height $transition-fast;

    span {
        color: black;
        display: inline-block;
        padding: 0 5px;
        margin-left: 40px;
    }

    &:focus {
        height: 40px;
        outline: 0;

        span {
            outline: 2px solid $primary;
        }

        & + .logo-block {
            @include min-screen($screen__xl) {
                top: calc(25px + 40px);
            }
        }
    }
}


.cms-home {
    .page-header {
        background: transparent;
    }
    .page-main > .columns{
        max-width: 100%;
        overflow: hidden;
    }
}

.cms-home .page-header, .tissot_landing-page-prx .page-header,
.cms-home .page-header,
.tissot_landing-page-ttouch .page-header {
    .menus-container {
        border-bottom: 0;
    }

    .header.content > .mini-cart-link {
        color: white;
    }

    &.is-open-menu,
    &.is-open-search,
    &.is-header-black {
        background: black;

        .user-menu-block .store-switcher a::after {
            border-top-color: black;

            @include min-screen($screen__xl) {
                border-top-color: white;
            }
        }

        .menus-container {
            border-bottom: 1px solid $border-color;
        }

        .header.content > .mini-cart-link {
            color: white;
        }
    }

    &.is-open-menu,
    &.is-open-search {
        .menus-container {
            border-bottom: 1px solid $border-color;
        }

        .header.content > .mini-cart-link {
            color: white;
        }
    }

    @include max-screen($screen__xl) {
        &.is-open-menu--mob {
            .menus-container {
                border-bottom: 1px solid $border-color;
            }

            .header.content > .mini-cart-link {
                color: white;
            }
        }
    }
}

.menus-container {
    display: flex;
    flex-flow: column;
    background: white;
    position: fixed;
    top: var(--page-header-height);
    bottom: 0;
    width: 100vw;
    overflow: auto;
    padding-bottom: 30px;
    left: 0;
    opacity: 0;
    transition: opacity $transition-fast;
    pointer-events: none;
    -webkit-overflow-scrolling: touch;

    @include min-screen($screen__xl) {
        flex-flow: row;
        height: var(--page-header-height);
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid $border-color;
        background: transparent;

        top: auto !important;
        bottom: auto;
        left: auto;
        width: 100%;
        padding: 0 5rem;
        overflow: initial;
        opacity: 1;
        pointer-events: all;
    }

    @include min-screen($screen__xx) {
        padding: 0 7rem;
    }
}

.page-wrapper {
    padding-top: 0;
    transition: padding-top $transition-fast;
}

@include max-screen($screen__xl) {
    .is-open-menu--mob {
        .menus-container {
            opacity: 1;
            pointer-events: all;
        }

        .header.content {
            background: black;
            transition: background $transition-fast;
            height: var(--page-header-height);

            & > .mini-cart-link {
                color: white;
            }
        }

        .nav-toggle:before {
            content: "\e616";
            font-size: 44px;
            line-height: 46px;
            margin-left: -9px;
            color: white;
        }

        //fix for mobile menu callout
        .block-callout {
            display: none !important;
        }

        .menus-container {
            top: var(--page-header-height) !important;
        }
    }

    .is-open-search {
        .block-callout {
            display: none !important;
        }
    }
}

.header.content {
    padding: 0;
    background: transparent;
    height: var(--page-header-height);

    & > .mini-cart-link {
        position: absolute;
        padding: 2rem;
        color: white;
        top: 0;
        right: 0;

        svg {
            display: block;
            stroke: white;
            stroke-width: 0.5px;
            width: 26px;
            height: 26px;
        }
        & > .label {
            display: none;
        }
        @include min-screen($screen__xl) {
            display: none;
        }
    }
}

.logo-block {
    left: 50%;
    top: 50%;
    width: 14rem;
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    z-index: 20;
    height: var(--page-header-height);

    @include min-screen($screen__xx) {
        width: 18rem;
    }

    .logo {
        flex-grow: 1;
        background: var(--mido-logo);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 100%;
        margin: 0;
    }
}

.cms-home .page-header, .tissot_landing-page-prx .page-header,
.cms-home .page-header, .tissot_landing-page-ttouch .page-header {
    .logo--black {
        opacity: 0;
        flex-grow: 1;
        background: var(--mido-logo);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.cms-home .page-header,
.tissot_landing-page-ttouch .page-header {
    .nav-toggle:before {
        color: white;
    }

    &.is-open-menu,
    &.is-open-search,
    &.is-header-black {
        .nav-toggle:before {
            color: white;
        }
    }

    @include max-screen($screen__xl) {
        &.is-open-menu--mob {
            .nav-toggle:before {
                color: $white;
            }
        }
    }
}

.main-nav,
.user-menu-block {
    @include min-screen($screen__xl) {
        width: calc(50% - 6rem);
    }
    @include min-screen($screen__xx) {
        width: calc(50% - 11rem);
    }
}

.user-menu-block {
    z-index: 20;
    margin-top: 2rem;

    @include min-screen($screen__xl) {
        margin-top: 0;
        margin-right: -2rem;
    }

    ul {
        list-style: none;
        gap: 2.4rem;
        margin: 0 20px;
        padding: 3.2rem 0 0 0;
        display: flex;
        flex-flow: column;
        border-top: 1px solid $border-color;

        @include min-screen($screen__xl) {
            text-align: right;
            margin: 0;
            padding: 0;
            display: block;
            border: 0;
            gap: 0;
        }
    }

    li {
        display: block;
        margin-bottom: 0;

        @include min-screen($screen__xl) {
            margin: 0;
            vertical-align: top;
            display: inline-block;
        }

        .icon-flag--flag-international {
            transition: color $transition-fast;
            color: black;

            @include min-screen($screen__xl) {
                color: $white;
            }
        }
    }

    .product-search {
        @include max-screen($screen__xl) {
            display: none;
        }
    }

    a,
    button:not([type="submit"]):not(.btn) {
        color: black;
        transition: color $transition-fast;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.2rem;

        @include min-screen($screen__xl) {
            padding: 20px;
            position: relative;
            justify-content: center;
            gap: 0;
        }

        svg {
            transition: fill $transition-fast;
            display: block;
            fill: black;
            width: 2rem;
            height: 2rem;

            @include min-screen($screen__xl) {
                fill: $white;
            }
        }

        .label {
            color: black;
            white-space: nowrap;

            @include min-screen($screen__xl) {
                opacity: 0;
                color: $primary;
            }

            &,
            &::after {
                transition: all $transition-fast;
            }
        }

        &:hover, &:focus-visible {
            .label {
                opacity: 1;
                color: $primary;
            }

            .icon {
                color: $primary;
                fill: $primary;
            }
        }
    }

    .label {
        color: black;
        margin: 0;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 2.4rem;

        &:hover {
            color: $primary;
        }

        @include min-screen($screen__xl) {
            display: block;
            text-transform: uppercase;
            font-size: 0.5625em;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            margin-left: 0;
            line-height: 1em;
            opacity: 1;
            transition: opacity $transition-fast;
        }
    }

    .search-btn--toggle {
        border: 0;
        transform: translateY(1px);
        background: transparent;
        font-size: 1em;
        padding: 0;

        @include min-screen($screen__xl) {
            padding: 2rem;
        }

        svg {
            fill: black;
            transition: fill $transition-fast;

            @include min-screen($screen__xl) {
                fill: white;
            }
        }

        &:focus svg,
        &:hover svg {
            @include min-screen($screen__xl) {
                fill: $primary;
            }
        }
    }

    .search-form {
        background: white;
        position: absolute;
        top: 0;
        right: 100%;
        bottom: 0;
        left: 0;
        max-height: 0;
        overflow: hidden;
        transition: right $transition-fast;

        @include min-screen($screen__xl) {
            top: 100%;
            bottom: auto;
            right: 0;
            transition: max-height $transition-fast;
            border: 0;
            box-shadow: none;

            .search-form__form {
                position: relative;
                z-index: 20;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &:before {
                content: '';
                display: block;
                position: fixed;
                z-index: 10;
                top: 12rem;
                bottom: 0;
                left: 0;
                width: 100%;
                background: rgba(0, 0, 0, 0.15);
                opacity: 0;
                transition: opacity $transition-fast;
                pointer-events: none;
            }
        }

        &__input {
            font-family: $font-family-extended;
            color: $content-primary;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 1.6rem 6rem 1.6rem 0;
            border: none;
            border-bottom: 1px solid var(--Border-Secondary, #C7C7C7);
            height: auto;
            border-radius: 0;
            max-width: 60rem;
        }

        &__submit {
            display: none;

            @include min-screen($screen__xl) {
                display: block;
                border: none;
                background: none;
                padding: 2rem;
                transform: translateX(-100%);

                svg {
                    fill: black !important;
                }
            }
        }
        &__close {
            display: none;
            background: transparent;
            border: 0;

            @include min-screen($screen__xl) {
                display: flex;
                gap: 0.4rem;
                align-items: center;
                position: absolute !important;
                top: 2.4rem;
                right: 5rem;
                padding: 0 0 .4rem !important;
                color: black !important;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.1rem;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                border-bottom: 1px solid black;

                svg {
                    width: 1.7rem !important;
                    height: 1.7rem !important;
                    fill: black !important;
                    transform: translateX(-4px);
                }
            }

            @include min-screen($screen__xx) {
                right: 7rem;
            }
        }

        &__form {
            padding: 5.2rem 5rem 4.4rem;

            @include min-screen($screen__xl) {
                padding: 0 7rem 1rem;
            }
        }
    }

    .search-btn--toggle[aria-expanded="true"] + .search-form {
        max-height: 100vh;
        right: 0;

        @include min-screen($screen__xl) {
            max-height: 140px;
            box-shadow: 0 3px 5px 0 rgba(black, 0.1);
            border-bottom: 1px solid $border-color;

            &:before {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    .store-switcher {
        order: 2;

        @include min-screen($screen__xl) {
            order: 0;
        }

        a {
            position: relative;
            display: inline-flex;
            align-items: center;

            &:hover {
                &:after {
                    border-top-color: $primary !important;
                }
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                margin-left: .4rem;
                margin-right: -1.2rem;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px;
                border-top-style: solid;
                border-top-color: black;

                @include min-screen($screen__xl) {
                    border-top-color: white;
                }
            }
        }
    }

    .mini-cart {
        display: none;

        @include min-screen($screen__xl) {
            display: inline-block;
        }

        .mini-cart-link {
            svg {
                transition: stroke $transition-fast;
                stroke: white;
                stroke-width: 0.5px;
            }

            &:focus-visible svg,
            &:hover svg {
                stroke: $primary;
            }
        }
    }
}

.cms-home .page-header, .tissot_landing-page-prx .page-header,
.cms-home .page-header,
.tissot_landing-page-ttouch .page-header {
    @include min-screen($screen__xl) {
        .user-menu-block {
            li {
                a,
                button:not(.btn):not([type="submit"]) {
                    color: $primary;

                    .label {
                        transition: opacity $transition-fast;
                        opacity: 0;
                    }

                    &:hover, &:focus-visible {
                        .label {
                            opacity: 1;
                        }
                    }
                }
            }

            .store-switcher a::after {
                border-top-color: white;
            }
        }

        .search-btn--toggle svg {
            fill: white;
        }

        .search-btn--toggle:hover svg {
            @include min-screen($screen__xl) {
                fill: $primary;
            }
        }
    }
}

.main-menu {
    display: flex;
    flex-flow: column;
    padding: 1.2rem 0 0;
    margin: 0;
    align-items: stretch;

    @include min-screen($screen__xl) {
        padding-top: 0;
        position: relative;
        flex-flow: row;
        margin-left: -1.4rem;
    }

    @include min-screen($screen__xx) {
        margin-left: -1.6rem;
    }

    &__item {
        @include min-screen($screen__xl) {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
        }

        margin: 0;
        color: black;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        transition: color $transition-fast;

        &:hover {
            @include min-screen($screen__xl) {
                color: $primary;
            }
        }
    }

    &__item-btn {
        display: flex;
        width: 100%;
        border: none;
        background: none;
        align-items: center;
        position: relative;
        z-index: 20;
        cursor: pointer;
        color: black;
        text-transform: uppercase;
        transition: color $transition-fast;
        font-family: $font-family-extended;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.8rem;
        letter-spacing: 1px;
        padding: 1.2rem 2rem;

        @include min-screen($screen__xl) {
            height: var(--page-header-height);
            font-size: 1.2rem;
            color: white;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0.3px;
            padding: 1.6rem 1.4rem;
        }

        @include min-screen($screen__xx) {
            padding: 1.6rem;
            font-size: 1.3rem;
        }

        @extend .icon-chevron-down;

        &:hover, &:focus {
            color: $primary;
            border: none;
            background: none;
        }

        &:visited:not(:hover) {
            color: inherit !important;
        }

        &:before {
            font-family: "icomoon";
            position: absolute;
            top: 1.4rem;
            right: 2rem;
            width: 2.4rem;
            height: 2.4rem;
            font-size: 2.4rem;
            line-height: 2.6rem;
            overflow: hidden;
            transform: rotate(-90deg);
            transition: transform $transition-fast;

            @include min-screen($screen__xl) {
                display: none;
            }
        }
    }

    a.main-menu__item-btn:before {
        display: none;
    }

    &__submenu-container {
        @include max-screen($screen__xl) {
            position: fixed;
            top: 6.6rem;
            left: 100%;
            padding-bottom: 6rem;
            height: calc(100vh - var(--page-header-height));
            width: 100%;
            z-index: 40;
            background: white;
            overflow-y: auto;
            transition: all $transition-fast;
            -webkit-overflow-scrolling: touch;
        }

        @include min-screen($screen__xl) {
            background: white;
            max-height: 0;
            overflow: hidden;
            transition: max-height $transition-fast;
            padding: 0;
            position: absolute;
            left: -3.4rem;
            width: 100vw;
            top: 100%;
            z-index: 10;

            &:before {
                content: '';
                display: block;
                position: fixed;
                z-index: 10;
                top: 4rem;
                bottom: 0;
                left: 0;
                width: 100%;
                background: rgba(0, 0, 0, 0.15);
                opacity: 0;
                transition: opacity $transition-fast;
                pointer-events: none;
            }
        }

        @include min-screen($screen__xx) {
            left: -5.4rem;
        }

        a {
            --color: black;
            color: var(--color);

            &:hover {
                --color: #{$primary};
            }

            &:visited:not(:hover) {
                --color: black;
            }
        }
    }

    &__submenu-inner {
        display: flex;
        flex-flow: column;

        @include min-screen($screen__xl) {
            position: relative;
            z-index: 20;
            flex-flow: row;
            border-bottom: 1px solid var(--Border-Tertiary, #EAE8E7);
            background: #FFF;
        }
    }

    &__item[aria-expanded="true"] {
        .main-menu__submenu-container {
            @include max-screen($screen__xl) {
                left: 0;
            }

            @include min-screen($screen__xl) {
                max-height: calc(100vh - 120px);
                overflow: auto;
                box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.15);

                &:before {
                    opacity: 1;
                }
            }
        }
    }

    .main-menu__submenu-back {
        display: flex;
        align-items: center;
        gap: 1.2rem;
        padding: .8rem 2.4rem .8rem 1.2rem;
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--Border-Tertiary, #EAE8E7);
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.8rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        background: white;
        font-family: $font-family-extended;
        @extend .icon-chevron-down;

        &:before {
            font-family: "icomoon";
            width: 2.4rem;
            height: 2.4rem;
            font-size: 2.4rem;
            line-height: 2.6rem;
            overflow: hidden;
            transform: rotate(90deg);
            transition: transform $transition-fast;
        }

        @include min-screen($screen__xl) {
            display: none;
        }
    }

    &__submenu-column {

        ul:not(.swiper-wrapper) {
            margin: 0;
            padding: 2.4rem 0;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            @include min-screen($screen__xl) {
                padding: 0;
            }

            li {
                margin: 0;
            }
        }

        .main-menu__submenu-item {
            margin-bottom: 0;

            a {
                position: relative;
                display: inline-block;
                color: black;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0;
                font-size: 2rem;
                line-height: 2.6rem;
                text-transform: none;
                white-space: nowrap;

                &:after {
                    position: absolute;
                    bottom: -4px;
                    display: block;
                    content: "";
                    width: 0;
                    height: 2px;
                    background-color: black;
                    transition: width .3s;
                }

                &:hover:after {
                    width: 100%;
                }

                @include min-screen($screen__xx) {
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }
            }
        }

        &.part1 {
            @include max-screen($screen__xl) {
                padding: 1.6rem 0 0;
                background: var(--Surface-Container-Secondary, #F8F7F7);

                .main-menu__submenu-title {
                    display: block;
                    color: $content-tertiary;
                    font-family: $font-family;
                    font-size: 1.1rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 1.2rem;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    margin-bottom: 1.2rem;
                    padding: 0 2rem;
                }

              .swiper {
                  padding-bottom: 1.6rem;

                  ul {
                      margin: 0;
                      padding: 0;
                      list-style: none;
                      will-change: transform;
                  }
              }

                .main-menu__submenu-item {
                    width: 14rem;
                    min-width: 14rem;
                    background: white;

                    a {
                        padding: 1.4rem;
                        max-width: 14rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: flex-start;
                        gap: .4rem;
                        color: black;
                        text-align: center;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 1.2rem;
                        line-height: 1.6rem;
                        letter-spacing: 1.3px;
                        text-transform: uppercase;
                        font-family: $font-family-extended;

                        &:after {
                            display: none;
                        }

                        .category-image {
                            width: 100%;
                            height: auto;
                            margin-bottom: 0;
                            transition: all $transition-fast;
                        }
                    }
                }
            }

            @include min-screen($screen__xl) {
                padding: 3.2rem 4rem 4rem 5rem;
                background: var(--Surface-Container-Secondary, #F8F7F7);

                ul {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 1.2rem;
                    padding: 0;

                    li {
                        margin-right: 0 !important;
                    }

                    .main-menu__submenu-item {
                        background: white;

                        a {
                            padding: 1.4rem;
                            max-width: 14rem;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            gap: .4rem;
                            color: black;
                            text-align: center;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 1.2rem;
                            line-height: 1.6rem;
                            letter-spacing: 1.3px;
                            text-transform: uppercase;
                            font-family: $font-family-extended;

                            &:after {
                                display: none;
                            }

                            @include min-screen($screen__xx) {
                                gap: .8rem;
                                padding: 1.8rem;
                                font-size: 1.6rem;
                                line-height: 2.2rem;
                                max-width: 21rem;
                            }

                            .category-image {
                                width: 100%;
                                height: auto;
                                margin-bottom: 0;
                                transition: all $transition-fast;
                            }

                            &:hover {
                                .category-image {
                                    transform: scale(1.1) translateY(-.4rem);
                                }
                            }
                        }
                    }
                }
            }
            @include min-screen($screen__xx) {
                padding: 3.2rem 5rem 6rem 7rem;
            }
        }

        &.part2 {
            margin: 0 2rem 2rem;
            border-top: 1px solid var(--Border-Tertiary, #EAE8E7);

            @include min-screen($screen__xl) {
                margin: 0;
                border-top: none;
                padding: 3.2rem 8rem 4rem 4rem;
                border-left: 1px solid var(--Border-Tertiary, #EAE8E7);
            }
        }

        &.part3 {
            margin: 0 2rem 2rem;
            border-top: 1px solid var(--Border-Tertiary, #EAE8E7);

            @include min-screen($screen__xl) {
                margin: 0;
                border-top: none;
                padding: 3.2rem 8rem 4rem 4rem;
                border-left: 1px solid var(--Border-Tertiary, #EAE8E7);
            }
        }

        &.full {
            position: fixed;
            top: 6.6rem;
            left: 100%;
            bottom: 0;
            width: 100%;
            background: var(--Surface-Container-Secondary, #F8F7F7);
            margin: 0;
            overflow: auto;
            transition: all 0.2s ease-in-out;
            z-index: 10;

            &.-active {
                left: 0;
            }

            .main-menu__submenu-item {
                display: block;
            }

            .main-menu__submenu-subtitle {
                display: flex;
                align-items: center;
                gap: 1.2rem;
                padding: .8rem 2.4rem .8rem 1.2rem;
                border: none;
                width: 100%;
                border-bottom: 1px solid var(--Border-Tertiary, #EAE8E7);
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 1.8rem;
                letter-spacing: 1px;
                text-transform: uppercase;
                background: white;
                font-family: $font-family-extended;
                @extend .icon-chevron-down;

                &:before {
                    font-family: "icomoon";
                    width: 2.4rem;
                    height: 2.4rem;
                    font-size: 2.4rem;
                    line-height: 2.6rem;
                    overflow: hidden;
                    transform: rotate(90deg);
                    transition: transform $transition-fast;
                }
            }

            ul {
                padding: 1.6rem 2rem 3.2rem;
            }

            @include min-screen($screen__xl) {
                display: none;
            }
        }
    }

    &__submenu-column--img {
        padding: 16px 18px;
        display: none;

        @include min-screen($screen__xl) {
            display: block;
            width: 31%;
            margin-right: 11%;
        }
    }

    &__submenu-title {
        color: $content-tertiary;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        line-height: 2rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: block;
        margin-bottom: 2rem;
        white-space: nowrap;
        font-family: $font-family;

        @include max-screen($screen__xl) {
            display: none;
        }
    }

    &__submenu-minititle-btn {
        display: block;
        padding: 0 2rem 1rem 0;
        border: none;
        background: none;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        font-family: $font-family;
        line-height: 1.6rem;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        position: relative;
        cursor: pointer;
        color: black;
        width: 100%;
        text-align: left;
        transition: color $transition-fast;
        text-decoration: underline;
        text-underline-offset: 6px;

        @include min-screen($screen__xl) {
            display: none;
        }

        @extend .icon-chevron-down;

        &:before {
            font-family: "icomoon";
            position: absolute;
            top: -0.4rem;
            right: -0.6rem;
            width: 2.4rem;
            height: 2.4rem;
            font-size: 2.4rem;
            line-height: 2.6rem;
            overflow: hidden;
            transform: rotate(-90deg);
            transition: transform $transition-fast;
        }
    }

    &__submenu-all {
        height: 80px;
        display: flex;
        width: 100%;
        align-items: center;
        margin: 0;
        padding: 10px 0;
    }

    &__submenu-item{
        .category-image {
            width: 50px;
            height: 50px;
        }
        @include max-screen($screen__xl) {
           display: none;
        }

        &.mini-show {
            display: block;
        }
    }

}

.main-nav {
    position: relative;

    #escape {
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        overflow: hidden;
        display: block;
    }
}

.nav-toggle {
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        &:before {
            color: white;
        }
    }

    &:before {
        color: white;
    }

    @include min-screen($screen__m) {
        display: flex;
    }
    @include min-screen($screen__xl) {
        display: none;
    }
}

.cms-home .page-header, .tissot_landing-page-prx .page-header,
.cms-home .page-header,
.tissot_landing-page-ttouch .page-header {
    @include min-screen($screen__xl) {
        .main-menu__item,
        .main-menu__item-btn {
            color: white;

            &:hover {
                color: $primary;
            }
        }

        &.is-open-menu,
        &.is-open-search,
        &.is-header-black {
            .main-menu__item,
            .main-menu__item-btn {
                color: white;

                &:hover {
                    color: $primary;
                }
            }
        }
    }
}

.block-callout {
    position: relative;
    height: $header-block-callout-height-mobile;
    overflow: hidden;
    background: $primary;
    transition: height $transition-fast;
    text-align: center;

    @include min-screen($screen__xl) {
        height: $header-block-callout-height;
    }

    &.is-hidden {
        height: 0;
        overflow: hidden;
    }

    &.-anim-2 {
        .text-one {
            transform: translateY(100%);
            animation: slideUp2 6s linear infinite;
        }
        .text-two {
            transform: translateY(100%);
            animation: slideUp2 6s 3s linear infinite;
        }
    }

    &.-anim-3 {
        .text-one {
            transform: translateY(100%);
            animation: slideUp3 9s linear infinite;
        }
        .text-two {
            transform: translateY(100%);
            animation: slideUp3 9s 3s linear infinite;
        }
        .text-three {
            transform: translateY(100%);
            animation: slideUp3 9s 6s linear infinite;
        }
    }

    @keyframes slideUp2 {
        0% {
            transform: translateY(100%);
        }
        5%, 50% {
            transform: translateY(0);
        }
        55%, 100% {
            transform: translateY(-100%);
        }
    }

    @keyframes slideUp3 {
        0% {
            transform: translateY(100%);
        }
        3%, 33% {
            transform: translateY(0);
        }
        36%, 100% {
            transform: translateY(-100%);
        }
    }

    &--content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        min-height: $header-block-callout-height-mobile;
        text-align: center;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        gap: .8rem;
        margin: 0;
        color: black;
        padding: 0 2rem;

        p {
            margin: 0;
            width: 100%;
            text-align: center;
        }

        span {
            display: none;
        }

        @include min-screen($screen__xl) {
            align-items: center;
            justify-content: center;
            height: $header-block-callout-height;
            min-height: $header-block-callout-height;
            text-align: center;
            font-size: 1.5rem;
            line-height: 125%;
        }

        a {
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: underline;
            text-underline-offset: 2px;
            color: black;

            @include min-screen($screen__xl) {
                font-size: 1.4rem;
            }
        }

        .wide_contentsystem_summary {
            margin-bottom: 0;
        }
    }

    &--close button {
        color: white;
        display: block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        position: relative;
        background: transparent;
        border: 0;

        @include min-screen($screen__xl) {
            margin: 0 20px;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &:focus-visible {
            outline-color: white;
            outline-offset: -5px;
        }
    }
}

.mobile-menu-toggle-block {
    opacity: 1;
    &.-hide {
            opacity: 0;
            pointer-events: none;
    }
}

.mini-cart-link {
    opacity: 1;
    transition: all 0.2s ease-in-out;

    &.-hide {
        @include max-screen($screen__xl) {
            opacity: 0;
            pointer-events: none;
        }
    }

    .counter.qty {
        background: #e32119;
        color: #fff;
        height: 18px;
        font-size: 1.25rem;
        line-height: 18px;
        border-radius: 50%;
        display: inline-block;
        min-width: 18px;
        overflow: hidden;
        padding: 0 3px;
        text-align: center;
        margin: -5px 0 0 -10px;
        white-space: normal;
        position: absolute;
    }
}

.mini-product-search {

    @include min-screen($screen__xl) {
       display: none;
    }
    position: absolute;
    top: 0px;
    right: 0px;
    color: white;
    height: inherit;
    width: 100%;

    .search-btn--toggle, .find-store {
        border: 0;
        background: transparent;
        font-size: 1em;
        padding: 2rem;
        top: 0;
        color: white;
        right: 5.6rem;
        position: absolute;
        opacity: 1;
        transition: all 0.2s ease-in-out;

        .icon {
            display: block;
            width: 2.6rem;
            height: 2.6rem;
        }

        svg {
            fill: white;
            transition: fill $transition-fast;
        }

        &:hover svg {
            @include min-screen($screen__xl) {
                fill: $primary;
            }
        }
        &:visited svg {
            fill: white;
        }

        svg:last-child {
            display: none;
        }

        &[aria-expanded="true"] {
            @include max-screen($screen__xl) {
                background: black;
                left: auto;
                right: 0;
                z-index: 100;

                svg:first-child {
                    display: none;
                }
                svg:last-child {
                    display: block;
                    fill: white !important;
                }
            }
        }
    }
    .find-store {
        right: 0rem;
        .label {
            display: none;
        }
        &.-hide {
                opacity: 0;
                pointer-events: none;
        }
    }

    .search-form{
        background: white;
        position: relative;
        left: 0;
        max-height: 0;
        overflow: hidden;
        top: 100%;
        bottom: auto;
        right: 0;
        transition: max-height $transition-fast;
        border: 0;
        box-shadow: none;

        &__input {
            font-family: $font-family-extended;
            color: $content-primary;
            font-size: 1.1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.4rem;
            letter-spacing: 1px;
            text-transform: uppercase;
            padding: 1.6rem 0;
            border: none;
            border-bottom: 1px solid var(--Border-Secondary, #C7C7C7);

            @include max-screen($screen__l) {
                margin-bottom: 3.2rem;
            }
        }

        &__close {
            display: none;
            position: absolute;
            background: transparent;
            border: 0;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
        }

        &__form {
            padding: 4.4rem 3.2rem;
            display: flex;
            gap: 2.4rem;
            align-items: center;

            @include max-screen($screen__l) {
                gap: 0;
                flex-direction: column;
            }
        }
    }

    .search-btn--toggle[aria-expanded="true"] + .search-form,{
        right: 0;
        height: 100vh;
        max-height: calc(100vh - 6.6rem);

        @include min-screen($screen__xl) {
            max-height: 120px;
            box-shadow: 0 3px 5px 0 rgba(black, 0.1);
            border-bottom: 1px solid $border-color;
        }
    }

}
