%icon_chevron{
    background-color: white;
    border-radius: 50%;
    color: black;
    border: 1px solid black;
    height: 44px;
    overflow: hidden;
    width: 44px;
    top: calc(50% - 12px);
    transform: translateY(-50%);
    &:before{
        font-size: 20px;
        line-height: 1;
    }
    &:after{
        display: none;
    }
    @include min-screen($screen__l) {
        &:not(.swiper-button-disabled) {
            opacity: 1;
            visibility: visible;
        }
    }
}
.swiper-container {
    background: $white;
    max-width: 100vw;
    padding: 0 15px 0;
    position: relative;

    @include min-screen($screen__m) {
        padding: 0 0 70px;
    }

    .swiper-wrapper {
        margin-bottom: 0px;
        .swiper-slide.swiper-slide-active{
            opacity: 1;
        }
    }

    .swiper-scrollbar {
        height: 23px;
        width: 200px;
        background: #D9D9D9;
        cursor: pointer;
        position: absolute;
        bottom: 30px;
        left: 50%;
        transform: translateX(-50%);
        border-top: 10px solid white;
        border-bottom: 10px solid white;
        border-radius: 0;
        z-index: 0;

        @include min-screen($screen__m) {
            width: 60%;
            max-width: 100%;
            bottom: 60px;
        }

        &-drag {
            background: black;
            height: 23px; //3px;
            position: absolute;

            top: -10px;
            border-top: 10px solid white;
            border-bottom: 10px solid white;
            border-radius: 0;

            span {
                display:none;
            }
        }
    }

    .swiper-slide {
        //width: auto;
        opacity: 0.6;
        height: auto;
        position: relative;

        .pagebuilder-overlay {
            padding: 0 !important;
        }

        .pagebuilder-poster-content {
            max-width: 75vw;
        }

        &.is-hoverable {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                width: 0%;
                height: 3px;
                background-color: $primary;
                top: calc(100% + 10px);
                transition: width $transition-fast;
            }

            &:hover {
                &::after {
                    width: 100%;
                }
            }
        }

        transition: opacity 0.3s ease-in;

        @include min-screen($screen__m) {
            opacity: 1;
        }

        &-active {
            opacity: 1;
        }

        &--media {
            display: flex;
            align-items: center;
        }
    }

    .swiper-pagination {
        display: none;
    }

    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        display: inline-block;
    }

    .swiper-notification {
        text-indent: -9999999px;
        overflow: hidden;
        height: 0;
        width: 0;
        display: block;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 50%;
        width: 60px;
        height: 60px;
        transform: translateY(-50%);
        border: 0;
        background: transparent;
        z-index: 9;
        opacity: 0;
        transition: all $transition-fast;
        overflow: hidden;

        &:focus {
            opacity: 1;
        }
    }


    .swiper-button-prev {
        left: -10rem;

        &.icon-chevron-left{
            @extend %icon_chevron;
        }
    }

    .swiper-button-next {
        right: -10rem;

        &.icon-chevron-right{
            @extend %icon_chevron;
        }
    }
}

.slick-arrow { // For Page Builder slider use
    @extend %icon_chevron;
    background-color: $primary !important;
    color: $white !important;
    &::before{
        color: inherit !important;
        font-family: $font-icons !important;
    }
    &.slick-prev {
        @extend .swiper-button-prev;

        &::before {
            content: '\e902';
            padding: 0;
        }

        &:focus {
            left: 20px;
        }
    }

    &.slick-next {
        @extend .swiper-button-next;

        &::before {

            content: '\e903';
            padding: 0;
        }

        &:focus {
            right: 20px;
        }
    }
    &.slick-disabled{
        opacity: 0 !important;
    }
}

.slick-dots li {
    button{
        background: $grey-slider-dots;
        border: none;
        transition: background .2s;
    }
    button:hover,
    &.slick-active button{
        background: $primary;
        border: none;
    }
}

.bg-black {
    .swiper-container {
        background: black;
        color: white;

        padding: 50px 0 90px;

        @include min-screen($screen__m) {
            padding: 100px 0 170px;
        }
    }

    .swiper-scrollbar {
        border-color: black;
        bottom: 40px;

        @include min-screen($screen__m) {
            bottom: 90px;
        }

        &-drag {
            border-color: black;
        }
    }
}

.products-slider {
    .swiper-slide {
        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }
    &__swiper-container .swiper-slide:not(.swiper-slide-visible) {
        opacity: 1;
    }
    @include min-screen($screen__m) {
        .swiper-slide {
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
        }
    }
}

.slick-arrow { // For Page Builder slider use
    @extend %icon_chevron;
    background-color: $orange !important;
    color: $white !important;
    z-index: 9;
    &::before{
        color: inherit !important;
        font-family: $font-icons !important;
    }
    &.slick-prev {
        @extend .swiper-button-prev;

        &::before {
            content: '\e902';
            padding: 0;
        }

        &:focus {
            left: 20px;
        }
    }

    &.slick-next {
        @extend .swiper-button-next;

        &::before {

            content: '\e903';
            padding: 0;
        }

        &:focus {
            right: 20px;
        }
    }
    &.slick-disabled{
        opacity: 0 !important;
    }
}

.slick-dots li {
    &:before{
        display: none;
    }
    button{
        background: $dark-grey;
        border: none;
        transition: background .2s;
    }
    button:hover,
    &.slick-active button{
        background: $orange;
        border: none;
    }
}
