
.block-owc {
    h1 {
        @extend .title-h1;
    }

    p {
        @extend .text-default;
    }

    & > .page-container {
        padding-bottom: 10rem;

        @include max-screen($screen__m) {
            padding: 0;
        }

        .listCards .productCard .technical-spec {
            min-width: 100%;
            padding: 0 3rem;
            text-align: left;

            svg {
                vertical-align: text-top;
                margin-right: 0.6rem;
            }

            .toggle {
                text-transform: uppercase;
                color: #252525;
                letter-spacing: 2px;
                font-size: 12px;
                padding: 10px 0;
                border-top: 1px solid rgba(0, 0, 0, .2);
            }

            .toggle:after {
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 28px;
                line-height: 16px;
                color: inherit;
                content: "";
                font-family: icons-blank-theme;
                vertical-align: middle;
                display: inline-block;
                font-weight: 400;
                overflow: hidden;
                speak: none;
                text-align: center;
                position: static;
                float: right;
                width: 20px;
            }

            .productCard__infos {
                margin: 1rem 0px 2rem;
                text-align: left;
                line-height: 1;

                span {
                    text-align: left;
                    display: inline;

                    .description {
                        font-weight: bold;
                    }
                }

                p {
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 2px;
                    text-transform: uppercase;
                }

                .btn {
                    display: block;
                    min-width: 100%;
                    margin-top: 1rem;
                    text-align: center;
                    text-decoration: none;
                }
            }
        }

        .listCards .productCard .technical-spec.active > .toggle:after {
            content: "";
        }

        .form,
        .page-sub-title {
            margin-bottom: 2rem;
            width: 100%;

            .fieldset {
                margin-bottom: 2rem;
            }

            .form-group {
                width: 100%;
            }
        }
        .page-sub-title {
            max-width: 54rem;
            width: 80%;
            @include max-screen($screen__m) {
                width: 100%;
            }
        }

        .product-wrapper {
            max-width: 50rem;
            width: 80%;
            @include max-screen($screen__m) {
                width: 100%;
            }
        }

        & > .productCard {
            max-width: 43rem;
            width: 100%;

            @include max-screen($screen__s) {
                max-width: none;
            }
        }

        .page-btns.actions-toolbar {
            margin-right: -1rem;
            max-width: 55rem;

            @include max-screen($screen__s) {
                margin-right: 0;
                max-width: none;
            }

            .btn {
                margin-bottom: 1rem;
                    max-width: 54rem;
                    width: 80%;
                @include max-screen($screen__m) {
                    max-width: 100%;
                    width: 100%;
                }
                @include min-screen($screen__s) {
                    max-width: 54rem;
                    width: 100%;
                }
                @include min-screen($screen__m) {
                    width: 46rem;
                }
                @include min-screen($screen__l) {
                    width: 54rem;
                }
            }
        }
    }

    .page-btns {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 5rem;
        margin-left: 0 !important;

        .btn {
            margin-right: 1rem;
        }

        p {
            width: 100%;
            font-size: 14px;
            line-height: 21px;
            color: #404141;

            a {
                text-decoration: underline;
            }
        }

        @include max-screen($screen__s) {
            flex-direction: column;
            margin-bottom: 3rem;

            .btn {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;
            }
        }
    }

    .page-back {
        color: $primary;
        font-weight: 300;
        font-size: 14px;
        line-height: 30px;
        display: block;
        margin-bottom: 2rem;

        .icon {
            display: inline-block;
            vertical-align: text-top;
            margin-right: 0.6rem;
            transition: all 0.2s ease-in-out;
        }

        &:visited:not(:hover) {
            color: $primary;
        }

        &:hover, &:focus {
            color: $primary;

            .icon {
                margin-right: 1rem;
            }
        }
    }

    .page-alert {
        background: rgba(26, 159, 11, 0.1);
        margin-bottom: 3rem;
        padding: 2rem;

        p {
            color: #404141;
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 0;

            &:not(:last-child) {
                @include max-screen($screen__s) {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .listCards {
        margin: 0 -.5rem 10rem;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
            width: 50%;
            padding: .5rem;
            margin-bottom: 0;

            @include max-screen($screen__l) {
                width: 100%;
                margin-bottom: 1.5rem;
            }

            .productCard {
                display: flex;
                margin-bottom: 0;
            }
        }
    }

    .productCard {
        display: flex;
        margin-bottom: 3rem;
        height: 100%;
        align-items: center;
        text-align: center;
        padding: 1.8rem 3rem 3rem;
        flex-direction: column;
        border: 1px solid #C4C4C4;

        @include max-screen($screen__l) {
            padding: 1.8rem 1rem 3rem;
        }

        &__actions {
            width: 100%;
            margin-top: -1.4rem;

            @include min-screen($screen__l) {
                margin-right: -5rem;
            }

            .action-close {
                background-image: none;
                background: none;
                -moz-box-sizing: content-box;
                border: 0;
                box-shadow: none;
                line-height: inherit;
                margin: 0;
                padding: 0;
                text-shadow: none;
                font-weight: 400;
                text-decoration: none;
                float: right;

                &:before {
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 32px;
                    line-height: 32px;
                    color: #8f8f8f;
                    content: "";
                    font-family: icons-blank-theme;
                    margin: 0;
                    vertical-align: top;
                    display: inline-block;
                    font-weight: 400;
                    overflow: hidden;
                    speak: none;
                    text-align: center;
                }
            }
        }

        &__aroundImage {
            height: 300px;
        }

        &__visual {
            width: auto;
            height: 270px;
            margin-bottom: 1.6rem;
        }

        &__title {
            color: black;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 2px;
            margin-bottom: 1.6rem;
            text-transform: uppercase;
        }

        &__infos {
            margin-bottom: 2.5rem;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                font-size: 12px;
                line-height: 16px;
                text-align: center;
                letter-spacing: 2px;
                display: block;
                text-transform: uppercase;
            }
        }

        &__warranty {
            margin-bottom: auto;
            color: #333333;
            font-size: 13px;
            line-height: 24px;
            vertical-align: middle;
            text-transform: uppercase;
            font-weight: bold;

            &.-reversed {
                color: white;
                background-color: black;
                border-radius: 16px;
                padding: 4px 12px;
            }

            svg {
                vertical-align: text-top;
                margin-right: 0.6rem;
            }
        }

        p {
            color: #404141;
            font-size: 14px;
            line-height: 21px;
            margin-top: 1.4rem;
            margin-bottom: 0;
            width: 80%;

            a {
                text-decoration: underline;
            }
        }

        .btn {
            min-width: 25rem;
            margin-top: 3rem;
        }

        .btn.only {
            margin-top: 0;
        }

        .btn + .btn {
            margin-top: 1rem;
        }
    }
//.block-owc .owc-account.-check:after
    .owc-account {
        margin-left: auto;
        margin-right: auto;
        padding: 25px 15px;
        min-height: 70vh;

        @include max-screen($screen__m) {
            min-height: 0;
        }

        .page-container {
            max-width: 860px;
            margin: 0 auto;
            padding: 0 15px;
        }

        .page-sub-title, .page-sub-title p {
            font-weight: 300;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 4rem;

            @include max-screen($screen__m) {
                margin-bottom: 3rem;
            }
        }

        .page-sub-title p {
            margin-bottom: 2rem;
            @include max-screen($screen__m) {
                margin-bottom: 0;
            }
        }

        &.-create {
            .page-title {
                margin-top: 5rem;
                margin-bottom: 5rem;
                @include max-screen($screen__m) {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
            }

            .fieldset {
                margin-bottom: 0px;
            }
        }

        &.-create, &.-login {
            .page-title, .page-sub-title {
                text-align: center;
                @include max-screen($screen__m) {
                    text-align: left;
                }
            }
        }

        &.-check {
            max-width: 1000px;
            margin: 0 auto;
            padding: 0 25px;


            @include min-screen($screen__m) {
                flex-direction: row;
                align-items: flex-start;
            }

            @include max-screen($screen__m) {
                flex-direction: column;

                &:last-child {
                    margin-bottom: 50px;
                }
            }

            .page-title {
                width: 100%;
                text-align: center;
                margin: 40px 0 15px;

                h1 {
                    @extend .title-h1;
                }
            }

            .page-sub-title {
                margin-bottom: 3rem;
                @include max-screen($screen__m) {
                    margin-bottom: 1rem;
                }
            }

            .page-container {
                max-width: none;
                padding: initial;
                display: flex;
                margin: 50px 0;
                width: 100%;
                flex-direction: column;

                @include min-screen($screen__m) {
                    flex-direction: row;
                    align-items: flex-start;
                }

                .actions-toolbar {
                    margin-top: -60px;

                }

            }

            .block-customer-login {
                flex: 1;

                @include min-screen($screen__m) {
                    padding-right: 5%;
                }

                .block-title #block-customer-login-heading {
                    @extend .title-h4;
                }

                .form-login {
                    padding: 40px 0 0 0;

                    .form-group,
                    .field .control {
                        width: 100%;
                    }

                    .note {
                        @extend .text-default;
                        font-size: 15px;
                        color: $dark-grey;
                        margin-top: 30px;
                    }

                    .actions-toolbar {
                        margin-left: 0;
                        margin: 20px 0;

                        .primary button {
                            @include make-btn;
                            @include make-btn-primary;
                            margin-top: 20px;
                        }

                        .secondary {
                            clear: left;
                            float: left;

                            .action.remind {
                                text-align: left;
                                font-size: 15px;
                                color: $dark-grey;
                                text-decoration: underline;
                                margin-top: 20px;
                            }
                        }
                    }
                }

                .block-content {
                    p {
                        @extend .text-default;
                        padding: 40px 0;
                        margin: 0;
                    }

                    .actions-toolbar {
                        margin: 20px 0;

                        .primary a {
                            @include make-btn;
                            @include make-btn-primary;
                        }
                    }
                }
            }

            .block-new-customer {
                flex: 1;

                @include min-screen($screen__m) {
                    padding-left: 5%;
                    border-left: 1px solid #cdcdcd;
                }

                .block-title #block-new-customer-heading {
                    @extend .title-h4;
                }

                .block-content {
                    p {
                        @extend .text-default;
                        .block-new-customer {
                            flex: 1;

                            @include min-screen($screen__m) {
                                padding-left: 5%;
                                border-left: 1px solid #cdcdcd;
                            }

                            .block-title #block-new-customer-heading {
                                @extend .title-h4;
                            }

                            .block-content {
                                p {
                                    @extend .text-default;
                                    padding: 40px 0;
                                    margin: 0;
                                }

                                .actions-toolbar {
                                    margin: 20px 0;

                                    .primary a {
                                        @include make-btn;
                                        @include make-btn-primary;
                                    }
                                }
                            }
                        }      padding: 40px 0;
                        margin: 0;
                    }

                    .actions-toolbar {
                        margin: 20px 0;

                        .primary a {
                            @include make-btn;
                            @include make-btn-primary;
                        }
                    }
                }
            }
        }

        &.-landing {
            width: 100vw;
            position: relative;
            left: 48%;
            transform: translateX(-50%);
            margin-top: -80px;
            margin-bottom: -70px;

            @include max-screen($screen__m) {
                margin-bottom: -40px;
            }

            @include min-screen($screen__m) {
                height: 700px;
            }
            @include min-screen($screen__l) {
                height: 700px;
            }
            @include min-screen($screen__xl) {
                height: 600px;
            }
            @include min-screen($screen__xll) {
                height: 650px;
            }
            @include min-screen($screen__xx) {
                height: 750px;
            }
            @include min-screen($screen__xxx) {
                height: 950px;
            }



            .page-container {
                width: calc(50vw - 11rem);
                transform: translateX(-25vw);
                margin-top: 3.5vw;

                @include max-screen($screen__m) {
                    width: 100%;
                    transform: none;
                    margin-top: 15.5vw;
                }
                .actions-toolbar {
                    margin-top: 0px;

                }

                .-primary, .btn.-primary, .form-group input[type="email"] {
                    width: 25vw;
                    @include max-screen($screen__m) {
                        width: 100%;
                    }
                }
                .actions-toolbar {

                }
                .owc-landing-img {
                    z-index:-100;
                    display: block;
                    content: '';
                    background: #FFF0  var(--url-image) no-repeat top center / cover;
                    position: absolute;
                    left: calc(50vw - 9rem);
                    top: -12vh;
                    transform: translateY(3vw);
                    @include max-screen($screen__m) {
                        left: 0;
                        transform: translateX(-8vw);
                        width: 92vw;
                        margin-left: 20px;
                        display: block;
                        position: inherit;
                    }
                    @include min-screen($screen__m) {
                        width: 432px;
                        height: 324px;
                        top: -4vh;
                    }
                    @include min-screen($screen__l) {
                        width: 540px;
                        height: 405px;
                        top: -4vh;
                    }
                    @include min-screen($screen__xl) {
                        width: 648px;
                        height: 486px;
                        top: -6vh;
                    }
                    @include min-screen($screen__xll) {
                        width: 756px;
                        height: 567px;
                        top: -8vh;
                    }
                    @include min-screen($screen__xx) {
                        width: 864px;
                        height: 648px;
                    }
                    @include min-screen($screen__xxx) {
                        width: 1080px;
                        height: 810px;
                    }
                }
            }

            .page-title {
                margin-bottom: 2rem;
                @include max-screen($screen__m) {
                    margin-bottom: 1rem;
                }
            }

            .page-sub-title {
                margin-bottom: 3rem;
                @include max-screen($screen__m) {
                    margin-bottom: 1rem;
                }
            }
        }

        .block-title > h4 {
            @extend .title-h4;
            margin-bottom: 60px;
            color: #000;
            font-family: $font-family;
            font-size: 1em;
            font-weight: 500;
            letter-spacing: 2px;
            line-height: 1.44em;
            text-transform: uppercase;
        }

        .form {
            &.create.account {
                min-width: 0;
                width: 100%;
            }

            .form-group {
                width: 100%;
                //max-width: 54rem;
                //width: 80%;
            }

            .control .fieldset {
                margin-bottom: 2rem;
            }
        }

        .actions-toolbar {
            margin-top: 3rem;
            margin-left: 0 !important;

            .btn {
              //  width: 100%;
            }
        }
    }

    .captcha-container {
        margin-bottom: 2rem;
        @include max-screen($screen__m) {
            margin-bottom: 3rem;
        }
    }

    .form-link {
        display: inline-block;
        margin-bottom: 2rem;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-decoration-line: underline;
    }

    #google-captcha {
        margin-top: 0;
    }

    div.field-recaptcha {
        position: relative;

        div.field {
            position: absolute;
            z-index: -1;
            top: 2rem;
            left: 2rem;

            div.control {
                position: relative;
                z-index: -1;

                input.mage-error {
                    border-color: white;
                }

                input:focus {
                    outline: none;
                    outline-offset: 0;
                }

                div.mage-error {
                    position: relative;
                    top: unset;
                    left: unset;
                    text-transform: none;
                }
            }
        }
    }

    .warrantycard {
        text-align: center;

        .page-sub-title {
            margin: 0 auto 2rem;
        }

        .page-btns.actions-toolbar {
            margin: 0 auto 5rem !important;

            a {
                margin: 0 auto 1rem
            }
        }
    }

    .fieldset {
        width: 100%;
        margin-top: 30px;
        cursor: pointer;

        &.active {
            .block-title {
                .chevron {
                    transform: rotate(-45deg);
                }
            }
        }

        &:not(.chack):not(.fieldset__input-maks):not(.login) {
          //  border: 1px solid $light-grey;
            border-left: none;
            border-right: none;
        }

        &.check {
            max-width: 54rem;
            width: 80%;
            @include max-screen($screen__m) {
                max-width: 100%;
                width: 100%;
            }
        }

        .block-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;

            h2 {
                @extend .title-h4;
                text-align: center;
                margin-top: 2.5rem;
                text-transform: initial;
            }

            .chevron {
                border-style: solid;
                border-width: 0.2em 0.2em 0 0;
                content: '';
                height: 2em;
                right: 5px;
                position: relative;
                top: -0.15em;
                transform: rotate(135deg);
                vertical-align: top;
                width: 2em;
                @include max-screen($screen__m) {
                    height: 1em;
                    width: 2em;
                    right: 5px;
                }
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 30px;
        }
    }

    .carte_etiquette {
        display: flex;
        position: relative;
        z-index: 2;
        @include max-screen($screen__m) {
            display: block;
        }
    }

    .click-zoom {
        &:first-child {
            padding-right: 20px;

            @include max-screen($screen__m) {
                padding-right: 0px;
            }
        }

        input[type=checkbox] {
            display: none;
        }

        img {
            margin: 0;
            transition: transform 0.25s ease;
            cursor: zoom-in;
        }

        input[type=checkbox]:checked ~ img {
            transform: scale(2);
            cursor: url('../images/logo/x-circle.png'), url('../images/logo/x-circle.png') 5 5, progress;
        }
    }
}

.block-owc.double {
    .page-title {
        width: 100%;
        text-align: center;
        margin: 40px 0 15px;
    }

    .warrantycard {

        .left, .right {
            -webkit-box-flex: 1;
            flex: 1;
        }

        .block-title h3 {
            font-family: Heebo, Roboto, Arial, Helvetica, sans-serif;
            font-weight: 500;
            font-size: 1em;
            line-height: 1.44em;
            letter-spacing: 2px;
            text-transform: uppercase;
            color: #000;
            text-align: center;
            margin: 1.5rem auto 1rem;
        }

        .block-content p {
            padding: 40px 0;
            margin: 0;
            @include max-screen($screen__m) {
                padding: 0;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .block-owc.double .warrantycard {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        -webkit-box-align: start;
        align-items: flex-start;
        display: flex;
        margin: 50px 0;
        width: 100%;

        .left {
            padding-right: 5%;
        }

        .right {
            padding-left: 5%;
            border-left: 1px solid #cdcdcd;
        }
    }
}

.modal-owc {
    &.modal-popup.modal-slide {
        @include max-screen($screen__m) {
            left: 0;
        }
    }

    .modal-inner-wrap {
        max-width: 73rem;

        .modal-content {
            padding: 0 3rem 3rem;
        }
        .modal-title {
            font-size: 1.6rem;
        }
    }

    .owc-modal {
        padding-top: 4rem;
        padding-bottom: 5rem;

        &__title {
            font-size: 40px;
            line-height: 50px;
            text-align: center;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: #000000;
            font-weight: bold;
            margin-bottom: 4rem;

            @include max-screen($screen__m) {
                font-size: 25px;
                line-height: 28px;
                letter-spacing: 1.5px;
            }
        }

        &__visual {
            margin-bottom: 4rem;
        }

        &__desc {
            text-align: center;
            @extend .text-default;
        }

        .productError {
            max-width: 43rem;
            margin: 4rem auto 0;
            text-align: center;
        }

        .productResume {
            background: #F2F2F2;
            display: flex;
            max-width: 43rem;
            margin: 0 auto 2rem;
            padding: 1.4rem 2.5rem;
            align-items: center;

            &__visual {
                width: 5rem;
                margin-right: 4rem;
                margin-bottom: 0;
            }

            &__title {
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 2px;
                text-transform: uppercase;
            }
        }

        .form {
            max-width: 43rem;
            margin: 0 auto;

            .select-group {
                width: 100%;
                margin: 0 0 2rem;

                label {
                    display: block;
                    margin-bottom: 1rem;
                }

                &:before {
                    z-index: 10;
                    bottom: 0;
                    top: auto;
                    transform: translateY(0);
                    font-size: 28px;
                    line-height: 56px;
                }
            }
        }

        .btn {
            width: 100%;
            max-width: 43rem;
        }
        .modal-title {
            @include max-screen($screen__m) {
                font-size: 1.8rem !important;
            }
        }
    }
}
