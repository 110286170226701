$black: black;

.cathegory-thumbnail {
    display: block;
    width: auto;
    cursor: pointer;

    &__img-container {
        padding-top: 75%;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            transition: all 200ms ease-in-out;
            min-width: 100%;
        }
    }

    &:hover {
        .cathegory-thumbnail__img-container img {
            transform: translate(-50%, -50%) scale(1.03);
        }
        h3 {
            color: $primary;
        }
    }

    &__title {
        font-size: 1em;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        font-weight: 400;
        margin: 15px;
        color: $black;
        text-align: center;

        @include min-screen($screen__m) {
            font-size: 1.25em;
            margin: 15px 20px 10px;
        }
    }

    &__description {
        font-size: 1.6rem;
        text-transform: none;
        letter-spacing: 0.05em;
        font-weight: 200;
        margin: 15px;
        color: $black;
        text-align: center;

        @include min-screen($screen__m) {
            font-size: 0.4em;
            margin: 15px 20px 10px;
        }
    }

    h3 {
        @include max-screen($screen__m) {
            margin: 0;
            .cathegory-title {
                font-size: 0.6em;
            }
        }
    }

}


.cathegory-list {
    margin: 0 8px 40px;

    @include min-screen($screen__m) {
        margin: 0 20px 40px;
    }

    @include min-screen($screen__xl) {
        margin: 0 3vw 90px;
        padding: 0 20px;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        @include min-screen($screen__m) {
            margin: 0 -10px;
        }
    }

    &__item {
        width: 50%;
        padding: 0 8px;
        margin-bottom: 0;

        @include min-screen($screen__m) {
            width: 33.33%;
            padding: 0 10px;
            margin-bottom: 20px;
        }

        h3 {
            @include max-screen($screen__m) {
                margin: 0;
                .cathegory-title {
                    font-size:  0.7em;
                }
            }
        }

        &__description {
            font-size: 1.6rem;
            text-transform: none;
            letter-spacing: 0.05em;
            font-weight: 200;
            margin: 15px;
            color: $black;
            text-align: center;

            @include min-screen($screen__m) {
                font-size: 0.4em !important;
                margin: 15px 20px 10px;
            }
        }
    }

    &__title {
        @extend .title-h2;
        margin-bottom: 50px;
        text-align: center !important;

        @include min-screen($screen__m) {
            font-size: 3.2rem !important;
            line-height: 4rem !important;
        }
    }

    &--articles {
        @extend .cathegory-list;

        @include min-screen($screen__m) {
            max-width: $width-large;
            margin: 0 auto 10px;
        }
        @include min-screen($screen__xl) {
            margin: 0 auto 10px;
        }

        .cathegory-list__item {
            list-style: none;
            @include min-screen($screen__m) {
                width: 50%;
            }
        }
    }
}

.cathegory-list--anim {

    .cathegory-list__item:nth-child(1),
    .cathegory-list__item:nth-child(2) {
        transform: translateY(5vh);
        opacity: 0.5;
        transition: all 1s ease-in 0s;
    }

    .cathegory-list__item:nth-child(3),
    .cathegory-list__item:nth-child(4) {
        transform: translateY(5vh);
        opacity: 0.5;
        transition: all 1s ease-in 0.5s;
    }

    .cathegory-list__item:nth-child(5),
    .cathegory-list__item:nth-child(6) {
        transform: translateY(5vh);
        opacity: 0.5;
        transition: all 1s ease-in 1s;
    }

    .cathegory-list__item:nth-child(7),
    .cathegory-list__item:nth-child(8),
    .cathegory-list__item:nth-child(9) {
        transform: translateY(5vh);
        opacity: 0.5;
        transition: all 1s ease-in 1s;
    }

    @include min-screen($screen__m) {
        .cathegory-list__item:nth-child(1),
        .cathegory-list__item:nth-child(2),
        .cathegory-list__item:nth-child(3) {
            transform: translateY(5vh);
            opacity: 0.5;
            transition: all 0.7s ease-in 0s;
        }

        .cathegory-list__item:nth-child(4),
        .cathegory-list__item:nth-child(5),
        .cathegory-list__item:nth-child(6) {
            transform: translateY(10vh);
            opacity: 0.3;
            transition: all 1s ease-in 0.5s;
        }

        .cathegory-list__item:nth-child(7),
        .cathegory-list__item:nth-child(8),
        .cathegory-list__item:nth-child(9) {
            transform: translateY(15vh);
            opacity: 0.3;
            transition: all 1s ease-in 0.5s;
        }
    }

    &.is-visible {
        .cathegory-list__item {
            opacity: 1 !important;
            transform: translateY(0);
        }
    }
}

.category-collections {
    .collection-list-slider__swiper-container {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .swiper-scrollbar {
            display: block;
        }

        .swiper-wrapper {
            margin: 15px;
        }

        @include min-screen($screen__m) {
            .swiper-scrollbar {
                display: none;
            }

            .swiper-wrapper {
                margin: 0 !important;
            }
        }

        .swiper-slide {
            margin-bottom: 10px;
            position: relative;

            @include min-screen($screen__m) {
                &:hover {
                    &::after {
                        position: absolute;
                        width: 100%;
                        background-color: $primary;
                        height: 2px;
                        content: "";
                        bottom: -10px;
                        left: 0;
                        z-index: 10000;
                    }
                }
            }

            .category-thumbnail {
                --title-color: black;
                --img-transform: scale(1);

                &:hover {
                    --img-transform: scale(1.03);
                }

                &[data-scroll-to] {
                    cursor: pointer;
                }

                &__img-container img {
                    transform: var(--img-transform);
                    transition: transform $transition-fast;
                }

                &__title {
                    @extend .title-h4;
                    color: var(--title-color);
                    transition: color $transition-fast;
                    margin: 0 !important;
                    padding: 15px 0;
                    text-align: center;
                }
            }
        }
    }

    .collection-detail {
        .text-media {
            display: flex;
            flex-flow: column nowrap;
            padding: 0 16px;

            @include min-screen($screen__m) {
                padding: 0 20px;
            }

            @include min-screen($screen__xl) {
                margin: 0 3vw;
            }

            &__media {
                position: relative;
                margin: 0;
                width: 100%;
                background-color: lightblue;
                /* need to bypass the scss compiler & use a literal instead. */
                padding-bottom: #{"min(75%, 500px)"};
                background: var(--background-image) no-repeat center center;
                -webkit-background-size: cover;
                -moz-background-size: cover;
                -o-background-size: cover;
                background-size: cover;
            }

            &__text {
                text-align: center;
                padding: 0;
                max-width: $width-medium;
            }

            &__btn {
                margin: 12px 0;
                min-width: 263px;
            }
        }
    }
}

.cms-home.cms-index-index .cathegory-thumbnail h3 {
    @include max-screen($screen__m) {
        margin: 1.5rem auto;
        .cathegory-title{
            font-size: 1em;
        }
    }
}
