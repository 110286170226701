.tissot-prefooter {
    display: flex;
    flex-direction: column;

    @include min-screen($screen__s) {
        flex-direction: row;
    }
}

.prefooter {
    $c: &;

    display: flex;
    flex-direction: column;

    @include min-screen($screen__s) {
        flex-direction: row;
    }

    &__left,
    &__right {
        position: relative;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        min-height: 27.5rem;

        img {
            object-fit: cover;
            height: 100%;
        }

        &--layer {
            &:before {
                position: absolute;
                content: " ";
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0, 0, 0, .5);
            }

            #{$c}__content {
                position: relative;
                z-index: 10;
            }
        }


        @include min-screen($screen__s) {
            width: 50%;
        }

        &--align-top {
            align-items: flex-start;
        }

        &--align-bottom {
            align-items: flex-end;
        }

        &--white #{$c}__title {
            color: white;
        }
    }

    &__container,
    &__content {
        transform: scale(1);
        transition: transform 200ms ease-in-out;

        // &:hover {
        //     transition: transform 200ms linear;
        // }
    }

    &__container {
        width: 100%;
        height: 100%;
        padding-top: 60%;
        background-position: center center;
        background-size: cover;

        &:hover {
            transform: scale(1.03);

            // .prefooter__content {
            //     transform: scale(.9);
            // }
        }
    }

    &__content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 8rem;
        min-height: 27.5rem;
    }

    &__title {
        @extend .title-h2;
        margin: auto;
        display: block;
        text-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35), 0px 0px 100px rgba(0, 0, 0, 0.80);
    }

    &__link {
        @extend .btn;
        @extend .-secondary;
        margin-top: 1.2rem;
        width: 100%;
        max-width: 35rem;
    }
}
