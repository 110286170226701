@mixin blockquote {
    margin: 90px auto;
    font-size: 35px;
    line-height: 125%;
    position: relative;
    text-align: left;
    font-style: normal;

    &::after,
    &::before {
        content: "“";
        font-size: 100px;
        font-weight: 300;
        color: $primary;
        font-family: $font-family-secondary;
    }

    &::before {
        position: absolute;
        top: 0;
        left: 0;
    }

    &::after {
        float: right;
        transform: translateY(-70%) rotate(180deg);
    }

    strong {
        font-family: $font-family;
        font-weight: bold;
    }
}

.quote-author,
blockquote {
    @include blockquote();
}
