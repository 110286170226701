.navigation,
.page-header .header.panel,
.header.content,
.footer.content,
.page-wrapper > .widget,
.page-wrapper > .page-bottom,
.block.category.event,
.top-container,
.page-main {
    max-width: 100%;
    @include max-screen($screen__m){
        padding-left: 0;
        padding-right: 0;
    }
    @include min-screen($screen__m) {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}

.columns .column.main {
    padding-bottom: 0;
}

.page-products .columns {
    position: static;
}

.block {
    margin-bottom: 0;
}

@include max-screen($screen__m) {
    .page-main {
        .account &,
        .cms-privacy-policy & {
            padding-top: 0 !important;
        }
    }
}

@include max-screen($screen__m) {
    .account {
        .page.messages {
            margin-bottom: 0 !important;
        }
    }
}

[data-content-type='row'][data-appearance='contained'] {
    max-width: inherit;
}

[data-content-type='row'][data-appearance='full-width'] {
    .row-full-width-inner {
        max-width: inherit;
    }
}

@media only screen and (min-width: 768px) {
    .page-layout-2columns-left, .page-layout-2columns-right {
        .column.main {
            width: 100%;
        }
    }
}
