/* m2 specific css */
.tissot2-account, .sales-guest-view, .order-update-view {
    #popin__email_validation {
        text-align: center;
        padding: 0 30px 30px;

        h1 {
            @extend .title-h1;
        }

        a {
            color: #DC1F18;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .page-main {
        .columns {
            margin-left: auto;
            margin-right: auto;
            padding: 25px 15px;

            @include min-screen($screen__xl) {
                max-width: $screen__xl;
            }

            @include screen($screen__l, $screen__xl) {
                max-width: $screen__l;
            }

            @include screen($screen__m, $screen__l) {
                max-width: $screen__m;
            }

            .sidebar {
                order: 0;
                top: calc(var(--page-header-height) + 10px);
                z-index: 1;

                @include min-screen($screen__m) {
                    position: sticky;
                }
            }

            .main {
                order: 1;
            }
        }
    }

    .account-nav {
        &-content {
            background: transparent;
        }
    }

    .customer-account-login {
        max-width: 860px;
        margin: 0 auto;
        padding: 0 15px;

        @include min-screen($screen__m) {
            flex-direction: row;
            align-items: flex-start;
        }

        @include max-screen($screen__m) {
            flex-direction: column;

            &:last-child {
                margin-bottom: 50px;
            }
        }

        .page-title {
            width: 100%;
            text-align: center;
            margin: 40px 0 15px;

            h1 {
                @extend .title-h1;
            }
        }

        .page-container {
            display: flex;
            margin: 50px 0;
            width: 100%;
            flex-direction: column;

            @include min-screen($screen__m) {
                flex-direction: row;
                align-items: flex-start;
            }

            .block-customer-login {
                flex: 1;

                @include min-screen($screen__m) {
                    padding-right: 5%;
                }

                .block-title #block-customer-login-heading {
                    @extend .title-h4;
                }

                .form-login {
                    padding: 40px 0 0 0;

                    .form-group,
                    .field .control {
                        width: 100%;
                    }

                    .note {
                        @extend .text-default;
                        font-size: 15px;
                        color: $dark-grey;
                        margin-top: 30px;
                    }

                    .actions-toolbar {
                        margin-left: 0;
                        margin: 20px 0;

                        .primary button {
                            @include make-btn;
                            @include make-btn-primary;
                            margin-top: 20px;
                        }

                        .secondary {
                            clear: left;
                            float: left;

                            .action.remind {
                                text-align: left;
                                font-size: 15px;
                                color: $dark-grey;
                                text-decoration: underline;
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }

            .block-new-customer {
                flex: 1;

                @include min-screen($screen__m) {
                    padding-left: 5%;
                    border-left: 1px solid #cdcdcd;
                }

                .block-title #block-new-customer-heading {
                    @extend .title-h4;
                }

                .block-content {
                    p {
                        @extend .text-default;
                        padding: 40px 0;
                        margin: 0;
                    }

                    .actions-toolbar {
                        margin: 20px 0;

                        .primary a {
                            @include make-btn;
                            @include make-btn-primary;
                        }
                    }
                }
            }
        }
    }

    .customer-account-create {
        max-width: 860px;
        margin: 0 auto;
        padding: 0 15px;

        .page-title {
            width: 100%;
            text-align: center;
            margin: 40px 0 15px;

            h1 {
                @extend .title-h1;
            }
        }

        .page-container {
            margin: 50px 0;

            .form-create-account {
                width: 100%;

                .fieldset.create.info,
                .fieldset.address,
                .fieldset.create.account {
                    .field {
                        width: 100%;
                    }

                    .block-title > h4 {
                        @extend .title-h4;
                        margin-bottom: 60px;
                    }
                }

                .note-required {
                    margin-bottom: 10px;
                }

                div.field-recaptcha {
                    div.field {
                        div.control {
                            position: relative;
                            top: -25px;
                            z-index: -1;

                            input.mage-error {
                                border-color: $white;
                            }

                            input:focus {
                                outline: none;
                                outline-offset: 0;
                            }

                            div.mage-error {
                                position: relative;
                                top: unset;
                                left: unset;
                                text-transform: none;
                            }
                        }
                    }
                }
            }

            .actions-toolbar {
                margin: 20px 0;

                .primary button {
                    @include make-btn;
                    @include make-btn-primary;
                }
            }
        }
    }

    .customer-account-forgotpassword {
        max-width: 860px;
        margin: 0 auto;
        padding: 0 15px;

        .page-title {
            width: 100%;
            text-align: center;
            margin: 40px 0 15px;

            h1 {
                @extend .title-h1;
            }
        }

        .page-container {
            margin: 0 0 50px 0;
            padding-bottom: 1px;

            .form.password.forget, .form.password.reset {
                padding: 0 0 40px 0;
                width: 100%;

                .note {
                    @extend .text-default;

                    @include min-screen($screen__m) {
                        margin-left: 15px;
                    }

                    &.required-fields {
                        font-size: 15px;
                        color: $dark-grey;
                        margin-top: 30px;
                    }
                }

                .form-group {
                    width: 100%;
                    margin-top: 20px;

                    &.actions-toolbar {
                        margin-top: 0;
                    }

                    small {
                        margin-bottom: 15px;
                    }

                    .action {
                        margin-bottom: 15px;
                    }
                }
            }

            .actions-toolbar {
                margin: 20px 0;

                @include min-screen($screen__m) {
                    margin-left: 15px;
                }

                .action.back {
                    float: right;
                }
            }
        }
    }

    .account-nav {
        &-content {
            padding: 0;
        }

        &-desktop-mobile {
            @include max-screen($screen__m) {
                margin: 15px 0 30px 0;
                padding-bottom: 30px;
                border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);
            }

            .acc-menu-title {
                @extend .title-h3;
                color: $dark-grey;

                @include max-screen($screen__m) {
                    display: none;
                }
            }

            .account-nav-menu {
                &-desktop {
                    @include max-screen($screen__m) {
                        display: none;
                    }

                    list-style-type: none;
                    padding: 0;

                    .account-nav-item {
                        @extend .text-default;
                        font-size: 14px;
                        text-transform: uppercase;
                        padding: 10px 0;
                        margin-bottom: 10px;

                        &.current > a {
                            color: $primary;
                            font-weight: 400;
                        }

                        a:hover {
                            color: $primary;
                        }
                    }
                }

                &-mobile {
                    @include min-screen($screen__m) {
                        display: none;
                    }
                }
            }
        }
    }

    .addresses-list {
        .page-title > h1 {
            @extend .title-h1;
        }
    }

    .addresses-additional,
    .addresses-primary {
        .acc-block-title .title {
            @extend .title-h3;
        }

        .row {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @include max-screen($screen__l) {
                flex-direction: column;
            }

            .acc-block-address {
                margin-bottom: 20px;

                @include min-screen($screen__l) {
                    width: calc(50% - 10px);
                }
                @include max-screen($screen__l) {
                    width: 100%;
                }

                .block-preview-title,
                .box-title {
                    .account-link {
                        font-size: 16px;
                        float: right;

                        span {
                            text-decoration: underline;
                        }
                    }
                }

                .box-content,
                .block-preview-content {
                    .actions {
                        .edit,
                        .delete {
                            text-decoration: underline;
                            float: left;
                        }

                        .delete {
                            clear: left;
                        }
                    }
                }
            }
        }

        .acc-block-button button {
            @include make-btn;
            @include make-btn-primary;
        }
    }

    .acc-block-recent-order {
        .page-title > h1 {
            @extend .title-h1;
        }

        .acc-block-content {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .pages {
                display: flex;

                .toolbar-amount {
                    align-self: center;
                    padding: 0;

                    @include min-screen($screen__xs) {
                        flex: 1;
                    }

                    @include max-screen($screen__xs) {
                        width: 35%;
                    }
                }

                .limiter {
                    @include min-screen($screen__xs) {
                        flex: 1;
                        text-align: right;
                    }

                    @include max-screen($screen__xs) {
                        width: 65%;
                    }
                }
            }

            .table {
                margin: 1.5rem 0;
            }
        }
    }

    .form-newsletter-manage {
        .page-title > h1 {
            @extend .title-h1;
        }

        .fieldset {
            .field {
                width: 100%;
            }
        }

        .actions-toolbar {
            margin: 20px 0 !important;

            .primary button {
                @include make-btn;
                @include make-btn-primary;
            }
        }
    }

    .form-edit-account {
        .page-title > h1 {
            @extend .title-h1;
        }

        .block-title > h3 {
            @extend .title-h3;
        }

        .control-label {
            margin-left: 0;
        }

        .fieldset {
            .field {
                width: 100%;
            }
        }

        .note {
            @extend .text-default;
            font-size: 15px;
            color: $dark-grey;
            margin-top: 30px;
        }

        .actions-toolbar {
            margin: 20px 0 !important;

            .primary button {
                @include make-btn;
                @include make-btn-primary;
            }
        }
    }

    .form-address-edit, .form-option-edit {
        .page-title > h1 {
            @extend .title-h1;
        }

        .fieldset {
            .field {
                width: 100%;

                &:before {
                    display: none;
                }
            }

            .block-title > h3 {
                @extend .title-h3;
                margin-bottom: 60px;
            }
        }

        .note {
            @extend .text-default;
            font-size: 15px;
            color: $dark-grey;
            margin-top: 30px;
        }

        .actions-toolbar {
            margin: 20px 0 !important;

            .primary button {
                @include make-btn;
                @include make-btn-primary;
            }
        }

        .two-column-container {
            width: 100%;
            margin-bottom: 30px;
            display: flex;

            .field.form-group {
                margin-top: 0;
                margin-right: 0;
                margin-left: 0;

                &.phone_prefix {
                    width: calc(35% - 15px);
                    margin-right: 30px;
                }

                &.phone_number {
                    width: calc(65% - 15px);
                }

                &.street {
                    width: 85%;
                }

                &.street_search {
                    width: calc(15% - 10px);
                    margin-left: 10px;
                    padding: 0;
                }

                .btn {
                    &:before {
                        bottom: 100px;
                    }

                    width: 100%;
                }
            }
        }

        .postcodify-suggestion {
            max-height: 250px;
            overflow: auto;
            width: 100%;

            .suggestion-items {
                padding: 10px;

                .suggestion-item {
                    cursor: pointer;
                    display: flex;
                    margin-bottom: 15px;

                    div {
                        pointer-events: none;
                    }

                    .suggestion-item-postcode {
                        margin-right: 10px;
                        font-weight: bold;
                    }
                }
            }
        }

        .autocomplete {
            .autocomplete-items {
                overflow-y: auto;
                max-height: 150px;
                position: absolute;
                border: 1px solid #d4d4d4;
                border-bottom: none;
                border-top: none;
                z-index: 99;
                top: 100%;
                left: 0;
                right: 0;
            }

            .autocomplete-items div {
                padding: 10px;
                cursor: pointer;
                background-color: #fff;
                border-bottom: 1px solid #d4d4d4;
            }

            .autocomplete-items div:hover {
                /*when hovering an item:*/
                background-color: #e9e9e9;
            }

            .autocomplete-active {
                background-color: DodgerBlue !important;
                color: #ffffff;
            }
        }

        .fieldset-jp-postcode {
            margin: 0 0 30px 0;
            width: 100%;

            .zipcode-generator {
                display: flex;
                flex-wrap: wrap;

                .zip-container {
                    width: calc(25% - 2.5px);
                    display: flex;
                    align-items: center;

                    @include max-screen($screen__m) {
                        width: 50%;
                    }

                    span {
                        text-align: center;
                        width: 20%;
                    }

                    .validation-advice {
                        padding: 0 0 0 5px;
                    }
                }

                .calculate-city {
                    flex-grow: 1;
                    margin-left: 10px;

                    @include max-screen($screen__m) {
                        margin-top: 15px;
                        margin-left: 0;
                    }
                }
            }
        }

        button.clear {
            left: 50%;
        }
    }

    .block-dashboard-info-top {
        .page-title {
            @extend .title-h1;
        }

        .greetings {
            font-size: 30px;
        }

        .description {
            @extend .text-default;
            margin: 20px 0;
        }
    }

    .order-details-items,.rma-details-items {
        h3 {
            @extend .title-h3;
        }
    }

    .acc-order-detail {
        .page-title {
            h1 {
                @extend .title-h1;
                margin-bottom: 0;
            }

            .order-status {
                @extend .title-emphasis;
                text-transform: uppercase;
                font-style: italic;
                color: $dark-grey;
            }
        }

        .acc-block-title {
            > h3 {
                @extend .title-h3;
            }
        }

        .acc-block-content {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;

            .block-preview-account {
                width: 100%;

                @include min-screen($screen__l) {
                    width: calc(50% - 10px);
                }
            }

            .box-actions a span {
                text-decoration: underline;
            }
        }
    }

    .block-dashboard-orders {
        .block-title {
            width: 100%;
        }
    }

    .block-dashboard-info,
    .block-dashboard-addresses,
    .block-dashboard-delete-account {
        .block-title {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 30px 0 10px 0;
            margin: 0;

            h3 {
                @extend .title-h3;
            }

            .action.edit {
                float: right;
                height: fit-content;
            }
        }

        .block-content {
            > .box {
                .box-title {
                    .action.edit {
                        font-size: 16px;
                        float: right;
                    }
                }
            }
        }

        a.action {
            text-decoration: underline;
        }
    }

    .table-order-items.update-order {
        td{
            vertical-align:middle;
        }
    }

    .table-order-items {
        &.history,
        &.recent,
        &.order-summary {
            td.col.subtotal, th.col.subtotal {
                text-align: right;
            }

            margin: 1.5rem 0;

            thead {
                th {
                    background-color: $lighter-grey;
                }
            }

            thead th,
            tbody td {
                text-align: center;
            }

            tbody {
                tr {
                    td {
                        &.id,
                        &.total span {
                            font-weight: bold;
                        }

                        &.status {
                            font-style: italic;
                        }

                        &.actions a span {
                            text-decoration: underline;
                        }
                    }
                }
            }

            @include max-screen($screen__l) {
                thead,
                tfoot th {
                    display: none;
                }

                tbody tr,
                tfoot {
                    border: 1px solid $light-grey2;
                    margin-bottom: 1.5rem;
                }

                tbody td,
                tfoot tr {
                    &:not(:last-child) {
                        border-bottom: 1px solid $lighter-grey !important;
                    }
                }

                tbody,
                tfoot {
                    tr {
                        display: flex;
                        flex-direction: column;

                        td {
                            text-align: right;
                            position: relative;
                            vertical-align: middle;

                            &::before {
                                content: attr(data-th);
                                font-weight: bold;
                                float: left;
                            }
                        }
                    }
                }
            }
        }
    }

    .customer-account-logoutsuccess {
        text-align: center;

        h1 {
            @extend .title-h1;
        }

        p {
            @extend .text-default;
        }
    }

    .block-title.order {
        margin-bottom: 0;

        a {
            font-weight: 300;
            line-height: 1.1;
            font-size: 1.8rem;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
        }
    }


    .block-preview-account__title, .block-title {
            a.action {
                text-decoration: none;
                color: $dark-grey3;
                transition: color $transition-fast;

                &:hover {
                    color: $primary;
                }
            }

            span {
                font-size: 12px;
                font-weight: normal;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 2px;
            }

            svg {
                width: 12px;
                height: 12px;
            }



    }

    .block-title {
        display: flex;
        justify-content: space-between;
    }
}

.tissot2-account {
    .egui-invoice-container {
        .step-content {
            padding-top: 20px;

            .invoice-types {
                .form-group {
                    width: 100%;
                    margin-top: 0;
                    margin-left: 0;
                    margin-bottom: 30px;
                }
            }

            .invoice-content {
                .row {
                    display: flex;
                    width: 100%;

                    .form-group {
                        width: calc(50% - 5px);
                        margin-top: 0;
                        margin-left: 0;
                        margin-bottom: 30px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}

.order-update-view{
    .acc-block-content{
        margin-bottom: 50px;
    }
}

.form-option-edit{
    display: flex;

    .primary{
        padding: 0 0 0 15px;
    }

    .product-mosaic__info{
        width: 50%;
    }
}

.modal-delete-account {
    form.form-tissot {
        display: block;
        margin-bottom: 30px;

        .form-group {
            width: 100%;
            margin-left: 0;
        }
    }
}

.page-account-deleted {
    max-width: $width-medium;
    margin: 30px 15px;

    @include min-screen($screen__m) {
        margin: 30px auto;
    }

    .title-h1 {
        font-weight: bold;
        margin-bottom: 50px;
        text-align: center;
    }
}
