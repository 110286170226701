.page-wrapper .breadcrumbs {
    padding: 0.6rem 0 0;
    margin: 0;
    max-width: none !important;

    @include min-screen($screen__m) {
        padding: 0.6rem 2rem 0;
    }


    @include max-screen($screen__xl) {
        background: var(--Surface-Section-Secondary, #F8F7F7);
    }

    ul.items {
        padding: 0 0 0.6rem;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;
        align-content: center;
        gap: 0;
        align-self: stretch;
        flex-wrap: wrap;

        @include max-screen($screen__m) {
            overflow: auto;
            flex-wrap: nowrap;
            white-space: nowrap;

            &.-show-all {
                li.item:not(.-show-all) {
                    display: flex !important;
                }
                li.-show-all {
                    display: none !important;
                }
            }
        }
    }

    li.item {
        display: flex;
        align-items: center;
        color: black;
        padding: 0;
        font-size: 1.2rem;
        line-height: 2.2rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;

        &:after {
            display: inline-block;
            content: '';
            width: 1px;
            height: 5px;
            background: rgba(0, 0, 0, 0.30);
        }

        &:last-child:after {
            display: none;
        }

        strong {
            display: block;
            padding: 0 1.6rem;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 2.2rem;
            letter-spacing: 0.1rem;
            color: $content-tertiary;
        }

        a, button {
            color: black;
            display: block;
            padding: 0 1.6rem;
            border: none;
            background: none;

            &:hover {
                color: $primary;
                text-decoration: none;
            }
        }

        @include max-screen($screen__m) {
            &:not(.-show-all):not(:first-child):not(:last-child) {
                display: none;
            }
        }

        &.-show-all {
            display: none;

            @include max-screen($screen__m) {
                display: flex;
            }
        }
    }
}
