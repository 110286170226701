.message.global.noscript,
.message.global.cookie {
    background: rgba($black, 0.8);
    color: $white;
    font-size: 1.6rem;
    padding: 30px;

    @include min-screen($screen__m) {
        padding: 15px 25px;
    }
    .wide_contentsystem_summary {
        margin-bottom: 0;
    }

    .content {
        display: flex;
        align-items: stretch;
        flex-direction: column;

        @include min-screen($screen__m) {
            flex-direction: row;
            align-items: center;
        }

        & > .wide_contentsystem_summary {
            display: flex;
            width: 100%;
        }
    }

    .content-system--html {
        font-size: 1.4rem;
        text-align: left;

        @include min-screen($screen__m) {
            padding: 0 40px;
            flex-grow: 1;
            text-align: center;
        }

        @include min-screen($screen__xl) {
            padding: 0 110px;
        }
    }

    .actions {
        margin-top: 20px;

        @include min-screen($screen__m) {
            margin: 0;

            display: flex;
            flex-flow: column;
        }
        @include min-screen($screen__xl) {
            flex-flow: row;
        }
        @include max-screen($screen__xl) {
            flex-direction: column-reverse;
            .btn-allow{
                margin-top: 0px;
            }
            .btn-disallow{
                margin-top: 15px;
            }
        }

    }

    .btn-disallow,
    .btn-allow {
        @include make-btn;
        white-space: nowrap;

        width: 100%;

        @include min-screen($screen__m) {
            width: auto;
        }
    }

    .btn-allow {
        @include make-btn-primary;
        margin: 15px 0 0 0;

        @include min-screen($screen__xl) {
            margin: 0 0 0 20px;
        }
    }

    .btn-disallow {
        @extend .-stroke;

        background: transparent;
        border-color: $primary;
        color: $primary;

        &:hover {
            color: $primary;
        }
    }

    a {
        color: $primary;

        &:hover {
            color: $primary;
        }
    }
}

/* Popin */
.cookie-popin {

    margin: auto;
    max-width: 860px;
    margin-bottom: 50px;

    hr {
        border-top: 1px solid #000;
        margin: 0;
    }

    .fieldset {
        width: 100%;
        border-left: none;
        border-right: none;
        margin: 0;

        &.active {
            .block-title {
                .chevron {
                    transform: rotate(-45deg);
                }
            }
        }

        .block-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3.with-button {
                text-align: center;
                margin-left: 70px;
                margin-right: 30px;
            }

            .chevron {
                border-style: solid;
                border-width: 0.2em 0.2em 0 0;
                content: '';
                height: 1em;
                right: 15px;
                position: relative;
                top: -0.15em;
                transform: rotate(135deg);
                vertical-align: top;
                width: 1em;
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 30px;
        }
    }

    /* The switch - the box around the slider */
    .switch {
        position: absolute;
        display: inline-block;
        width: 60px;
        height: 34px;
        margin-top: 5px;
    }

    /* Hide default HTML checkbox */
    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* The slider */
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked + .slider {
        background-color: green;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #DC1F18;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }
}

