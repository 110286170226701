//Popin reserve in store for M2, there is also a file for M1

#contact-us-in-store,
#appointment-in-store,
#digital-appointment-in-store,
#reserve-in-store,
#out-of-stock {
    $left-part-width: 40%;
    $right-part-width: 60%;

    color: $black;

        .reserve-header,
        .out-of-stock-header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px 10px 10px;

            @include min-screen($screen__m) {
                padding: 20px;
            }

            .reserve-step {
                line-height: 44px;
                width: 44px;
                height: 44px;
                font-size: 1.3rem;
                letter-spacing: 2px;
                font-weight: 500;
                border-radius: 50px;
                text-align: center;
                background-color: $primary;
                color: $white;

                @include min-screen($screen__m) {
                    line-height: 52px;
                    width: 52px;
                    height: 52px;
                    font-size: 1.6rem;
                }
            }

            .close {
                top: 0;
                font-size: 3rem;
                cursor: pointer;
            }

            .reserve-header {
                padding: 0;
            }
        }

        .reserve-main-title {
            @extend .title-h1;
            text-align: center;
            line-height: 125%;
            margin-top: 20px;
        }

        .reserve-main-subtitle {
            letter-spacing: 1px;
            text-align: center;
            font-size: 1.8rem;
            display: block;
            font-weight: 300;
            margin-top: 10px;
            margin-bottom: 10px;

            @include min-screen($screen__m) {
                margin-bottom: 0;
            }
        }

        .reserve-product-section {
            padding: 20px 0 16px;
            text-transform: uppercase;
            border-bottom: 1px solid #cdcdcd;
            text-align: center;

            @include min-screen($screen__m) {
                padding: 25px 0 20px;
            }

            .reserve-product {
                font-size: 2.5rem;
                line-height: 125%;
                margin-bottom: 5px;

                @include min-screen($screen__m) {
                    font-size: 3.5rem;
                }
            }

            .reserve-ref {
                font-size: 1.6rem;
                letter-spacing: 2px;
                font-weight: 500;
                color: #666;
            }
        }

        .out-of-stock-left,
        .out-of-stock-right {
            float: none;
            display: block;
            width: auto;
        }

        .out-of-stock-left {
            @include min-screen($screen__m) {
                float: left;
            }
        }

        .reserve-product-left,
        .out-of-stock-left {
            margin: 0 0 16px;
            padding: 16px;

            @include min-screen($screen__m) {
                width: $left-part-width;
                margin: 0;
                padding: 20px;
            }

            img {
                width: 100%;
                height: auto;
                display: block;
                margin: 0 auto;
                max-height: 100%;

                @include min-screen($screen__m) {
                    max-width: 320px;
                }
            }

            .reserve-product-price {
                font-size: 35px;
            }
        }

        .reserve-product-right {
            max-width: 680px;
            text-align: left;
            display: flex;
            flex-direction: column;

            .control {
                margin-right: 20px;
                margin-left: 20px;
            }
        }

        .reserve-product-right,
        .out-of-stock-right {
            font-size: 1.8rem;

            @include min-screen($screen__m) {
                width: $right-part-width;
            }

            .store-phone,
            .store-opening,
            .reserve-product-state {
                margin-top: 25px;
                font-weight: 300;
            }

            .reserve-store-details {
                margin-top: 40px;
            }

            .store-adress {
                strong {
                    display: block;
                }
            }

            .reserve-product-state {
                color: $primary;
            }

            .store-opening,
            .store-phone,
            .store-adress {
                padding-left: 30px;
                position: relative;

                .icon {
                    position: absolute;
                    top: 3px;
                    left: 0;
                }
            }

            .store-phone .icon {
                fill: white;
            }
        }

        .reserve-nav-parts {
            text-align: left;
            font-size: 1.6rem;

            .next-step {
                @include min-screen($screen__m) {
                    margin-left: $left-part-width;
                }

                .btn {
                    width: 100%;

                    @include min-screen($screen__m) {
                        width: auto;
                    }
                }
            }

            .btn {
                @include make-btn;
                @include make-btn-primary;
                width: auto;
            }
        }

        .store_locator_container {
            label.sr-only {
                display: none;
            }
        }

        .reserve-shop-title,
        .out-of-stock-main-title,
        .out-of-stock-confirmation {
            font-size: 1.6rem;
            font-weight: 500;
            letter-spacing: 2px;

            & + .custom-select-2 {
                margin-top: 10px;
            }
        }

        .reserve-shop-title {
            display: none;
        }

    #step-1-contact-us,
    #step-1-appointment,
    #step-1-digital-appointment,
    #step-1-reserve {
        display: flex;
        flex-direction: column;
        align-items: center;

            @include min-screen($screen__l) {
                flex-direction: row;
                align-items: flex-start;
            }

            .reserve-product-left,
            .reserve-product-right {
                padding: 20px 0;

                @include max-screen($screen__l) {
                    width: 100%;
                }
            }

            .reserve-product-left {
                .reserve-product-price,
                .recommended-price {
                    text-align: center;
                    width: 100%;
                }
            }

            .reserve-product-right {
                .control {
                    display: flex;
                    flex-direction: row;
                    padding-bottom: 20px;

                    button {
                        // @include make-btn; // todo replace extends when possible
                        background-color: $black;
                        color: $white;
                        margin-left: 10px;
                    }
                }

                .results {
                    overflow-y: auto;

                > ul {
                    padding-left: 0;
                    margin-right: 20px;
                    margin-left: 20px;

                    > li {
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: 8px;
                        font-size: 1.8rem;
                        padding: 10px;
                        border: 1px solid rgba(25, 25, 25, 0.25);

                            &:hover {
                                background-color: #f0f0f0;
                            }

                        > .address {
                            flex: 1;
                            margin-left: 12px;
                            margin-right: 12px;

                                .store-name {
                                    font-weight: bold;
                                    margin-bottom: 10px;
                                }
                            }

                        .control {
                            align-self: flex-start;
                            margin-left: 0;
                            margin-right: 0;
                        }

                    }
                }

                .opening-hours {
                    min-width: 220px;
                    width: 100%;
                    margin-top: 20px;

                    @include min-screen($screen__m) {
                        max-width: 220px;
                    }

                    ul {
                        padding: 0!important;
                        margin-bottom: 2px;
                    }

                    li {
                        margin-bottom: 2px;
                        padding: 0!important;
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }

    #step-2-contact-us,
    #step-2-appointment,
    #step-3-appointment,
    #step-2-digital-appointment,
    #step-3-digital-appointment,
    #step-2-reserve {
        display: flex;
        flex-direction: column;
        align-items: center;

            .form-tissot,
            .button-previous,
            .reserve-nav-parts {
                width: 100%;
                margin: 20px 0;

                @include min-screen($screen__m) {
                    max-width: 680px;
                }
            }

            .available,
            .reserve-watch {
                text-align: center;
            }

            .form-tissot {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;

                .form-group {
                    width: 100%;
                    margin: 20px 0;
                }

                .form-group:not(.form-group-recaptcha):not(.form-group-message) {
                    @include min-screen($screen__m) {
                        width: calc(50% - 5px);
                    }
                }

                .form-group.checkbox-group {
                    @include min-screen($screen__m) {
                        width: 100% !important;
                    }
                }

                .form-group.select-group {
                    select {
                        -webkit-appearance: none;
                    }
                }
            }
        }

    #step-3-contact-us,
    #step-4-appointment,
    #step-4-digital-appointment,
    #step-3-reserve {
        .reserve-confirmation {
            max-width: 680px;
            text-align: center;
            margin: auto;
            padding: 20px 0;
        }
    }

        .step-3 {
            .reserve-nav-parts {
                margin: 20px auto;
            }

            .reserve-parts {
                max-width: $width-medium;
                margin: 20px auto;
            }

            .close-popup {
                text-align: center;
            }
        }

        .reserve-wrap,
        .out-of-stock-wrap {
            @include max-screen($screen__m) {
                margin: 0;
                width: 100%;
            }
        }
    }

    #out-of-stock {
        .out-of-stock-wrap {
            min-height: 80vh;
        }

        .out-of-stock-main-title,
        .out-of-stock-confirmation {
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        .out-of-stock-confirmation {
            margin: 100px auto 0;
            max-width: $width-medium;
        }

        .out-of-stock-right {
            max-width: 680px;
            float: none;
            width: auto;
            text-align: left;
            padding: 20px 16px;

            @include min-screen($screen__m) {
                margin-left: 40%;
                padding: 20px 20px 0 0;
            }

            .form-group {
                margin-left: 0;
                margin-right: 0;
            }
        }

        .out-of-stock-description {
            font-weight: 300;
            font-size: 1.6rem;
            color: $dark-grey;
            line-height: 1.3em;
            margin-bottom: 20px;
        }

        .out-of-stock-newsletter {
            max-width: 680px;
            @include min-screen($screen__m) {
                margin-left: 40%;
            }
        }

        p {
            font-weight: 300;
        }

        .out-of-stock-nav-parts {
            margin-bottom: 40px;
            margin-top: 40px;
            font-size: 1.6rem;

            .btn {
                width: 100%;

                @include min-screen($screen__m) {
                    width: auto;
                }
            }
        }

        .out-of-stock-newsletter .out-of-stock-nav-parts {
            margin-top: 20px;
            font-size: 1.6rem;

            @include min-screen($screen__m) {
                margin-top: 40px;
            }
        }

        form {
            .form-group {
                margin: 40px 15px 0;
                width: auto;
            }
        }

        .out-of-stock-newsletter form .form-group {
            width: 100%;

            @include min-screen($screen__m) {
                width: calc(50% - 30px);
            }

            &.form-footer {
                margin-left: 15px;
                margin-right: 15px;

                @include min-screen($screen__m) {
                    width: 100%;
                    margin-left: 15px;
                }
            }
        }

        #notified-newsletter-form {
            width: 100%;

            .form-group:not(.form-footer) {
                width: 100%;

                @include min-screen($screen__m) {
                    width: calc(50% - 30px);
                }
            }
        }

        #outofstock-form {
            width: 100%;

            .form-group,
            .checkbox-group .control {
                width: 100%;
            }
        }
    }

#contact-us-in-store .reserve-parts.out-of-stock-parts,
#appointment-in-store .reserve-parts.out-of-stock-parts,
#digital-appointment-in-store .reserve-parts.out-of-stock-parts,
#reserve-in-store .reserve-parts.out-of-stock-parts,
#out-of-stock .reserve-parts.out-of-stock-parts,
#contact-us-in-store .out-of-stock-parts.out-of-stock-parts,
#appointment-in-store .out-of-stock-parts.out-of-stock-parts,
#digital-appointment-in-store .out-of-stock-parts.out-of-stock-parts,
#reserve-in-store .out-of-stock-parts.out-of-stock-parts,
#out-of-stock .out-of-stock-parts.out-of-stock-parts {
    margin: 0;
    padding: 0;
}

#contact-us-in-store form .form-group select,
#appointment-in-store form .form-group select,
#digital-appointment-in-store form .form-group select,
#reserve-in-store form .form-group select,
#out-of-stock form .form-group select,
#contact-us-in-store form .form-footer select,
#appointment-in-store form .form-footer select,
#digital-appointment-in-store form .form-footer select,
#reserve-in-store form .form-footer select,
#out-of-stock form .form-footer select,
#contact-us-in-store form .form-group input:not([type="checkbox"]),
#appointment-in-store form .form-group input:not([type="checkbox"]),
#digital-appointment-in-store form .form-group input:not([type="checkbox"]),
#reserve-in-store form .form-group input:not([type="checkbox"]),
#out-of-stock form .form-group input:not([type="checkbox"]),
#contact-us-in-store form .form-footer input:not([type="checkbox"]),
#appointment-in-store form .form-footer input:not([type="checkbox"]),
#digital-appointment-in-store form .form-footer input:not([type="checkbox"]),
#reserve-in-store form .form-footer input:not([type="checkbox"]),
#out-of-stock form .form-footer input:not([type="checkbox"]),
#out-of-stock .out-of-stock-newsletter form .form-group .form-control:focus,
#out-of-stock form .form-group input:not([type="checkbox"]):focus {
    border-color: $border-color;
}

#contact-us-in-store form .form-group label,
#appointment-in-store form .form-group label,
#digital-appointment-in-store form .form-group label,
#reserve-in-store form .form-group label,
#out-of-stock form .form-group label,
#contact-us-in-store form .form-footer label,
#appointment-in-store form .form-footer label,
#digital-appointment-in-store form .form-footer label,
#reserve-in-store form .form-footer label,
#out-of-stock form .form-footer label {
    font-size: 1.6rem;
    text-transform: none;
}

#contact-us-in-store form .form-group.has-value,
#appointment-in-store form .form-group.has-value,
#contact-us-in-store form .form-group.is-focus,
#appointment-in-store form .form-group.is-focus,
#digital-appointment-in-store form .form-group.has-value,
#digital-appointment-in-store form .form-group.is-focus,
#reserve-in-store form .form-group.has-value,
#reserve-in-store form .form-group.is-focus,
#out-of-stock form .form-group.is-focus,
#out-of-stock form .form-group.has-value {
    .control-label {
        top: -2px;
        font-size: 1rem;
        text-transform: uppercase;
        color: $black;
    }
}

    #out-of-stock .out-of-stock-newsletter form .form-group .form-control:focus,
    #out-of-stock form .form-group input:not([type="checkbox"]):focus {
        box-shadow: none;
    }

.catalog-product-view #contact-us-in-store .form-group label,
.catalog-product-view #appointment-in-store .form-group label,
.catalog-product-view #digital-appointment-in-store .form-group label,
.catalog-product-view #reserve-in-store .form-group label {
    text-transform: none;
}

.catalog-product-view #contact-us-in-store .form-group.is-focus .control-label,
.catalog-product-view #appointment-in-store .form-group.is-focus .control-label,
.catalog-product-view #digital-appointment-in-store .form-group.is-focus .control-label,
.catalog-product-view #reserve-in-store .form-group.is-focus .control-label {
    font-size: 1rem;
    text-transform: uppercase;
}
