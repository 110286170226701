.video-background {
    position: relative;
    display: flex;
    height: 100vh;
    overflow: hidden;
    margin-bottom: 50px;

    video {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    video, .video-background-image {
        background-color: #000;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        max-width: none;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
        width: auto;

        @include min-screen($screen__m) {
            height: auto;
        }
    }

    .video-background-image{
        background-position: center;
        left: 0;
        top: 0;
    }

    .video-background__content {
        position: absolute;
        right: 30px;
        bottom: 120px;
        left: 30px;

        @include min-screen($screen__xl) {
            right: auto;
            bottom: 80px;
            left: 50px;
        }

        .video-background__slogan,
        .video-background__title {
            color: $white;
        }

        .video-background__title {
            margin-bottom: 0;

            @extend .title-h2;
            display: block;
            font-weight: bold;
        }

        .video-background__link {
            @include make-btn;
            @include make-btn-primary;
            margin-top: 20px;
        }
    }

    .video-background__icons-container {
        position: absolute;
        right: 30px;
        bottom: 30px;
        cursor: pointer;
    }

    .video-background__icons {
        position: relative;
        display: flex;
        width: 30px;
        height: 30px;

        &:hover:after {
            width: 50px;
            height: 50px;
        }

        &:after {
            display: block;
            content: " ";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0;
            height: 0;
            background-color: $black;
            transform: translate(-50%, -50%);
            border-radius: 100px;
            transition: all .3s;
            z-index: 0;
        }
    }

    .video-background__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        width: 30px;
        height: 30px;
        fill: $white;
        visibility: visible;
        opacity: 1;
        transition: all .5s;

        &.hide {
            visibility: hidden;
            opacity: 0;
        }

        &.video-background__icon--play {
            transform: translate(calc(-50% + 3px), -50%);
        }
    }
    @include max-screen($screen__m) {
        &.has-mobile-video{
            padding-bottom: 177.7777778%; // 9/16 ratio
        }
        .video-background__content{
            bottom: 40px;
        }
    }
}
