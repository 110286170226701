.form-tissot .checkbox-group {
    padding-left: 0;

    label {
        margin: 0;
        padding: 0 0 0 20px;
        font-size: 1.4rem;

        p {
            display: inline;
        }
    }
}

//M1 Contact Page
.contacts-index-index {
    .footer {
        padding: 0;
    }

    .page-contact {
        padding: 40px 0;

        @include min-screen($screen__xl) {
            padding: 60px 4vw;
        }

        h1 {
            @extend .title-h1;

            margin-bottom: 0;
            margin-left: 0;
        }

        h2 {
            @extend .title-h4;
            margin: 0;
        }

        p {
            @extend .text-default;
        }
    }

    .contact-title {
        text-align: center;
        margin-bottom: 50px;

        @include max-screen($screen__l) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .section-contact-form .form-tissot {
        flex-flow: column;
        margin-left: 0;
        margin-right: 0;

        .section-reason .form-group {
            width: auto;
            margin-left: 0;
            margin-right: 0;
            margin-top: 40px;

            label {
                width: auto;
                float: none;
                font-size: 1rem;
                text-align: left;
                font-weight: 300;
            }
        }
    }

    .select-group:before {
        content: "\e901" !important;
    }

    #contact-reason select {
        background: none;

        &:focus {
            box-shadow: none;
        }
    }

    .section-reason .form-submit {
        margin-top: 40px;
    }

    .section-reason button {
        @include min-screen($screen__m) {
            width: auto;
        }
    }

    .sidebar-contact {
        @include max-screen($screen__l) {
            padding-left: 30px;
            padding-top: 40px;
        }

        @include max-screen($screen__m) {
            padding-left: 0;
        }
    }

    .sidebar-container {
        @include max-screen($screen__m) {
            padding: 0;
            margin-left: 15px;
            margin-right: -15px;
        }
    }

    .main-content {
        @include max-screen($screen__m) {
            padding: 0;
            margin-left: -15px;
            margin-right: -15px;
        }
    }

    .form-tissot .form-group > div {
        float: none;
        width: auto;
    }

    .answers-container {
        margin-top: 40px;

        h2 {
            margin-bottom: 20px;
        }
    }

    .page-contact .topic {
        padding-left: 0;

        .question {
            font-weight: 300;
            text-transform: uppercase;
            margin-left: 0;
        }
    }

    .answers {
        margin-left: -15px;

        @include min-screen($screen__m) {
            margin-right: -15px;
        }

        h2 {
            margin-left: 15px;
        }

        .label-container {
            @extend .text-default;
            margin-bottom: 20px;
        }

        .button-container {
            margin-top: 40px;
        }
    }

    .section-contact {
        margin-top: 60px;
    }

    .section-contact__form {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;

        .form-group {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 40px;

            label {
                width: auto;
                float: none;
                text-align: left;
                font-weight: 300;
            }
        }
    }

    .form-tissot .form-group .checkbox-label {
        padding-bottom: 15px;
        margin-bottom: 20px;

        .validation-advice {
            position: absolute;
            padding-left: 0;
            top: auto;
            bottom: 0;
        }
    }

    .file-upload-button {
        padding: 0;
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 300;
        border: 0;

        &:hover,
        &:focus {
            background: transparent;
            border: transparent;
        }
    }

    .question-mark-icon {
        position: relative;
        background: transparent;
        border: 0;
        margin-top: 10px;

        &:before {
            content: "?";
            width: 30px;
            height: 30px;
            background: $white;
            border: 1px solid $black;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            line-height: 30px;
            font-size: 2rem;
            font-weight: 300;
            transition: all $transition-fast;
        }

        &:hover,
        &:focus {
            &:before {
                background: $black;
                color: $white;
            }
        }
    }

    .section-thanks {
        margin-top: 40px;
    }

    .page-contact .answers .button-container > div {
        .btn {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .captcha-group {
        @include max-screen($screen__xl) {
            width: 100%;
            margin-top: 0 !important;
        }
    }

    .section-customer-service {
        display: flex;
        flex-wrap: wrap;

        .form-group:first-child {
            width: 100%;
        }

        .contain-help {
            display: flex;
        }

        .icon-container {
            margin-left: 10px;
        }
    }
}

// Contact Page
.contact-index-index {
    .page-contact {
        padding: 40px 0;

        @include min-screen($screen__l) {
            padding: 60px 6.5vw;
        }

        h2 {
            @extend .title-h4;
        }

        .contact-page-header {
            text-align: center;
            margin-top: 50px;
            margin-bottom: 50px;

            @include max-screen($screen__l) {
                padding-left: 15px;
                padding-right: 15px;
            }

            h1 {
                @extend .title-h1;

                margin-bottom: 0;
                margin-left: 0;
                padding-bottom: 30px;
            }

            p {
                @extend .text-default;
            }
        }

        .contact-page-container {
            @include max-screen($screen__l) {
                padding-left: 15px;
                padding-right: 15px;
            }

            @include min-screen($screen__l) {
                display: flex;
            }

            .section-contact-form {
                margin-bottom: 30px;

                @include min-screen($screen__l) {
                    flex: 1;
                    padding-right: 30px;
                }

                .section-title {
                    @extend .title-h4;
                    margin: 0;
                }

                .section-reason {
                    form {
                        textarea {
                            min-height: 56px;
                            font-size: 1.6rem;
                            font-weight: 300;
                        }

                        .form-group {
                            width: calc(100% - 30px);
                            margin: 30px 15px 0;
                        }

                        @include min-screen($screen__s) {
                            .fieldset {
                                display: flex;
                                flex-wrap: wrap;
                                margin-bottom: 0;

                                .form-group {
                                    width: calc(50% - 30px);

                                    &:last-child {
                                        margin-right: 15px;
                                    }
                                }
                            }
                        }
                    }
                }

                .faq-container {
                    .answers-container {
                        .answers {
                            h2 {
                                margin-bottom: 20px;
                            }

                            .topic {
                                padding-left: 0;

                                .question {
                                    .icon {
                                        color: $primary;
                                        vertical-align: sub;
                                    }

                                    span {
                                        font-weight: 300;
                                        text-transform: uppercase;
                                        margin-left: 0;
                                    }
                                }

                                .answer {
                                    padding-left: 20px;
                                    border-style: solid;
                                    border-width: 0 0 0 1px;
                                    border-color: #e4141d;
                                    margin-left: 10px;
                                    display: none;
                                    margin-top: 10px;
                                    text-align: justify;
                                    transition: 0.5s;
                                }
                            }
                        }
                    }

                    .faq-actions-container {
                        margin-top: 40px;

                        .action-title {
                            @extend .text-default;
                            padding-left: 20px;
                            padding-right: 20px;
                            margin-bottom: 20px;
                        }

                        .faq-buttons-container {
                            padding-left: 20px;
                            padding-right: 20px;

                            button {
                                width: calc(50% - 30px);

                                &:nth-child(odd) {
                                    margin-right: 15px;
                                }

                                &:nth-child(even) {
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }

                .section-contact {
                    margin-top: 60px;

                    h2 {
                        margin: 0;
                    }

                    .form-tissot {
                        .form-group {
                            margin-top: 40px;
                            height: fit-content;

                            &:nth-child(odd) {
                                margin-left: 0;
                            }

                            &:nth-child(even) {
                                margin-right: 0;
                            }

                            .contain-help {
                                display: flex;

                                a.help-btn {
                                    margin-left: 10px;

                                    &.active {
                                        color: $primary;
                                    }
                                }
                            }

                            &.help-active:not(.is-focus) {
                                .control-label {
                                    top: 25%;
                                }
                            }

                            .help-text {
                                margin: 10px 0 0 0;
                            }
                        }

                        .customer-service-title {
                            width: 100%;
                            margin-left: 0;
                        }
                    }
                }

                .section-thanks {
                    text-align: center;

                    h2 {
                        margin-bottom: 0;
                    }
                }
            }

            .sidebar-container {
                @include min-screen($screen__l) {
                    flex: 1;
                }

                .contact-block {
                    padding: 35px 10%;
                    margin-bottom: 20px;
                    background-color: #f5f5f5;
                    font-size: 18px;
                    color: #666;

                    h3 {
                        margin-top: 0;
                        font-size: 22px;
                        font-weight: 900;
                        text-transform: uppercase;
                        color: #000;
                    }

                    .contact-block-details {
                        padding: 50px 40px;
                        padding-bottom: 15px;
                        font-size: 15px;
                        display: flex;

                        .contact-block-footer {
                            position: relative;
                            padding-left: 15px;

                            &:first-child {
                                padding-left: 15px;
                                padding-right: 15px;
                                border-right: 1px solid #ccc;
                            }

                            .icon {
                                position: absolute;
                                left: -20px;
                                width: 25px;
                                height: 25px;
                            }

                            .contact-block-phone {
                                font-size: 18px;
                                font-weight: 800;
                                color: #000;
                            }

                            .contact-block-phone-details {
                                color: #535353;
                            }
                        }
                    }

                    .contact-block-reference {
                        font-size: 13px;
                    }
                }

                .service-center {
                    margin-bottom: 20px;
                    height: 230px;
                    padding: 32px;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    display: flex;
                    align-items: flex-end;

                    h3 {
                        margin-top: 0;
                        font-size: 35px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }
                }

                .service-center-find {
                    background-image: url("../images/contact/contact-us-bloc-a.jpg");
                }

                .service-center-download {
                    background-image: url("../images/contact/contact-us-bloc-b.jpg");
                }

                .service-center-question {
                    background-image: url("../images/contact/contact-us-bloc-c.jpg");
                }

                .contact-action > a {
                    position: relative;
                    display: block;
                    padding: 60px 40px;
                    padding-left: 110px;
                    margin-top: 20px;
                    border-bottom: 1px solid #ececec;
                    border-top: 1px solid #ececec;

                    .icon {
                        position: absolute;
                        display: block;
                        left: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 70px;
                        color: #666;
                        width: 65px;
                        height: fit-content;
                    }

                    h3 {
                        margin: 0;
                        margin-bottom: 3px;
                        font-size: 20px;
                        font-weight: 800;
                        text-transform: uppercase;
                    }

                    div {
                        color: #666;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    #confirm_message {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .wide_contentsystem_summary {
        .contact-block {
            h3 {
                text-transform: uppercase;
                font-size: 16px;
                line-height: 22px;
                margin-bottom: 20px;
            }
        }
    }
}

.contact-success-index,
.contact-prepaidpacking-success {
    .contact-form-success-page {
        margin: 60px auto;
        padding-left: 15px;
        padding-right: 15px;
        max-width: $screen__s;

        h1 {
            @extend .title-h1;
            margin: 0;
            padding-bottom: 30px;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 40px;

            & + div {
                @extend .text-default;
            }
        }

        a {
            color: $primary;
        }
    }
}

.contact-prepaidpacking-index {
    .prepaidpacking-header {
        img {
            width: 100%;

            @include min-screen($screen__xl) {
                margin-top: -60px;
            }
        }
    }

    .prepaidpacking-container {
        .simple_html {
            max-width: unset;
        }

        .prepaidpacking-information {
            margin-top: 60px;
            max-width: $width-large;
            margin-left: auto;
            margin-right: auto;
            text-align: justify;

            @include max-screen($screen__m) {
                margin-left: 30px;
                margin-right: 30px;
            }

            h1 {
                @extend .title-h1;
                text-align: center;
            }

            p:first-of-type {
                font-size: 18px;
                font-weight: 300;
                padding-left: 0;
                margin-bottom: 1rem;
            }

            ol {
                font-size: 16px;
                @extend .text-default;
                list-style-type: decimal;

                li {
                    padding-left: 10px;
                    padding-bottom: 5px;
                }
            }

            p:not(:first-of-type) {
                font-size: 16px;
                padding-left: 15px;
            }
        }

        .prepaidpacking-form {
            max-width: $width-large;
            margin-left: auto;
            margin-right: auto;

            @include max-screen($screen__m) {
                margin-left: 30px;
                margin-right: 30px;
            }

            .form-tissot {
                flex: 1;

                .form-group {
                    &.checkbox-group {
                        small {
                            margin-bottom: 15px;
                        }

                        margin-top: 15px;
                    }

                    &.form-submit {
                        width: 100%;
                        text-align: center;
                        margin: 30px 0 60px 0;

                        button {
                            width: 50%;

                            @include max-screen($screen__s) {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
.contact-index-index {
    .page-contact {
        .contact-page-container{
            .sidebar-contact-mido {
                .contact-block {
                    padding: 0px 15px;
                    @include max-screen($screen__xs) {
                        .contact-block-details {
                            padding-left: 15px;
                            display: initial;

                            .contact-block-footer {
                                padding-left: 0px;
                                &:first-child {
                                    border-right-width: 0;
                                    padding-left: 40px;
                                    .icon {
                                        left: -0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.form-tissot {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    .form-group {
        margin-top: 30px;
    }
}
