.category-push {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
    gap: 1.2rem;

    @include min-screen($screen__m) {
        gap: 0;
        margin-bottom: 40px;
        padding: 20px 10px;
    }

    @include min-screen($screen__xl) {
        margin: 0 3vw 90px;
        padding: 0;
    }

    &__img {
        width: 100%;
        position: relative;
        order: -1;

        @include min-screen($screen__m) {
            order: 0;
            width: 50%;
            padding: 0 10px;
        }

        @include min-screen($screen__xl) {
            padding: 0 20px;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__description {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        text-align: center;
        padding: 0 1.2rem;

        @include min-screen($screen__m) {
            padding-top: 50px;
            bottom: auto;
            top:0;
        }
    }

    &__title {
        @extend .title-h2;
        margin-bottom: 25px;
        color: white;
    }

    &__btn {
        @include make-btn;
        @include make-btn-primary;
    }

    &__products {
        display: flex;
        width: 100%;
        padding: 0 8px;
        flex-wrap: wrap;
        gap: 1.2rem 0;

        @include min-screen($screen__m) {
            width: 50%;
            padding: 0;
        }

        @include min-screen($screen__xl) {
            padding: 0 0 0 45px;
        }


        .product-thumbnail {
            height: 100%;
        }
    }

    &__products--large {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        gap: 1.2rem 0;

        @include min-screen($screen__m) {
            margin-top: 60px;
            gap: 0;
        }

        .product-thumbnail {
            height: 100%;
        }

        .category-push__product-item {
            width: calc(50% - 1.2rem);

            @include min-screen($screen__m) {
                width: calc(25% - 1.2rem);
            }

            @include min-screen($screen__xl) {
                width: calc(25% - 40px);
            }
        }
    }

    &__products--large + &__img {
        order: -1 !important;
        overflow: hidden;

        @include min-screen($screen__m) {
            width: 100%;
        }

        img {
            width: auto;
            max-width: none;
            height: 400px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);


            @include min-screen($screen__m) {
                width: 100%;
                height: auto;
                position: static;
                transform: none;
            }
        }

        .category-push__description {
            @include min-screen($screen__m) {
                width: 50%;
                bottom: 0;
                display: flex;
                flex-flow: column;
                justify-content: center;
                align-items: center;
                padding: 0;
            }
        }
    }

    &__product-item {
        width: calc(50% - 1.2rem);
        margin: 0 .6rem;

        @include min-screen($screen__xl) {
            margin: 0 20px;
            width: calc(50% - 42px);
        }
    }
}

.category-push--anim {
    .category-push__img {
        transform: scale(0.9);
        transition: all ease-in 0.5s;
    }

    .category-push__product-item {
        opacity: 0.3;
        transform: scale(0.9);
        transition: all 0.5s ease-in 0s;
    }

    .category-push__product-item:first-child,
    .category-push__product-item:nth-child(2) {
        opacity: 0.5;
        transition: all 0.5s ease-in 0s;
    }

    .category-push__product-item:nth-child(3),
    .category-push__product-item:nth-child(4) {
        transition: all 0.5s ease-in 0.7s;
    }

    &.is-visible {
        .category-push__img {
            transform: scale(1);
        }

        .category-push__product-item {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.wide_contentsystem-right {
    .category-push {
        @include min-screen($screen__m) {
            .category-push__img {
                order: 1;
            }
        }

        @include min-screen($screen__xl) {
            .category-push__products {
                padding: 0;
                gap: 40px 0;

                .product-thumbnail {
                    height: 100%;
                }
            }
        }
    }
}

.wide_contentsystem-left {
    .category-push {
        @include min-screen($screen__m) {
            .category-push__img {
                order: -1;
            }
        }

        @include min-screen($screen__xl) {
            .category-push__products {
                padding: 0;
                gap: 40px 0;

                .product-thumbnail {
                    height: 100%;
                }
            }
        }
    }
}

