.checkout-success, .order-update-view {
    padding: 0 3rem;

    .section__title {
        font-weight: 500;
        margin: 0 0 2rem;
        text-transform: uppercase;
    }

    .header {
        display: flex;
        flex-direction: column;
        margin: 7rem auto 4.3rem;
        max-width: 50rem;

        &__title, &__intro {
            text-align: center;
        }

        &-infos {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 3.8rem;

            &__order {
                margin: 0;
                font-weight: 700;
                font-size: 1.8rem;
                text-transform: uppercase;
                text-align: center;

                @include max-screen($screen__xs) {
                    position: relative;
                    width: 100%;
                    height: 5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 1.1rem;

                    &::before {
                        content: '';
                        background-color: $lighter-grey;
                        position: absolute;
                        top: 0;
                        left: -3rem;
                        width: calc(100% + 6rem);
                        height: 100%;
                        z-index: -1;
                    }
                }
            }

            &__status {
                font-size: 1.8rem;
                text-transform: uppercase;
                text-align: center;

                @include max-screen($screen__xs) {
                    position: relative;
                    width: 100%;
                    height: 5rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &::before {
                        content: '';
                        background-color: $lighter-grey;
                        position: absolute;
                        top: 0;
                        left: -3rem;
                        width: calc(100% + 6rem);
                        height: 100%;
                        z-index: -1;
                    }
                }
            }
        }
    }

    .messages {
        max-width: $container;
        margin: 0 auto;
    }

    .order-details {
        max-width: $container;
        margin: 0 auto;
        padding-top: 2.5rem;
        margin-bottom: 2.5rem;
        border-top: 1px solid $light-grey4;

        .addresses {
            display: grid;
            column-gap: 1rem;
            row-gap: 1rem;
            margin-top: 3rem;

            @include min-screen($screen__s) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            .address {
                &__title {
                    font-size: 1.8rem;
                    font-weight: 500;
                    margin-bottom: 3rem;
                    display: block;
                }

                &__content {
                    border: 1px solid $light-grey4;
                    border-radius: 10px;
                    padding: 2.5rem 3rem;
                }

                &__actions {
                    margin-top: 3rem;

                    @include max-screen($screen__s) {
                        margin-bottom: 3rem;

                        &:nth-last-child {
                            margin-bottom: 0;
                        }
                    }

                    .action {
                        width: 100%;
                        padding: 16px 10px;
                    }
                }
            }
        }
    }

    .order-items {
        margin-top: 3.2rem;
        padding: 2.5rem 3rem;
        background-color: $lighter-grey;
        border-radius: 10px;

        @include min-screen($screen__xl) {
            padding: 2.5rem 8rem;
        }

        .section__title {
            margin: 0 0 3.5rem;
        }

        .items {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
        }

        .item {
            display: flex;
            margin-bottom: 4rem;

            &__thumb {
                width: 9rem;
                height: 9rem;
                object-fit: contain;
                margin-right: 3rem;

                @include min-screen($screen__s) {
                    width: 13rem;
                    height: 13rem;
                    margin-right: 5rem;
                }
            }

            &__content {
                flex: 1;
            }

            &__infos {
                display: flex;
                flex-direction: column;
                column-gap: 1.5rem;

                @include min-screen($screen__m) {
                    display: grid;
                    grid-template-columns: 1fr 1fr 7rem 7rem;
                }

                @include min-screen($screen__l) {
                    grid-template-columns: 1fr 1fr 11rem 11rem;
                }

                * {
                    font-size: 1.4rem;
                    margin-bottom: 0.5rem;

                    @include min-screen($screen__m) {
                        margin-bottom: 1rem;
                    }
                }
            }

            &__name {
                display: block;
                max-width: 22rem;
            }

            &__price {
                font-weight: 700;
            }
        }

        .product-size {
            margin-top: 2rem;

            @include max-screen($screen__m) {
                transform: translateX(-18rem);
                width: calc(100% + 18rem);
            }

            @include max-screen($screen__s) {
                transform: translateX(-12rem);
                width: calc(100% + 12rem);
            }

            .btn {
                @include max-screen($screen__m) {
                    width: 100%;
                }
                @include max-screen($screen__m) {
                    padding: 16px 30px;
                }
            }

            &__content {
                margin-top: 1.3rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                @include min-screen($screen__m) {
                    padding-left: 1.5rem;
                }
            }

            &__infos {
                margin-right: 2.6rem;
                font-size: 1.2rem;
            }

            &__action {
                text-transform: uppercase;
                margin-right: 0.8rem;
                font-size: 1.2rem;
                text-decoration: underline;
            }
        }

        &__text {
            margin-top: 3rem;
        }
    }

    .order-update {
        margin: 3rem 0;

        &__form {
            display: flex;
            justify-content: center;
        }

        &__submit {
            width: 100%;
            max-width: 30rem;
        }
    }

    .order-account {
        max-width: $container;
        margin: 7rem auto 0;
        border-top: 1px solid $light-grey4;

        &__link {
            margin-bottom: 4rem;
            display: flex;
            justify-content: center;
        }

        &__form {
            max-width: 50rem;
            margin: 13rem auto;
            display: flex;
            flex-direction: column;
            align-items: center;

            .form-group {
                width: 100%;
            }
        }

        &__submit {
            border-radius: 50px;
        }

        &__see {
            width: 100%;
            max-width: 30rem;
        }
    }

    .order-cancel {
        max-width: $container;
        margin: 7rem auto;
        padding-top: 2.5rem;
        border-top: 1px solid $light-grey4;

        &__actions {
            margin-top: 6rem;
            display: flex;
            justify-content: center;
        }

        &__submit {
            width: 100%;
            max-width: calc(50% - 1rem);

            @include max-screen($screen__s) {
                padding: 16px 10px;
                max-width: 100%;
            }
        }
    }

    .message-success {
        margin-bottom: 2.5rem;
        text-transform: uppercase;
        font-weight: 700;
    }
}

.order-update-panel {
    width: 100%;
    max-width: 64rem;
    display: flex;
    justify-content: flex-end;
    left: initial;

    &_title {
        margin: 0 0 7rem;
        font-weight: 500;
    }

    .modal-inner-wrap {
        width: 100%;
        padding: 10rem 8rem;
        overflow-y: initial;

        @include max-screen($screen__s) {
            padding: 7rem 2.6rem 2.6rem;
        }
    }

    .modal-header {
        padding: 0 !important;
    }

    #order-cancel__modal {
        .actions {
            margin-top: 5rem;
            display: flex;
            justify-content: center;

            .btn {
                margin: 0.5rem;
            }
        }
    }

    #edit-address-billing__modal, #edit-address-shipping__modal {
        height: 100%;

        .form-modal__inner {
            overflow-y: auto;
            padding: 1rem 2rem 0 0;
            max-height: calc(100vh - 38rem);

            @include max-screen($screen__s) {
                max-height: calc(100vh - 33rem);
            }
        }

        .form-group {
            width: 100%;
            margin: 0 0 4rem;

            &.actions {
                display: flex;
                justify-content: center;
            }
        }

        .two-column-container {
            display: flex;
            column-gap: 1rem;
        }

        .note {
            margin: 2rem 0;
            color: $red;
            text-transform: uppercase;
            padding-left: 3rem;
        }

        .btn.-primary {
            width: 100%;
            max-width: 36rem;
        }
    }

    .edit-size__modal {
        .form-group {
            width: 100%;
            margin: 0;
        }

        .select-group {
            margin-bottom: 4.7rem;
        }

        .actions {
            display: flex;
            justify-content: center;
        }

        .btn.-primary {
            width: 100%;
            max-width: 36rem;
        }

        .product-size {
            &__content {
                margin-top: 1.3rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
            }

            &__infos {
                margin-right: 2.6rem;
                font-size: 1.2rem;
            }

            &__action {
                text-transform: uppercase;
                margin-right: 0.8rem;
                font-size: 1.2rem;
                text-decoration: underline;
            }
        }
    }
}

.modals-overlay {
    background-color: rgba(0, 0, 0, 0.6);
}
