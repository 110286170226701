.bracelet {
    padding: 50px 0;

    .bracelet__content {
        margin: auto;
        padding: 0 20px;
        max-width: $width-large;
        text-align: center;
    }

    .bracelet__title {
        @extend .title-h1;
    }

    .bracelet__subtitle {
        margin-top: 15px;
        color: #8E8E8E;
        font-family: $font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 1em;
        line-height: 25px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .bracelet__link {
        @extend .btn;
        @extend .-primary;
        margin-top: 20px;
    }

    .bracelet__list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 30px !important;
        margin-bottom: 50px !important;

        .bracelet__list-item {
            margin-top: 20px;
            width: 50%;

            @include min-screen($screen__m) {
                width: 33%;
            }

            img {
                width: 90%;
            }
        }
    }

    .bracelet__footer {
        @extend .bracelet__content;

        p {
            margin-bottom: 60px;
            font-size: 1.2em;
        }

        span {
            font-size: .8em;
        }
    }
}
