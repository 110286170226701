.modal-popup {
    align-items: center;
    justify-content: center;
    display: flex;
    background-color: rgba($black, 0.6);

    .swiper-container {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    &.confirm {
        .modal-content {
            padding-bottom: 1.2rem;
            padding-top: 3rem;
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }
   .modal-header .modal-title {
        border-bottom: none;
        font-size: 1.8rem !important;
        font-weight: 700;
        border-bottom: 1px solid #c1c1c1;
        padding-bottom: 10px;
        margin-bottom: 0;
        min-height: 1em;
        word-wrap: break-word;
    }
   .modal-content p {
        font-size: 1.4rem !important;
    }
}

.modal-slide .action-close,
.modal-popup .action-close {
    padding: 10px;
    top: 10px;
    right: 10px;
}
.modal-inner-wrap .modal-content{
    padding: 0;
}

.modal-footer button {
    border-color: #000;
    background: transparent;
    color: #000;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.8);
    transition: all 0.2s ease-in;
    padding: 12px 30px;
    border-radius: 2px;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.33em;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.modal-bracelet {
    .modal-header {
        .modal-title {
            border-bottom: none;
        }
    }

    .modal-interlug-container {
        display: flex;
        align-items: center;
        padding: 25px;
        font-size: 1.6rem;

        @include max-screen($screen__m) {
            display: grid;
            grid-template-rows: auto;
        }

        h3 {
            font-weight: 700;
            border-bottom: 1px solid #c1c1c1;
            padding-bottom: 10px;
            margin-bottom: 0;
            min-height: 1em;
            word-wrap: break-word;
        }

        p {
            padding-top: 1.2rem;
            font-weight: 300;
            line-height: 1.1;
            margin-top: 1.5rem;
            margin-bottom: 1rem;
        }

        .modal-interlug-image-container {
            width: 40%;

            @include max-screen($screen__m) {
                position: relative;
                display: block;
                top: 300px;
                width: 300px;
            }
        }

        .modal-interlug-text-container {
            width: 60%;
            padding: 0 25px;
            font-size: 1.4rem;

            @include max-screen($screen__m) {
                position: relative;
                top: -450px;
                left: 0;
                width: 100%;
                padding: 0;
            }
            ul {
                list-style-type: none;
            }
            li:before {
                content: '- ';
            }
        }
    }

}

.modal-video {
    .modal-inner-wrap {
        background: transparent;
        box-shadow: none;
        height: auto !important;
        min-height: auto !important;
        overflow-y: visible !important;
        width: 90% !important;

        @include min-screen($screen__m) {
            width: 75% !important;
        }

        .modal-content {
            box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
        }

        .modal-header {
            padding: 0;
        }

        .action-close {
            top: 0;
            right: 0;
            transform: translate(40%, -100%);

            @include min-screen($screen__m) {
                transform: translate(100%, -100%);
            }

            &:before {
                color: white;
            }

        }

        .product-video {
            margin-top: 0;
        }
    }
}
