//
//  Tissot colors
//  -----------------------------------------------
$primary: #F77F00;
$primary-bg: #202020;
$red: #DC1F18;
$red-disabled: #FF6169;
$green: #27AE60;
$black: #000;
$white: #fff;
$ultralight-grey: #F8F8F8;
$lighter-grey: #F6F6F6;
$light-grey4: #C0C0C0;
$light-grey3: #9a9a9a;
$light-grey2: #EAE8E7;
$light-grey: #828282;
$dark-grey: #404141;
$dark-grey2: #333333;
$dark-grey3: #4F4F4F;
$orange-warning: rgba(238, 143, 0, 0.1);
$orange: #f67f00;
$grey-slider-dots: #DEDEDE;
$content-primary: #121212;
$content-secondary: #616161;
$content-tertiary: #838383;


// Borders
$border-color: rgba(black, 0.2);
$border-active-color: black;

$transition-slow: 0.5s ease-in;
$transition-fast: 0.2s ease-in;
$transition-05: 0.5s ease-in;
$transition-02: 0.2s ease-in;

$width-medium: 652px;
$width-large: 860px;
$screen__xxl: 1681px;
$screen__xll : 1450px !default;
$screen__xx  : 1700px !default;
$screen__xxx : 2200px !default;
$container: 1120px;

// Header
$header-height: 82px !default;
$header-total-height: $header-height !default;
$header-block-callout-height: 30px !default;
$header-height-mobile: 66px !default;
$header-total-height-mobile: $header-height-mobile !default;
$header-block-callout-height-mobile: 40px !default;
$header-header-bottom-height: 82px !default;
