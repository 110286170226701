.tissot_catalog-compatible-strap {
    .compatible-strap-page {
        padding: 40px 0;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        @include min-screen(margin-top) {
            padding: 60px 4vw;
        }

        .header-content {
            text-align: center;

            h1 {
                @extend .title-h1;

                margin-bottom: 0;
                margin-left: 0;
            }

            p {
                @extend .text-default;
            }
        }

        .page-content {
            margin-left: auto;
            margin-right: auto;

            .form-tissot {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 30px;
                max-width: 500px;

                .form-group {
                    width: 100%;
                    margin-top: 15px;

                    .contain-help {
                        display: flex;

                        a.help-btn {
                            margin-top: 18px;
                            margin-left: 10px;

                            .icon {
                                align-self: center;
                            }

                            &.active {
                                color: #e32119;
                            }
                        }
                    }

                    a.btn {
                        width: calc(100% - 30px);
                        margin-top: 30px;
                    }

                    .btn-strap-search {
                        width: 100%;
                    }

                    .btn-strap-search {
                        width: calc(100% - 30px)
                    }
                }
            }
        }
    }

    .modal-owc {
        .modal-content {
            text-align: center;

            .image-container {
                margin-top: 15px;

                img {
                    width: 75%;
                    @include max-screen($screen__l) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.products-grid {
    display: grid !important;
    gap: .6rem;
    grid-template-columns: repeat(2, 1fr);
    margin: 0 1.2rem;

    @include min-screen($screen__l) {
        gap: 1.2rem;
        margin: 0 5rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @include min-screen($screen__xl) {
        margin: 0 7rem;
        grid-template-columns: repeat(4, 1fr);
    }

    .product-thumbnail {

        &.block-category_page_push {
            width: calc(100% - 5px);
            align-items: stretch;
            display: flex;
            flex-flow: row wrap;
            > div {
                width: 100%;
            }
            .category_page_push__link {
                display: flex;
                flex-flow: row wrap;
                align-items: stretch;
                align-content: flex-start;
                width: 100%;
                height: 100%;
                background-color: $ultralight-grey;
                &:hover {
                    color: $dark-grey;
                }
            }
            figure {
                overflow: hidden;
                width: 100%;
                position: relative;
                img{
                    display: block;
                    height: auto;
                    position:  relative;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 100%;
                    z-index:1;
                }
                figcaption {
                    position: absolute;
                    bottom: 1rem;
                    text-align: center;
                    width: 100%;
                }
            }
            .category_page_push__content {
                padding: 15px 20px;
                text-align: center;
                justify-content: center;
            }
        }

        @include min-screen($screen__l) {

            &.block-category_page_push {
                figure{
                    height: auto;
                    padding-top: calc(50% - 10px);
                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        height: 100%;
                        object-fit: cover;
                        transform: translate3d(-50%, -50%, 0) scale(1);
                    }
                }
                .category_page_push__content {
                    display:  flex;
                    flex-flow: column wrap;
                    flex: 1;
                    .category_page_push__text {
                        max-height: 5.3rem;
                        overflow: hidden;

                        p:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        @include min-screen(1800px) {
            &.block-category_page_push figure { // Prevent product-list image scale making different height with category push into product list
                padding-top: 0;
                border-top: solid 1.75vw $white;
                border-bottom: solid 1.5vw $ultralight-grey;
            }
        }
    }

    .plp_image_text_button_link {
        position: relative;
        overflow: hidden;

        .product-thumbnail {
            position: relative;
            display: block;
            padding: 0;
            height: 100%;
            width: 100%;
            border-bottom: none;

            &-content {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                padding: 4rem 1.6rem 2rem;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                border: none;

                @include min-screen($screen__l) {
                    padding: 8rem 3.2rem;
                }
            }

            &-visual {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: all .4s ease-in-out;
            }

            &-title {
                z-index: 10;
                margin: auto;
                color: white;
                text-align: center;
                text-transform: uppercase;
                font-style: normal;
                font-weight: 400;
                font-size: 2.2rem;
                line-height: 3rem;
                letter-spacing: .1rem;
                transition: all $transition-02;
                font-family: $font-family-extended;
                text-shadow: 0px 4px 30px rgba(0, 0, 0, 0.35), 0px 0px 100px rgba(0, 0, 0, 0.80);

                @include min-screen($screen__l) {
                    font-size: 3.2rem;
                    line-height: 4rem;
                }

                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                }

                &:focus-visible {
                    outline: 2px solid $primary;
                    outline-offset: 4px;
                }
            }

            &-btn {
                z-index: 10;
                width: 100%;
            }

            &.-hover:hover {
                .product-thumbnail-visual {
                    transform: scale(1.1);
                }
            }

        }

        &.nb-cells2 {
            grid-column: span 2;

            @include max-screen($screen__l) {
                height: 64vw;
            }

            .product-thumbnail {
                .product-thumbnail-content {
                    padding: 3.2rem;
                    align-items: flex-start;

                    &:before {
                        display: block;
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, transparent, transparent 59.9%, rgba(0, 0, 0, .5));
                    }

                    & > span {
                        position: relative;
                        z-index: 1;
                        color: white;
                        font-size: 2.2rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2.8rem;
                        letter-spacing: 0.1px;
                        text-transform: uppercase;
                        text-shadow: 0 0 30px rgba(0, 0, 0, 0.50);

                        @include max-screen($screen__m) {
                            font-size: 1.6rem;
                            line-height: 2.8rem;
                        }
                    }

                    .product-thumbnail-btn {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        color: #FFF;
                        text-shadow: 0 0 30px rgba(0, 0, 0, 0.50);
                        font-size: 2.2rem;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 2.8rem;
                        letter-spacing: 0.1px;
                        text-transform: uppercase;

                        @include max-screen($screen__m) {
                            font-size: 1.6rem;
                            line-height: 2rem;
                        }

                        &:after {
                            content: '';
                            width: 3.4rem;
                            height: 3.4rem;
                            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M14.5126 32.7376C13.8291 32.0542 13.8291 30.9461 14.5126 30.2627L23.7751 21.0002L14.5126 11.7376C13.8291 11.0542 13.8291 9.94613 14.5126 9.26272C15.196 8.5793 16.304 8.5793 16.9874 9.26272L27.4874 19.7627C28.1709 20.4461 28.1709 21.5542 27.4874 22.2376L16.9874 32.7376C16.304 33.421 15.196 33.421 14.5126 32.7376Z" fill="white"/></svg>');
                            background-repeat: no-repeat;
                            background-size: contain;

                            @include max-screen($screen__m) {
                                width: 2.4rem;
                                height: 2.4rem;
                            }
                        }

                        &:before {
                            display: block;
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    @include max-screen($screen__l) {
                        padding: 0 1.8rem 2rem;
                    }
                }
            }
        }
    }
}

.products-section {
    @include min-screen($screen__l) {
        margin-top: 40px;
    }
    .search.results{
        @include max-screen($screen__l) {
            display: flex;
            flex-flow: row wrap;
            > .message {
                width: 100%;
                & + .products-header {
                    order: -1;
                    justify-self: flex-end;
                    margin-left: auto;
                    max-width: 50%;
                    .sortby,
                    .custom-select-2 {
                        max-width: 100%;
                    }
                }
            }
        }
        @include max-screen($screen__xs) {
            > .message + .products-header {
                justify-content: flex-start;
                margin-left: 10px;
                max-width: 100%;
            }
        }
    }
}

// Product mosaic wrapper
.collection-header {
    text-align: center;
    padding: 2rem 1.2rem;

    @include min-screen($screen__l) {
        padding: 4rem 5rem;
    }

    @include min-screen($screen__xl) {
        padding: 8rem 7rem;
    }

    &__title {
        @extend .title-xl;
        margin-bottom: 0;

        strong {
            display: block;
            font-weight: 300;
        }
    }

    &__description {
        @extend .text-default;
        font-family: $font-family-secondary;
        color: $dark-grey3;
        max-width: 836px;
        margin: 10px auto auto auto;
    }

    &__banner {
        margin-top: 40px;
        overflow: hidden;
        position: relative;
        /* need to bypass the scss compiler & use a literal instead. */
        padding-bottom: #{"min(75%, 500px)"};
        background: var(--background-image) no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    &__products {
        display: flex;
        margin: 10px -5px 3rem;

        &-img {
            display: block;
            margin: 0 5px;
            width: calc(50% - 10px);
        }

        &-img-container {
            padding-top: 100%;
            position: relative;
            overflow: hidden;
            display: block;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1);
                transition: all 2s ease-in-out;
                min-width: 100%;
                min-height: 100%;
            }
        }
        &.collection-header--anim.is-visible  img {
            transform: translate(-50%, -50%) scale(1.1);
        }
    }
}

.collection-header-col2{
    align-items: stretch;
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1.2rem;
    &__btn{
        margin-top: .5rem;
    }
    &__image_wrapper{
        flex: 0 0 100%;
        max-width: 100%;
        order: 0;
    }
    &__content_wrapper{
        order: 1;
        width: 75%;
        flex: 0 0 75%;
        max-width: 75%;
        margin: auto;
        text-align: center;
        padding: 2.4rem 0 2.4rem;
    }
    &__image {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
    }
    &__title{
        @extend .title-xl;
        margin-bottom: 0.5rem;
    }
    &__text{
        font-style: normal;
        font-weight: 400;
        overflow: hidden;
        white-space: pre-wrap;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        color: $content-secondary;
        -webkit-line-clamp: 3;
        font-size: 1.5rem;
        line-height: 2.2rem;
        margin-top: .8rem;
        margin-bottom: 0;

        @include min-screen($screen__l) {
            font-size: 1.8rem;
            line-height: 2.8rem;
        }
    }
    &__text_less{
        font-style: normal;
        font-weight: 400;
        overflow: hidden;
        color: $content-secondary;
        font-size: 1.5rem;
        line-height: 2.2rem;
        margin-top: .8rem;
        margin-bottom: 0;

        @include min-screen($screen__l) {
            font-size: 1.8rem;
            line-height: 2.8rem;
        }
    }

    &.-text-only { // No picture to show
        justify-content: center;
    }
     .readmore, .readless{
         display: inline-block;
         margin-top: 1.2rem;
         cursor: pointer;
         font-size: 1.2rem;
         font-style: normal;
         font-weight: 500;
         line-height: 16px; /* 160% */
         letter-spacing: 1px;
         text-transform: uppercase;
         color: $primary!important;
         text-decoration: underline;
         text-underline-offset: 4px;
     }
    @include min-screen($screen__l) {
        &__btn{
            margin-top: 1rem;
        }
        &__image_wrapper{
            order: 1;
            flex-basis: calc(50% - .6rem);
            max-width: calc(50% - .6rem);
        }
        &__content_wrapper{
            order: 0;
            margin: 0;
            align-self: center;
            padding: 5rem;
            text-align: left;
        }
        p {
            &:first-child, a {
                color: $primary!important;
            }
        }
        &__image {
            flex: 1;
            height: 100%;
            max-width: none;
            object-fit: cover;
            overflow: hidden;
            width: auto;
            &_wrapper {
                display: flex;
                flex-direction: row;
                width: 100%;
            }
        }
        &.-text-only{
            min-height: 20vw;
            .collection-header-col2__content_wrapper{
                text-align: center;
            }
        }
    }
    @include min-screen($screen__l) {
        &__content_wrapper{
            padding: 5rem 0 5rem 5rem;
            margin-right: auto;
            flex-basis: 41.6666666667%;
            max-width: 41.6666666667%;
        }
    }


    @include min-screen($screen__xl) {
        &__content_wrapper{
            padding: 5rem 0 5rem 7rem;
        }
    }
}

.collection-subcategories {
    text-align: center;

    &__show-more {
        margin: 30px 0;
        width: auto !important;
    }

    .subcategories {
        display: grid !important;
        gap: .6rem;
        grid-template-columns: repeat(2, 1fr);
        margin: 0 1.2rem;

        @include min-screen($screen__l) {
            gap: 1.2rem;
            margin: 0 5rem;
            grid-template-columns: repeat(3, 1fr);
        }

        @include min-screen($screen__xl) {
            margin: 0 7rem;
        }

        .subcategory {
            display: flex;
            overflow: hidden;
            flex-direction: column;
            background: #F8F7F7;
            padding: 2.4rem 1.2rem 2rem;
            border-bottom: 3px solid transparent;
            transition: all 0.4s ease-in-out;

            @include min-screen($screen__l) {
                padding: 4rem 4.8rem 4.8rem;
            }

            &__headtitle {
                font-family: $font-family-extended;
                color: $content-primary;
                font-size: .8rem;
                font-style: normal;
                font-weight: 400;
                line-height: 1.2rem;
                letter-spacing: .1rem;
                text-transform: uppercase;
                margin: 0;

                @include min-screen($screen__l) {
                    font-size: 1.2rem;
                    line-height: 2rem;
                }
            }

            &__title {
                font-family: $font-family-extended;
                color: $content-primary;
                font-style: normal;
                font-weight: 400;
                letter-spacing: 0.08rem;
                text-transform: uppercase;
                font-size: 1.3rem;
                line-height: 1.7rem;
                margin: 0;

                @include min-screen($screen__l) {
                    font-size: 2.2rem;
                    line-height: 3rem;
                    margin: 0 0 .4rem;
                }
            }

            &__description {
                color: $content-secondary;
                font-size: 1.3rem;
                line-height: 2rem;
                font-style: normal;
                font-weight: 400;
                margin: 0;
                transition: all 0.4s ease-in-out;
                transform: translateY(0);

                @include min-screen($screen__l) {
                    font-size: 1.6rem;
                    line-height: 2rem;
                }
            }

            &__imgs {
                margin-top: .4rem;
                position: relative;

                @include min-screen($screen__l) {
                    margin-top: 2rem;
                }

                &.--nb2 {
                    .subcategory__img-2 {
                        opacity: 1;
                        transform: translate(-50%, -50%) scale(0.9);
                    }
                }
            }

            &__img {
                &-1 {
                    position: relative;
                    z-index: 10;
                    transform: scale(1);
                    max-height: 33rem;
                    transition: all 0.4s ease-in-out;

                    @include min-screen($screen__l) {
                        transform: scale(0.9);
                    }
                }

                &-2, &-3 {
                    position: absolute;
                    max-height: 33rem;
                    z-index: 5;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0.8);
                    opacity: 0;
                    transition: all 0.4s ease-in-out;
                }
            }

            &__link {
                color: $primary;
                text-align: center;
                font-size: 1.2rem;
                font-weight: 500;
                line-height: 1.1rem;
                letter-spacing: .1rem;
                text-transform: uppercase;
                text-decoration: underline;
                text-underline-offset: 4px;
                transition: all 0.4s ease-in-out;
                transform: translateY(0);
            }

            &__anim {
                overflow: hidden;
                height: 2rem;
                margin-bottom: auto;
            }

            &:hover,
            &:focus {
                border-bottom: 3px solid $primary;

                @include min-screen($screen__m) {

                    .subcategory__description {
                        transform: translateY(-2rem);
                    }

                    .subcategory__link {
                        transform: translateY(-1.6rem);
                    }

                    .subcategory__imgs {
                        &.--nb1 {
                            .subcategory__img-1 {
                                transform: scale(1);
                            }
                        }

                        &.--nb2 {
                            .subcategory__img-1 {
                                transform: translateX(-22%) scale(1);
                            }
                            .subcategory__img-2 {
                                opacity: 1;
                                transform: translate(-28%, -51%) scale(1);
                            }
                        }

                        &.--nb3 {
                            .subcategory__img-1 {
                                transform: scale(1);
                            }
                            .subcategory__img-2 {
                                opacity: 1;
                                transform: translate(-12%, -50%) scale(0.8);
                            }
                            .subcategory__img-3 {
                                opacity: 1;
                                transform: translate(-88%, -50%) scale(0.8);
                            }
                        }
                    }

                }

            }
        }
    }
}

#filter-header {
    height: 4.2rem;
    margin-bottom: 1.2rem;

    @include min-screen($screen__l) {
        height: 7rem;
    }
}

.filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--Border-Tertiary, #EAE8E7);
    border-bottom: 1px solid var(--Border-Tertiary, #EAE8E7);
    background: #FFF;
    padding: .2rem 1.2rem;

    &.-sticky {
        position: fixed;
        width: 100%;
        z-index: 90;
        top: 6.6rem;

        @include min-screen($screen__xl) {
            top: 8.2rem;
        }
    }

    @include min-screen($screen__l) {
        padding: 2rem 1.2rem;
    }

    @include min-screen($screen__xl) {
        padding: 2rem 7rem;
    }

    .filters {

        @include max-screen($screen__l) {
            width: 100%;
            min-width: 8rem;
            max-width: 30%;
        }

        .btn-filters.visible-xs {
            transform: translateX(-1.2rem);
            display: flex !important;

            @include min-screen($screen__l) {
                display: none !important;
            }
        }

        @include min-screen($screen__l) {
            margin-left: -2.2rem;
        }
    }

    .number-results {
        color: rgba(0, 0, 0, 0.40);
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: auto;
        padding: 0 1.2rem;
        text-align: center;

        @include min-screen($screen__l) {
            margin: 0 1.2rem 0 auto;
        }
    }

    .sort-by-num-result-wrapper {
        width: auto;
        text-align: right;

        @include max-screen($screen__l) {
            width: 100%;
            max-width: 30%;
            min-width: 8rem;
        }

        .chosen-select {
            .sorter-options {
                margin: 0;
                color: black;
                font-size: 1.4rem;
                border-bottom: none;
                text-transform: none;
                text-align: right;
            }

            .arrow {
                top: 2px;
                font-size: 1.6rem;
            }
        }
    }
}

.filters {
    &-used {
        flex-grow: 1;

        .container {
            display: flex;
            flex-direction: column;
            height: 100%;

            @include min-screen($screen__l) {
                justify-content: center;
                flex-wrap: wrap;
                flex-direction: row;
                height: auto;

                .filters-actions {
                    display: none;
                }
            }

            .items {
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                @include min-screen($screen__l) {
                    display: inline-block;
                    flex-grow: 0;
                    margin-right: 20px;
                }
            }
        }

        .filter-item {
            margin-top: 50px;
            @include min-screen($screen__l) {
                margin-top: 15px;
            }
        }

        .filters-actions {
            .filter-item,
            .filter-item + .filter-item {
                margin: 0;

                button {
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }

    .filter-clear {
        @include max-screen($screen__l) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: $lighter-grey;

            padding: 16px;
            margin: 30px -16px 0 !important;
        }

        .btn-see-results {
            @include make-btn;

            max-width: 70%;
            background: $black;
            color: $white;

            @include min-screen($screen__l) {
                display: none;
            }

            &:hover {
                background: $black;
                color: $white;
            }
        }

        a {
            font-size: 1.2rem;
            padding-left: 15px;
            position: relative;
            text-transform: uppercase;
            display: inline-block;
            margin-right: 5px;

            @extend .icon-x;

            &::before {
                font-family: "icomoon";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
            }
        }
    }

    .filter-item {
        align-items: flex-start;

        @include max-screen($screen__l) {
            padding-right: 0;

            .filters__wrapper {
                border-top: solid 1px $dark-grey2;
            }

            &:last-child {
                .filters__wrapper {
                    border-bottom: solid 1px $dark-grey2;
                }
            }
        }

        &__number {
            padding-left: .5rem;
        }

        &.checkbox-group {
            &:active, &:focus-visible {
                outline: 2px solid $primary;
                outline-offset: 4px;
                box-shadow: none;
            }

            label {
                display: flex;
                flex: 1;
                font-size: 1.4rem;
                height: auto;
                line-height: inherit;
                margin-right: 0;
                max-width: 100%;
                overflow: hidden;
                padding-left: 0;
                text-overflow: inherit;
                white-space: inherit;
                transition: all 0.2s ease;

                .icon-checkbox {
                    position: relative;
                    top: 3px;
                    margin-right: 5px;
                }

                &:active, &:focus-visible {
                    outline: 2px solid $primary;
                    outline-offset: 4px;
                    box-shadow: none;
                }
            }
        }

        input[type='checkbox'] {
            //display: inline-block;
            //margin-right: 1rem;
            //margin-top: .5rem;
            width: 1.6rem;
            height: 1.6rem;
            overflow: hidden;
            display: block;
            position: absolute;
            top: .7rem;
            left: 1.5rem;
            margin: 0;
            opacity: 0;

            &:focus {
                background-color: green;
            }

            &:checked {
                background-color: $primary;

                & ~ label {
                    font-weight: $weight-bold;

                    .icon-checkbox {
                        border-color: $primary;
                        background-color: $primary;
                    }
                }
            }
        }

        & + .filter-item {
            margin: 30px 0 0;

            @include min-screen($screen__l) {
                margin: 0 0 0 20px;
                .filters__wrapper_items {
                    left: auto;
                    right: -1px;
                }
                .filters__wrapper_title {
                    border-left: solid 1px $content-secondary;
                }
            }
        }
    }

    .filter-name {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.6rem;
        vertical-align: middle;
        display: inline-block;
    }

    .filter-value {
        margin-left: 10px;
        vertical-align: middle;
        display: inline-block;

        a {
            display: inline-block;
            padding: 2px 30px 2px 8px;
            border: 1px solid #e8e8e8;
            border-radius: 2px;
            background: #f8f8f8;
            position: relative;
            font-size: 1.2rem;
            //color: $dark-grey;
            transition: all $transition-fast;

            @extend .icon-x;

            &::before {
                font-family: "icomoon";
                position: absolute;
                right: 8px;
                top: 50%;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                display: block;
                line-height: 12px;
                margin-top: -6px;
                font-size: 0.8rem;
                text-align: center;
                background: #e8e8e8;
                color: $dark-grey;
                transition: all $transition-fast;
            }

            &:hover {
                color: $dark-grey;
                border: 1px solid $dark-grey;

                &::before {
                    color: $white;
                    background: $dark-grey;
                }
            }
        }
    }

    ///////////////////////////

    &-container {
        display: flex;
        flex-direction: column;

        .filter-item {
            position: relative;
            margin-top: 20px;
            width: 100%;

            label {
                position: relative;
                z-index: 2;

                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: 300;
                margin: 0 25px 0 0;
                background: $white;
                height: auto;
                min-height: 30px;
                line-height: 30px;
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                @include max-screen($screen__l) {
                    margin-left: 25px;
                }
            }

            .field-tooltip {
                z-index: 9;
                top: 3px;

                @include max-screen($screen__l) {
                    top: 7px;
                }
            }

            .select-styled {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;

                select {
                    color: transparent;
                }

                option {
                    color: $black;
                }

                @include max-screen($screen__l) {
                    .arrow {
                        left: 0;
                    }

                    .field-tooltip {
                        right: 0;
                        top: 3px;
                        z-index: 9;
                    }
                }
            }

            &.has-tooltip {
                .filters__wrapper_title {
                    padding-right: 6rem;

                    &::before {
                        right: 4rem;
                    }
                }

                .field-tooltip {
                    right: 1rem;
                }
            }
        }

        .filter-item + .filter-item {
            margin: 50px 0 0;
            //width: 100%;
        }

        @include min-screen($screen__l) {
            flex-direction: row;
            justify-content: flex-start;

            .filter-item {
                margin: 0;
                width: auto;

                + .filter-item {
                    margin: 0;
                    //width: auto;
                }

                select, .select {
                    border-bottom: none;
                }

                label {
                    overflow: visible;
                    position: relative;
                }
            }
            .filter-title + .filter-item label:before {
                display: none;
            }
        }
    }

    &-inner {
        padding: 20px 0 50px;
        position: fixed;
        top: 100%;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 11111;
        overflow: auto;
        background: $white;
        display: flex;
        flex-flow: column;
        transition: top $transition-fast;

        @include min-screen($screen__l) {
            padding: 0;
            position: static;
            overflow: initial;
            display: block;
            &:not(.is-open) .btn-close{
                display: none;
            }
        }

        @include max-screen($screen__l) {
            padding-bottom: 100px;

            .filters-container .filter-item + .filter-item {
                margin: 0;
            }

            .filters-used {
                opacity: 0;
                visibility: hidden;
            }

            &.is-open .filters-used {
                opacity: 1;
                visibility: visible;
            }
        }

        &.is-open {
            top: 0;

            @include min-screen($screen__m) {
                top: $header-height-mobile;
            }
        }

        .filter-item > .arrow {
            display: none;
        }

        .filters-actions {
            .filter-clear {
                display: none;
                position: absolute;
                bottom: 34px;
                padding: 10px 0 1.5rem;
                left: 0;
                width: 100%;
                margin: 0 !important;
                text-align: center;
                background-color: $white;
                flex-basis: 100%;
                max-width: 100%;

                .reset_filters {
                    display: block;
                }

                .btn {
                    border: solid 1px $black;
                    background-color: transparent;
                    padding-left: 0;
                    margin: 0;

                    &:before {
                        display: none;
                    }
                }
            }

            &.hasResetButton {
                @include max-screen($screen__l) {
                    .filter-clear {
                        display: block;
                    }
                }
            }
        }
    }

    &-available {
        & + .filters-used {
            bottom: 0;
            left: 0;
            position: fixed;
            width: 100%;
            z-index: 3;
        }
    }

    .btn-filters {

        &:not(.btn) {
            gap: .4rem;
            border: none;
            display: flex;
            align-items: center;
            background: transparent;

            svg {
                order: 1;
            }

            span {
                order: 2;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

        }

        &.-sticky {
            bottom: 0;
            border-radius: 0;
            color: $white;
            font-size: 1.6rem;
            font-weight: $weight-bold;
            left: 0;
            letter-spacing: 0;
            margin: 0;
            position: fixed;
            padding: 1rem 5rem;
            width: 100%;
            z-index: 15;

            &::before {
                background-size: cover;
            }

            &::after {
                bottom: 1.6rem;
                content: '\e901';
                font-family: $font-icons;
                font-size: 2.5rem;
                position: absolute;
                right: 1rem;
                transform: rotate(180deg);
                z-index: 5;
            }
        }

        @include min-screen($screen__l) {
            display: none !important;
        }
    }

    .btn-close {
        position: absolute;
        top: 10px;
        right: 0;
        border: 0;
        background: transparent;
    }

    .visible-xs {
        display: none;
    }

    .filter-title {
        text-align: center;

        .title-h2 {
            margin-top: 0;
        }

        @include min-screen($screen__l) {
            display: none;
        }
    }

    // Collapse
    &__wrapper {
        width: auto;
        position: relative;
        z-index: 3;

        &_title {
            font-family: $font-family;
            background-color: transparent !important;
            border: solid 1px white;
            border-radius: 0;
            cursor: pointer;
            display: inline-block;
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 2.2rem;
            margin-bottom: 0;
            padding: 0 4.2rem 0 2.2rem;
            position: relative;
            transition: border-color .3s ease;
            z-index: 3;
            @extend .icon-chevron-down;

            &::-moz-selection,
            &::selection {
                color: inherit;
                background: transparent;
            }

            &[aria-expanded=true] {
                border: 1px solid $black;
            }

            &::before {
                font-family: $font-icons;
                position: absolute;
                right: 2rem;
            }

            &::after {
                content: '';
                background-color: transparent;
                position: absolute;
                bottom: -2px;
                height: 2px;
                width: 100%;
                left: 0;
                transition: background-color .3s ease;
                z-index: 3;
            }
        }

        &_items_wrapper {
            background-color: $white;
            border: solid 1px $white;
            left: 0;
            max-height: 60vh;
            min-width: 24rem;
            overflow: hidden;
            padding-top: 1rem;
            position: absolute;
            top: 0;
            transition: border-color .3s ease;
            width: 100%;
            z-index: 2;
        }

        &_content.collapse {
            opacity: 0;
            position: relative;
            transition: opacity .3s ease, visibility .3s ease;
            visibility: hidden;

            .filter-item { // prevent regression
                display: flex;
                flex-flow: row wrap;
                margin: 0;
                padding: .5rem 1rem;

                > .arrow {
                    display: none;
                }
            }

            .filters-actions {
                border-width: 1px 0 0;
            }

        }

        &.active {
            z-index: 4;

            .filters__wrapper {
                border-color: $dark-grey2 $dark-grey2 $white;
            }

            .filters__wrapper_title {
                margin-right: -1px;

                & ~ .collapse {
                    opacity: 1;
                    visibility: visible;

                    .filters__wrapper_items_wrapper {
                        border-color: $dark-grey2;
                    }
                }

                &::after {
                    background-color: $white;
                }
            }

            @include min-screen($screen__l) {
                .filters__wrapper_items_wrapper {
                    display: flex;
                    flex-direction: column;


                    .filters__wrapper_items {
                        position: relative;
                        flex: 1 0 auto;
                        max-height: 40vh;
                        overflow-y: auto;
                    }
                }
                .filters-actions {
                    position: sticky;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                    margin-top: 5px;
                }
            }
        }

        .filters__wrapper_title {
            color: $black;

            &.bold {
                font-weight: $weight-bold;
            }
        }

        @include max-screen($screen__l) {
            &_title {
                width: 100%;
                text-align: left;
                border: none;
                display: block;
                padding: 1.5rem 4rem 1rem;

                &::before {
                    font-size: 2rem;
                    left: 1rem;
                    right: auto;
                }

                &::after {
                    display: none;
                }
            }

            &_items_wrapper {
                border: none;
                display: none;
                padding: 0 1rem 1rem;
                position: relative;
                width: calc(100% - 2rem);

                .filter-item > input[type="checkbox"] + label {
                    margin-left: .5rem;
                }

                .filters-actions {
                    display: none;
                }
            }

            &.active {
                .filters__wrapper_items_wrapper {
                    display: block;
                }
            }
        }
    }

    // Filters actions
    &-actions {
        align-items: center;
        box-sizing: border-box;
        border-collapse: collapse;
        border: solid 1px $dark-grey2;
        display: flex;
        justify-content: stretch;
        margin-top: 1rem;

        > * {
            flex: 0 0 50%;
            max-width: 50%;
        }

        button {
            background-color: $white;
            border: none;
            border-radius: 0;
            line-height: 1;
            min-height: 32px;
            padding: .8rem 2rem .8rem;
        }
    }
}

.reset_filters {
    position: relative;
    top: 3px;
    padding: 0;
    margin: 0 0 0 2rem;
    display: flex;
    gap: 0.4rem;
    align-items: center;
    background: none !important;
    border: none !important;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.6rem;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-decoration: underline;
    text-underline-offset: 4px;

    @include max-screen($screen__l) {
        display: none;
    }
}

.block-cta {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;

    .text-media__text {
        max-width: none;
    }

    @include min-screen($screen__l) {
        .text-media__media {
            width: calc(50% - 0.6rem);
        }
    }
}

.block-cta-left {
    @include min-screen($screen__l) {
        .text-media {
            flex-direction: row-reverse;
        }
    }
}

.block-cta-right {

}

.catalog-category-view {
    .page-wrapper {
        padding-top: 60px;
    }
}

.product-retailers-pop {
    width: 332px;
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #7777;
    z-index: 2;
    box-sizing: border-box;
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 5px;

    &__close {
        width: 332px;
    }

    .retailer-media {
        width: 49%;
        display: inline-block;
    }

    .retailer-link {
        width: min-content;
    }
}

.narrow {
    max-width: 652px;
}

#modal-filter_information {
    padding: 20px;
}
