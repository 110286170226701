.page-newsletter {
    max-width: $width-medium;
    margin: 0 15px;

    @include min-screen($screen__m) {
        margin: 0 auto;
    }

    .title-h1 {
        font-weight: bold;
        margin-bottom: 50px;
        text-align: center;
    }

    .section-newsletter-form__description {
        p {
            @extend .text-default;
            text-align: center;
            display: block;
        }

        h2 {
            @extend .title-h2;

            @include max-screen($screen__m) {
                font-size: 2rem;
            }
        }

        p + h2 {
            margin-top: 50px;
        }
    }
}

.page .messages {
    padding-top: 15px;
}
.cms-home.cms-index-index .page .messages{ // prevent top blank before domready
    padding-top: 0;
}

.newsletter-benetifs {
    font-size: 1.4rem;
    font-weight: 300;
    color: $dark-grey;
    margin: 60px 0;

    h3 {
        @extend .title-h4;
    }
}

.newsletter-benefits-item {
    margin-bottom: 30px;
}

.form-newsletter {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    #google-captcha {
        margin-top: 0;
    }

    .-center {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .form-group {
        margin-top: 30px;

        &.full-width {
            width: 100%;
        }

        select.form-control:focus {
            box-shadow: none;
        }

        div.field-recaptcha {
            div.field {
                div.control {
                    position: relative;
                    top: -25px;
                    z-index: -1;

                    input.mage-error {
                        border-color: $white;
                    }

                    input:focus {
                        outline: none;
                        outline-offset: 0;
                    }

                    div.mage-error {
                        position: relative;
                        top: unset;
                        left: unset;
                        text-transform: none;
                    }
                }
            }
        }
    }

    .form-group-line-break {
        width: 100%;
    }

    .fieldset {
        width: 100%;
        border: 1px solid $light-grey;
        border-left: none;
        border-right: none;
        margin-top: 30px;
        cursor: pointer;

        &.active {
            .block-title {
                .chevron {
                    transform: rotate(-45deg);
                }
            }
        }

        .block-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
                @extend .title-h4;
                text-align: center;
            }

            .chevron {
                border-style: solid;
                border-width: 0.2em 0.2em 0 0;
                content: '';
                height: 1em;
                right: 5px;
                position: relative;
                top: -0.15em;
                transform: rotate(135deg);
                vertical-align: top;
                width: 1em;
            }
        }

        .content {
            display: flex;
            flex-wrap: wrap;
            margin: 0 0 30px;
        }
    }
}

.sgecomnewsletter-newsletter-subscriptionconfirm p.newsletter-success {
    margin-bottom: 60px;
}

#newsletter-modal {
    .form-newsletter {
        margin-bottom: 30px;
    }
}

.footer, .main {
    .newsletter-banner {
        display: block;
        padding: 15px;
        text-align: center;
        background-color: $white;
        color: $black;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        margin-top: 50px;

        .banner-title {
            @extend .title-h2;
            color: $black;
        }

        .banner-content {
            margin-right: auto;
            margin-left: auto;
            max-width: 750px;

            .banner-form {
                div.form-group.newsletter-email {
                    width: 100%;
                    margin: 30px 0;

                    div {
                        display: flex;
                        flex-wrap: wrap;
                        text-align: left;
                        column-gap: 25px;

                        .form-control {
                            flex: 1;
                        }

                        .btn.-primary {
                            width: auto;
                            @include min-screen($screen__s) {
                                width: 18rem;
                            }
                        }

                        .tooltip {display: none}

                        .mage-error {
                            display: block;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.modal-newsletter {
    &.modal-popup.modal-slide {
        @include max-screen($screen__m) {
            left: 0;
        }
    }

    .modal-inner-wrap {
        max-width: 70em;

        .modal-content {
            padding: 0 3rem;
        }
    }
}
