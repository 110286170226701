.tissot-ajax-loading {
    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        z-index: 100;
        opacity: 1;
    }

    &:after {
        content: "";
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 125px;
        height: 125px;
        background: url(../images/loader/loader.gif) no-repeat center center;
        background-size: cover;
        opacity: 1;
        z-index: 101;
    }
}

body:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: opacity $transition-fast;
}

body:before {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 100%;
    bottom: 100%;
    overflow: hidden;
    opacity: 0;
    transition: opacity $transition-fast;
}

.ajax-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;

    .ajax-loader-background {
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }

    .ajax-loader-container {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        text-align: center;
        position: absolute;
        left: calc(50%);
        top: calc(50%);
        transform: translateX(-50%) translateY(-50%);

        h3 {
            position: absolute;
            margin: 15px;
            top: calc(55% + 30px);
            background: #f1f1f1;
            border-radius: 5px;
            border: 1px solid #a7a7a7;
            padding: 5px;
            font-weight: bold;
            display: none;
        }
    }

    .ajax-loader-img-container {
        position: absolute;
        left: calc(50%);
        top: calc(50%);
        transform: translateX(-50%) translateY(-50%);
        width: 125px;
        height: 125px;
    }
}

.loading-mask {
    .loader {
        img {
            width: 110px;
            height: 110px;
        }
    }
}
