.title-h1 {
    font-weight: 400;
    font-size: 2.8rem;
    line-height: 3.4rem;
    letter-spacing: -1px;
    text-transform: uppercase;
    color: $content-primary;

    @include min-screen($screen__l) {
        font-size: 3.6rem;
        line-height: 4.2rem;
    }
}

.title-h2 {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $content-primary;
    font-family: $font-family-extended;

    @include min-screen($screen__l) {
        font-size: 3.2rem;
        line-height: 4rem;
    }
}

.title-h3 {
    font-family: $font-family-extended;
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 3rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $content-primary;

    @include min-screen($screen__l) {
        font-size: 3.2rem;
        line-height: 4rem;
    }
}

.title-h4 {
    font-family: $font-family;
    font-weight: 500;
    font-size: 1em; //16px
    line-height: 1.44em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $content-primary;
}

.title-emphasis {
    font-family: $font-family;
    font-weight: 500;
    font-size: 18px; //16px
    line-height: 1.44em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $content-primary;
}

.title-xl {
    font-family: $font-family-extended;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: $content-primary;
    font-size: 2.8rem;
    line-height: 3.4rem;

    @include min-screen($screen__l) {
        font-size: 6rem;
        line-height: 7rem;
    }
}

.text-default {
    font-family: $font-family;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: $content-secondary;

    @include min-screen($screen__l) {
        font-size: 1.6rem;
    }
}

.text-muted {
    font-family: $font-family;
    font-weight: 300;
    font-size: 1em; //18px
    line-height: 1.5em;
    color: $light-grey;
}

.text-highlight {
    font-family: $font-family-extended;
    font-weight: 700;
    font-size: 1.1rem; //14px
    line-height: 1.4rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $primary;
}

.link-more {
    font-family: $font-family;
    font-weight: 300;
    font-size: 0.75em; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $primary !important;
    margin-left: 18px;
    position: relative;
    display: inline-block;

    @extend .icon-chevron-right;

    &:before {
        font-family: 'icomoon';
        position: absolute;
        left: -18px;
        top: 46%;
        transform: translateY(-50%);
        transition: left $transition-fast;
    }

    &:hover {
        &:before {
            left: -15px;
        }
    }
}

.link-back {
    font-family: $font-family;
    font-weight: 400;
    font-size: 1.2rem; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $content-primary !important;
    margin-left: 18px;
    position: relative;

    @extend .icon-chevron-left;

    &:before {
        font-family: 'icomoon';
        position: absolute;
        left: -18px;
        top: 46%;
        transform: translateY(-50%);
        transition: left $transition-fast;
        display: block;
    }

    &:hover {
        &:before {
            left: -15px;
        }

        .icon {
            left: -15px;
        }
    }

    .icon {
        position: absolute;
        left: -18px;
        top: 42%;
        transform: translateY(-50%);
        transition: left $transition-fast;
        display: block;
    }
}

.link-next {
    font-family: 'Heebo', 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1.2rem; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $content-primary !important;
    margin-right: 18px;
    position: relative;
    float: right;

    @extend .icon-chevron-right;

    &:before {
        font-family: 'icomoon';
        position: absolute;
        right: -18px;
        top: 46%;
        transform: translateY(-50%);
        transition: right $transition-fast;
        display: block;
    }

    &:hover {
        &:before {
            right: -15px;
        }

        .icon {
            right: -15px;
        }
    }

    &::after {
        clear: both;
    }

    .icon {
        position: absolute;
        right: -18px;
        top: 42%;
        transform: translateY(-50%);
        transition: right $transition-fast;
        display: block;
    }
}

.link-storelocator {
    font-family: $font-family;
    font-weight: 400;
    font-size: 1.2rem; //12px
    line-height: 1.33em;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $dark-grey;
    position: relative;

    @extend .icon-map-pin;

    &:before {
        font-family: 'icomoon';
    }
}

.required-char {
    color: $red;
    font-size: 14px;
    font-style: italic;
}

.price {
    font-family: $font-family-condensed;
}
